import { Card, Flex, Text, Button, Badge } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { CustomerContractDetails } from "../../../hooks/customer/useContracts";

interface OwnerPropertyCardProps {
  contract: CustomerContractDetails;
}

export default function OwnerPropertyCard({ contract }: OwnerPropertyCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const displayedName = useMemo(() => {
    if (!contract.name) {
      return "";
    }

    return contract.name.split("by Rentujemy")[0];
  }, [contract]);

  const listingData = useMemo(() => {
    if (contract.listing_provider !== "hostaway") {
      return null;
    }

    return contract.listing_data;
  }, [contract]);

  return (
    <Card variation="elevated" marginBottom={20} padding={0}>
      <Flex
        direction={{ base: "column", medium: "row" }}
        alignItems={{ base: "center", medium: "flex-start" }}
        height={{ base: "auto" }}
        gap={3}
      >
        <Flex
          justifyContent={"space-evenly"}
          direction="column"
          width="100%"
          height="100%"
          padding={10}
        >
          <Flex direction={"column"} gap="3px">
            <Text fontWeight={700}>{`${displayedName}`}</Text>

            <Text fontSize={12}>
              ID:{" "}
              {`${contract.contract_type}${contract.contract_id}-${contract.internal_property_id}-${contract.hostaway_id}`}
            </Text>

            <Text fontSize={12}>
              {`${listingData?.street}, ${listingData?.zip_code} ${listingData?.city}`}
            </Text>
          </Flex>
          {contract.property_disabled && (
            <Flex>
              <Flex direction={"column"}>
                <Badge size="small" variation="info">
                  <Text whiteSpace="nowrap">Disabled</Text>
                </Badge>
              </Flex>
            </Flex>
          )}
          <Flex alignContent={"flex-end"}>
            <Button
              size="small"
              onClick={() => {
                navigate(
                  `/owner-cockpit/${contract.contract_type}/${contract.contract_id}`
                );
              }}
            >
              {contract.property_disabled
                ? t("Details")
                : t("Calendar.Calendar")}
            </Button>

            <Button
              size="small"
              disabled={contract.property_disabled}
              onClick={() => {
                navigate(`/owner-cockpit/${contract.hostaway_id}/owner-stay`);
              }}
            >
              {t("Reservation.OwnerReservation")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
