import { Button, Flex, Placeholder, Text } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { borderBottomStyle } from "../../Discovery/commonStyles";
import ExternalResourceLink from "../../components/ExternalResourceLink";
import Headline2 from "../../components/Headline2";
import { useAccessInstructions } from "../../hooks/useAccessInstructions";
import AuthWrapper from "../components/AuthWrapper";
import CheckInButton from "../components/CheckInButton";
import { useSessionContext } from "../providers/SessionProvider";
import SectionWithBoldText from "./SectionWithBoldText";

export default function AccessInstructionsBottomsheet({
  open,
  onDismiss,
  reservationId,
}: {
  reservationId: string;
  open: boolean;
  onDismiss: () => void;
}) {
  const { t, i18n } = useTranslation();
  const { data, loading, error, refetch } =
    useAccessInstructions(reservationId);
  const { session } = useSessionContext();

  useEffect(() => {
    if (session?.type === "unregistered") {
      if (session.guestLastName && session.reservationId) {
        refetch();
      }
    }
  }, [session]);

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight * 0.93]}
    >
      <Flex direction={"column"} style={borderBottomStyle}>
        <Flex direction={"column"}>
          <Flex paddingLeft={16} style={{ ...borderBottomStyle }}>
            <Headline2>{t("AccessInstructions.Header")}</Headline2>
          </Flex>

          <AuthWrapper isPublic={false}>
            {!!error && (
              <Flex padding={16}>
                {(error?.response?.data as any)?.message === "NotCheckedIn" && (
                  <Flex direction={"column"} grow={1}>
                    <Text>{t(`AccessInstructions.Errors.NotCheckedIn`)}</Text>
                    <CheckInButton
                      reservationId={reservationId}
                    ></CheckInButton>
                  </Flex>
                )}

                {(error?.response?.data as any)?.message !== "NotCheckedIn" && (
                  <Flex direction={"column"} grow={1}>
                    <Text>{(error.response?.data as any)?.message}</Text>

                    <Text>Please reach out to us directly.</Text>
                  </Flex>
                )}
              </Flex>
            )}

            {loading && (
              <Flex direction={"column"} padding={16}>
                <Placeholder height={45}></Placeholder>
                <Placeholder height={45}></Placeholder>
                <Placeholder height={45}></Placeholder>
                <Placeholder height={45}></Placeholder>
              </Flex>
            )}

            {data && !loading && (
              <Flex direction={"column"} padding={16}>
                <Flex>
                  <Button
                    variation={i18n.language === "en" ? "primary" : undefined}
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                  >
                    English
                  </Button>
                  <Button
                    variation={i18n.language === "pl" ? "primary" : undefined}
                    onClick={() => {
                      i18n.changeLanguage("pl");
                    }}
                  >
                    Polski
                  </Button>
                </Flex>

                <SectionWithBoldText
                  header={`${t("AccessInstructions.Address")}`}
                >
                  {data.address}
                  <ExternalResourceLink
                    href={`https://maps.google.com/maps?q=${data?.address}`}
                  ></ExternalResourceLink>
                </SectionWithBoldText>

                <SectionWithBoldText
                  header={`${t("AccessInstructions.BuildingAccess")}`}
                  text={
                    i18n.language === "pl"
                      ? data.accessInstructions.pl
                      : data?.accessInstructions.en
                  }
                />

                <SectionWithBoldText
                  header={`${t("AccessInstructions.Access")}`}
                  text={t("AccessInstructions.DoorCode", {
                    doorSecurityCode: data.doorSecurityCode,
                  })}
                />

                {data.parking && (
                  <SectionWithBoldText
                    header={`${t("AccessInstructions.Parking")}`}
                    text={
                      i18n.language === "pl"
                        ? data.parking.pl
                        : data?.parking.en
                    }
                  />
                )}

                <SectionWithBoldText
                  header={`${t("AccessInstructions.Internet.Header")}`}
                >
                  <Flex>
                    {t("AccessInstructions.Internet.Username")}:{" "}
                    {data.wifiUsername}
                  </Flex>
                  <Flex>
                    {t("AccessInstructions.Internet.Password")}{" "}
                    {data.wifiPassword}
                  </Flex>
                </SectionWithBoldText>
                {data?.pastCheckinInstructions?.en && (
                  <SectionWithBoldText
                    header={`${t("AccessInstructions.PostCheckin")}`}
                    text={
                      i18n.language === "pl"
                        ? data.pastCheckinInstructions.pl
                        : data?.pastCheckinInstructions.en
                    }
                  />
                )}
              </Flex>
            )}
          </AuthWrapper>
        </Flex>
      </Flex>
    </BottomSheet>
  );
};
