const makeAmountNice = (amount: number) => {
  return Math.round(100 * amount) / 100;
};

// TODO Stephan Extend with more elaborate logic
export default function calculateExpectedIncome(investObject: {
  totalFinancingAmount?: number;
  expectedPricePerNight: number;
  occupationRate?: number;
  apartmentSize?: number;
}) {
  const variables = {
    realEstateValue: investObject.totalFinancingAmount,
    pricePerNight: investObject.expectedPricePerNight || 400,
    occupationRate: investObject.occupationRate || 0.8,
    currency: "PLN",
    reservationPortalFees: 0.16,
    managementFeeRate: 0.17,
    runningCosts: (investObject.apartmentSize || 50) * 15,
  };

  const revenuePerMonth = makeAmountNice(
    variables.occupationRate * variables.pricePerNight * 30
  );
  const reservationFees = makeAmountNice(
    revenuePerMonth * variables.reservationPortalFees
  );
  const managementFees = makeAmountNice(
    revenuePerMonth * variables.managementFeeRate
  );
  const taxes = makeAmountNice(revenuePerMonth * 0.08);
  const ownerPayout = Math.round(
    makeAmountNice(revenuePerMonth - taxes - reservationFees - managementFees)
  );
  const payout = makeAmountNice(ownerPayout - variables.runningCosts);
  const roi = variables.realEstateValue
    ? Math.round((10000 * (payout * 12)) / variables.realEstateValue) / 100
    : undefined;

  return {
    taxes,
    revenuePerMonth,
    reservationFees,
    managementFees,
    ownerPayout,
    payout,
    roi,
  };
};
