import { Flex, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdEmail, MdPhone } from "react-icons/md";
import { Paragraph } from "./DiscoveryTypography";

interface Props {
  handleClose: () => void;
}

export default function DiscoveryContact({ handleClose }: Props) {
  const { t } = useTranslation();

  const createTable = () => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <MdEmail></MdEmail>
          </TableCell>
          <TableCell textAlign="right">guest@rentujemy.com</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <MdPhone></MdPhone>
          </TableCell>
          <TableCell textAlign="right">+48 732 059 069</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Flex direction="column" justifyContent={"center"}>
          <Modal.Title>{t("Discovery.Contact.Header")}</Modal.Title>
          <Paragraph>{t("Discovery.Contact.Subtitle")}</Paragraph>
        </Flex>
      </Modal.Header>
      <Modal.Body>{createTable()}</Modal.Body>
    </Modal>
  );
};
