import { Outlet, RouteObject } from "react-router-dom";
import OwnerIncomeCalculatorPage from "./OwnerIncomeCalculatorPage";

const incomeCalculatorRoutes: RouteObject[] = [
  {
    path: "/short-term-rental-calculator",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region/:type",
        element: <OwnerIncomeCalculatorPage />,
      },
      {
        path: ":city/:region/:type/:standard",
        element: <OwnerIncomeCalculatorPage />,
      },
    ],
  },
];

export default incomeCalculatorRoutes;
