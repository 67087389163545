import {
  Button,
  Flex,
  Placeholder,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import { isMobile, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import MobileNavTabs, {
  NAV_TABS_HEIGHT,
} from "../Discovery/components/MobileNavTabs";
import { DISCOVERY_LINK } from "../Discovery/links";
import Headline from "../components/Headline";
import { useAnalytics } from "../hooks/useAnalytics";
import { useGuestReservations } from "../hooks/useGuestReservations";
import ReservationComponent from "./components/ReservationComponent";

export default function GuestReservations() {
  useAnalytics();

  const { t } = useTranslation();
  const { data, loading } = useGuestReservations();
  const navigate = useNavigate();
  const isMatchingDesktop = useBreakpointValue({ large: true, base: false });

  return (
    <>
      <DiscoveryHeader></DiscoveryHeader>

      <Flex
        paddingTop={35}
        direction="column"
        className="container"
        height={`calc(
        100svh -
          ${NAV_TABS_HEIGHT}px -
          ${isMobile && isSafari ? "env(safe-area-inset-bottom) + 0px" : "0px"}
      )`}
      >
        <Flex direction={"column"} paddingBottom={120}>
          <Headline>{t("GuestReservation.Header")}</Headline>

          {loading && <Placeholder height={80} />}

          <Flex direction={"column"}>
            {data?.length === 0 && (
              <Flex direction="column" padding={16} boxShadow={"medium"}>
                <Flex direction="column">
                  <Headline>{t("GuestReservation.NoResultsHeader")}</Headline>
                  <Text>{t("GuestReservation.NoResultsSubtitle")}</Text>
                </Flex>
                <Flex marginTop={16}>
                  <Button
                    size="large"
                    variation="primary"
                    onClick={() => {
                      navigate(DISCOVERY_LINK);
                    }}
                  >
                    {t("GuestReservation.NoResultsCTA")}
                  </Button>
                </Flex>
              </Flex>
            )}

            {data?.map((item) => (
              <ReservationComponent
                key={item.id}
                reservation={item}
              ></ReservationComponent>
            ))}
          </Flex>
        </Flex>
      </Flex>

      {!isMatchingDesktop && <MobileNavTabs></MobileNavTabs>}
    </>
  );
};
