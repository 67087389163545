import { useHerokuApi, useLazyApi } from "../common";

export interface ReservationData {
  arrival_date: string;
  cancellation_date?: string;
  channel_name: string;
  cleaning_fee?: number;
  currency: string;
  departure_date: string;
  host_note?: string;
  hostaway_id: number;
  hostaway_listing_id: number;
  no_of_guests?: number;
  owner_payout: number;
  pm_commission: number;
  rental_income: number;
  reservation_date: string;
  reservation_portal_fee: number;
  status: string;
  total_tax: number;
}

export const useReservationsForProperty = (propertyId: number) =>
  useLazyApi<{
    items: ReservationData[];
    stats: { occupancy: number; payout: number; price: number };
  }>(`v1/customer/reservations/reader/property/${propertyId}`, {}, undefined);

export const useReservation = (reservationId: number) =>
  useHerokuApi<ReservationData>(
    `v1/customer/reservations/reader/${reservationId}`,
    {},
    `reservation-${reservationId}`,
    {
      cleaning_fee: "float",
      owner_payout: "float",
      pm_commission: "float",
      rental_income: "float",
      reservation_portal_fee: "float",
      total_tax: "float",
    }
  );
