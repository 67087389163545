import { Badge, Flex } from "@aws-amplify/ui-react";

interface OwnerCockpitTagsProps {
  tags: string[];
}

export default function OwnerCockpitTags({ tags }: OwnerCockpitTagsProps) {
  return (
    <Flex alignItems="center">
      <Flex marginTop={5} wrap="wrap" gap={5}>
        {tags?.map((tag) => (
          <Badge key={tag} variation="info">
            {tag}
          </Badge>
        ))}
      </Flex>
    </Flex>
  );
};
