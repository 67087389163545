import { Alert, Flex, Heading } from "@aws-amplify/ui-react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../../components/Box";
import LoaderBox from "../../components/LoaderBox";
import { useReservationsForProperty } from "../../hooks/customer/useReservations";
import OwnerPropertyReservationSingleMonth from "../Table/OwnerPropertyReservationSingleMonth";
import OwnerCockpitReservationPageCalendar from "../components/Calendar/OwnerCockpitReservationPageCalendar";
import ReservationValuesDisclaimer from "../components/ReservationValuesDisclaimer";
import { CalendarContext } from "../context/CalendarContext";

export default function ReservationsSection({
  propertyId,
}: {
  propertyId: number;
}) {
  const { t } = useTranslation();
  const { data, error, isLoading, load } =
    useReservationsForProperty(propertyId);
  const [selectedMonth, setSelectedMonth] = useState<string>();

  useEffect(() => {
    load({
      year: selectedMonth?.split("-")[0] || moment().year(),
      month: selectedMonth?.split("-")[1] || moment().month() + 1,
    });
  }, [selectedMonth]);

  return (
    <>
      {isLoading && <LoaderBox label={t("Reservation.IsSyncing")} />}

      {error && (
        <Box>
          <Alert
            variation="error"
            isDismissible={true}
            hasIcon={true}
            heading={`${t("OwnerCockpitError.SomeErrorCode")} - ${
              error.message
            }`}
          />
        </Box>
      )}

      <Box
        flexDirection="column"
        mb={30}
        flexGrow={1}
        style={{ display: "flex" }}
      >
        <Heading level={4} marginBottom={10}>
          {t("Reservations")}
        </Heading>

        <CalendarContext.Provider
          value={{
            selectedMonth,
          }}
        >
          <>
            <Flex marginBottom={30} direction={"column"} alignItems={"center"}>
              <OwnerCockpitReservationPageCalendar
                onMonthChange={(year, month) => {
                  setSelectedMonth(`${year}-${month}`);
                }}
                reservationList={data?.items || []}
              />
            </Flex>

            <Flex direction="column">
              {data && (
                <Fragment>
                  <OwnerPropertyReservationSingleMonth
                    avgDailyRate={data.stats.price}
                    avgPayout={data.stats.payout}
                    occupancyRate={data?.stats.occupancy}
                    onBack={() =>
                      setSelectedMonth(
                        moment(`${selectedMonth}-01`)
                          .subtract(1, "month")
                          .format("YYYY-MM")
                      )
                    }
                    onNext={() =>
                      setSelectedMonth(
                        moment(`${selectedMonth}-01`)
                          .add(1, "month")
                          .format("YYYY-MM")
                      )
                    }
                    items={
                      data.items.filter((item) =>
                        moment(item.departure_date).isSameOrBefore(
                          moment(`${selectedMonth}-01`).endOf("month")
                        )
                      ) || []
                    }
                  />
                </Fragment>
              )}
              <ReservationValuesDisclaimer />
            </Flex>
          </>
        </CalendarContext.Provider>
      </Box>
    </>
  );
}
