import Box from "../components/Box";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Badge,
  Button,
  Expander,
  ExpanderItem,
  Heading,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import { downloadFile } from "../utils/downloadFile";
import { IPayoutEntry, PayoutType } from "../hooks/useCoreData";
import { PayoutStatus } from "../hooks/useCoreData";

export default function PayoutItemComponent({ item }: { item: IPayoutEntry }) {
  const { t } = useTranslation();
  const isMatchingDesktop = useBreakpointValue({ base: false, large: true });

  const displayPayoutStatus = (status: PayoutStatus) => {
    switch (status) {
      case PayoutStatus.Paid:
        return t("Payout.Paid");
      case PayoutStatus.Planned:
        return t("Payout.Planned");
    }
  };

  const displayPayoutType = (type: PayoutType) => {
    switch (type) {
      case PayoutType.FIXED_INTEREST:
        return t("Payout.FixedInterest");
      case PayoutType.VARIABLE_INTEREST:
        return t("Payout.VariableInterest");
      case PayoutType.PRINCIPAL:
        return t("Payout.Principal");
      case PayoutType.MIXED_INTEREST:
        return t("Payout.Principal");
      case PayoutType.OWNER_PAYOUT:
        return t("Payout.OwnerPayout");
    }
  };

  return (
    <Box key={item.id} mb={20}>
      <Expander
        type="single"
        isCollapsible={true}
        style={{ position: "relative" }}
      >
        {isMatchingDesktop ? (
          <Box style={{ position: "absolute", top: 8, right: 70 }}>
            <Badge variation="info">
              {displayPayoutStatus(item.status as PayoutStatus)}
            </Badge>
          </Box>
        ) : null}

        <ExpanderItem
          title={`${t("Payout.Settlement")}: ${moment(
            item.settlement_start
          ).format("DD.MM.YYYY")} - ${moment(item.settlement_end).format(
            "DD.MM.YYYY"
          )}, ${item.amount} ${item.currency}`}
          value="item-1"
        >
          <Box>
            <Heading level={6}>{`${t("Payout.Settlement")}: ${moment(
              item.settlement_start
            ).format("DD.MM.YYYY")} - ${moment(item.settlement_end).format(
              "DD.MM.YYYY"
            )}`}</Heading>
          </Box>

          <Box>
            {t("PaymentId")}: {item.id}
          </Box>

          <Box>
            {t("Payout.Title")}: {item.payout_title}
          </Box>

          <Box>
            {t("Payout.Amount")}: {item.amount} {item.currency}
          </Box>

          <Box>
            {t("BankAccount")}: {item.receiver_iban}
          </Box>

          <Box>
            {t("Payout.Type")}: {displayPayoutType(item.type as PayoutType)}
          </Box>

          <Box>
            {t("Payout.Status")}: {displayPayoutStatus(item.status)}
          </Box>

          {!item.paid_date && (
            <Box>
              {t("Payout.PlannedPaymentDate")}:{" "}
              {moment(item.planned_payment_date).format("DD.MM.YYYY")}
            </Box>
          )}

          {item.paid_date && (
            <Box>
              {t("Payout.PaidDate")}:{" "}
              {moment(item.paid_date).format("DD.MM.YYYY")}
            </Box>
          )}

          {item.settlement_url && (
            <Box>
              <Button
                variation="link"
                onClick={async () => {
                  if (item.settlement_url) {
                    await downloadFile(item.settlement_url);
                  }
                }}
              >
                {t("Payout.Settlement")}
              </Button>
            </Box>
          )}
        </ExpanderItem>
      </Expander>
    </Box>
  );
};
