import { Button } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import PressableExternalLink from "../../Discovery/components/PressableExternalLink";

export default function CheckinButton({ reservationId }: { reservationId: string }) {
  const { t } = useTranslation();

  return (
    <PressableExternalLink
      url={`https://rentujemy.typeform.com/checkin?rid=${reservationId}`}
    >
      <Button isFullWidth={true} variation="primary" size="large">
        {t("GuestReservation.CheckInNow")}
      </Button>
    </PressableExternalLink>
  );
};
