import { MdCheckCircleOutline } from "react-icons/md";
import { theme } from "../../theme";

export default function SuccessIcon() {
  return (
    <MdCheckCircleOutline
      size={20}
      color={theme.success}
    ></MdCheckCircleOutline>
  );
};
