import { Button, Flex, StepperField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineSearch } from "react-icons/md";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Headline2 from "../../components/Headline2";
import { borderBottomStyle } from "../commonStyles";
import { FilterSearchObj } from "../components/DiscoveryMobileSearch";

export default function GuestsBottomsheet({
  open,
  onSelected,
  onDismiss,
}: {
  open: boolean;
  onDismiss: () => void;
  onSelected: (filter: FilterSearchObj) => void;
}) {
  const [searchObj, setSearchObj] = useState<FilterSearchObj>({});
  const { t } = useTranslation();

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <Flex padding={16} style={{ ...borderBottomStyle }}>
        <Headline2>{t("Discovery.Guests.Header")}</Headline2>
      </Flex>

      <Flex direction={"column"} style={borderBottomStyle}>
        <Flex justifyContent={"center"}>
          <Flex
            direction={"column"}
            alignItems={"flex-start"}
            padding="16px"
            grow={1}
          >
            <Flex>
              <StepperField
                width={"100%"}
                max={10}
                min={1}
                defaultValue={2}
                step={1}
                borderRadius="20px"
                label={t("Discovery.Search.Guests")}
                placeholder={t("Discovery.Search.Guests")}
                onStepChange={(newValue) => {
                  setSearchObj({
                    guests: String(newValue) || "2",
                  });
                }}
              />
            </Flex>
            <Flex grow={1} width={"100%"}>
              <Button
                width={"100%"}
                variation="primary"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => onSelected(searchObj)}
              >
                <MdOutlineSearch size={40}></MdOutlineSearch>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </BottomSheet>
  );
};
