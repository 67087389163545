import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { RouteObject } from "react-router";
import AuthWrapper from "./Auth/AuthWrapper";
import GuestReservations from "./Guest/GuestReservations";
import OwnerCockpitPage from "./OwnerCockpitPage/OwnerCockpitPage";
import OwnerPropertyReservationPage from "./OwnerCockpitPage/OwnerPropertyReservationPage";
import OwnerStayPage from "./OwnerCockpitPage/OwnerStayPage";
import PayoutsPage from "./Payouts/PayoutsPage";
import Box from "./components/Box";
import RentujemyLogo from "./components/RentujemyLogo";
import UserLanguageApplier from "./components/UserLanguageApplier";
import { UserProfileProvider } from "./components/UserProfileProvider";

function applyWrappers(routes: RouteObject[]) {
  return routes.map((item) => {
    item.errorElement = (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Flex direction={"column"} textAlign={"center"}>
          <Box style={{ margin: "0 auto", maxWidth: 600, textAlign: "center" }}>
            <RentujemyLogo />
          </Box>

          <p>Przepraszamy, ale coś poszło nie tak po naszej stronie.</p>
          <p>
            Prosimy spróbować ponownie później. Jeśli problem będzie się
            powtarzał, prosimy o kontakt z naszym działem obsługi klienta.
          </p>
          <p>
            <a href="https://rentujemy.pl">Wróć na stronę główną</a>
          </p>
        </Flex>
      </Flex>
    );

    item.element = (
      <Authenticator.Provider>
        <AuthWrapper>
          <UserProfileProvider>
            <UserLanguageApplier>{item.element}</UserLanguageApplier>
          </UserProfileProvider>
        </AuthWrapper>
      </Authenticator.Provider>
    );

    return item;
  });
}

const customerRoutes: RouteObject[] = [
  ...applyWrappers([
    {
      path: "/",
      element: <OwnerCockpitPage />,
    },
    {
      path: "trips",
      element: <GuestReservations />,
    },
    {
      path: "/owner-cockpit",
      element: <OwnerCockpitPage />,
    },
    {
      path: "/owner-cockpit/:contractType/:contractId",
      element: <OwnerPropertyReservationPage />,
    },
    {
      path: "/owner-cockpit/:id/owner-stay",
      element: <OwnerStayPage />,
    },
    {
      path: "/payouts",
      element: <PayoutsPage />,
    },
  ]),
];

export default customerRoutes;
