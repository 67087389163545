import { useMemo } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { Paragraph } from "./DiscoveryTypography";

export default function DiscoveryFAQ() {
  const { t } = useTranslation();

  const items = useMemo(() => {
    const faqItems = [];

    for (let index = 0; index < 7; index++) {
      faqItems.push({
        header: t(`Discovery.FAQ.Section${index}Header` as any) as string,
        body: t(`Discovery.FAQ.Section${index}Body` as any) as string,
      });
    }

    return faqItems;
  }, [t]);

  return (
    <Accordion defaultActiveKey="0" alwaysOpen={true}>
      {items.map((item, index) => (
        <Accordion.Item eventKey={String(index)}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>
            <Paragraph>{item.body}</Paragraph>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
