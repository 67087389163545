export enum ChannelName {
  "BOOKING" = "bookingcom",
  "AIRBNB" = "airbnbOfficial",
  "STAY" = "bookingengine",
  "DIRECT" = "direct",
  "PARTNER" = "partner",
  "HOMEAWAY" = "homeaway",
  "VRBO" = "vrbo",
}

export const getChannel = (channel: ChannelName) => {
  switch (channel) {
    case ChannelName.BOOKING:
      return "Booking";
    case ChannelName.AIRBNB:
      return "Airbnb";
    case ChannelName.STAY:
      return "RENTUJEMY.COM";
    case ChannelName.DIRECT:
      return "RENTUJEMY.COM";
    case ChannelName.PARTNER:
      return "RENTUJEMY.COM";
    case ChannelName.HOMEAWAY:
      return "Homeaway";
    case ChannelName.VRBO:
      return "VRBO";
    default:
      return "RENTUJEMY.COM";
  }
};
