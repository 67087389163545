import { Flex, Link } from "@aws-amplify/ui-react";

interface OwnerCockpitListingLinksProps {
  links: { name: string; url: string }[];
}

export default function OwnerCockpitListingLinks({ links }: OwnerCockpitListingLinksProps) {
  return (
    <Flex>
      <Flex direction="column" gap={0}>
        {links.map((link, index) =>
          link.url ? (
            <Link
              isExternal={true}
              href={link.url}
              key={index}
              fontStyle={"italic"}
              fontSize={"large"}
            >
              {link.name}
            </Link>
          ) : (
            <></>
          )
        )}
      </Flex>
    </Flex>
  );
};
