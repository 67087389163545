import { TabItem, Tabs } from "@aws-amplify/ui-react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../components/Box";
import PayoutFilterTab from "./PayoutFilterTab";
import useAccountActiveYears from "./useAccountActiveYears";
import { useSearchParams } from "react-router-dom";
import { PayoutStatus } from "../hooks/useCoreData";
import { PropertyDetails } from "../hooks/useProperties";

interface PayoutFilterProps {
  hasInvestObjectFilter: boolean;
  startMonth: number;
  setStartMonth: Dispatch<React.SetStateAction<number>>;
  startYear: number;
  setStartYear: Dispatch<React.SetStateAction<number>>;
  endMonth: number;
  setEndMonth: Dispatch<React.SetStateAction<number>>;
  endYear: number;
  setEndYear: Dispatch<React.SetStateAction<number>>;
  setPayoutStatus: Dispatch<SetStateAction<PayoutStatus | undefined>>;
  investObjectId?: number;
  setInvestObjectId?: Dispatch<SetStateAction<number | undefined>>;
  investObjects?: PropertyDetails<boolean>[];
  handleInvestObjectsAutocompleteClick?: () => void;
}

export default function PayoutFilter({
  hasInvestObjectFilter,
  startMonth,
  setStartMonth,
  endMonth,
  setEndMonth,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  setPayoutStatus,
  investObjectId,
  setInvestObjectId,
  investObjects,
  handleInvestObjectsAutocompleteClick,
}: PayoutFilterProps) {
  const { activeYears } = useAccountActiveYears();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const TABS = ["All", "Paid", "Planned"];

  const section = useMemo(() => {
    return searchParams.get("section") || "All";
  }, [searchParams]);

  const [index, setIndex] = useState(TABS.indexOf(section));

  useEffect(() => {
    setSearchParams({ section: TABS[index] });
  }, [index, setSearchParams]);

  return (
    <Box mb={30}>
      <Tabs
        spacing="equal"
        currentIndex={index}
        onChange={(i) => {
          setIndex(i as number);
        }}
      >
        <TabItem
          title={t("All")}
          onClick={() => {
            setPayoutStatus(undefined);
          }}
        ></TabItem>
        <TabItem
          title={t("Paid")}
          onClick={() => {
            setPayoutStatus(PayoutStatus.Paid);
          }}
        ></TabItem>
        <TabItem
          title={t("Planned")}
          onClick={() => {
            setPayoutStatus(PayoutStatus.Planned);
          }}
        ></TabItem>
      </Tabs>
      <PayoutFilterTab
        hasInvestObjectFilter={hasInvestObjectFilter}
        startMonth={startMonth}
        setStartMonth={setStartMonth}
        startYear={startYear}
        setStartYear={setStartYear}
        endMonth={endMonth}
        setEndMonth={setEndMonth}
        endYear={endYear}
        setEndYear={setEndYear}
        activeYears={activeYears}
        investObjectId={investObjectId}
        setInvestObjectId={setInvestObjectId}
        investObjects={investObjects}
        handleInvestObjectsAutocompleteClick={
          handleInvestObjectsAutocompleteClick
        }
      />
    </Box>
  );
};
