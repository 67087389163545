import { Flex, Alert } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import Box from "../../components/Box";

export default function ReservationValuesDisclaimer() {
  const { t } = useTranslation();

  return (
    <Flex marginBottom={20} marginTop={10}>
      <Box>
        <Alert variation="info" isDismissible={false} hasIcon={true}>
          {t("Reservation.AdditionalCostDisclaimer")}
        </Alert>
      </Box>
    </Flex>
  );
};
