import { Widget } from "@typeform/embed-react";

export default function RentalLeadCaptureForm() {
  // Rentujemy Main Lead Capture
  return (
    <Widget
      id="UjogvZzj"
      style={{ height: 600, width: "100%" }}
      className="my-form"
    />
  );
};
