import axios from "axios";
import { apiEndpoint } from "../config";
import { DiscoveryListing } from "../Discovery/components/DiscoveryListing";
import { ComboBoxOption } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { buildQueryString } from "../utils/buildQueryString";
import { debounce } from "lodash";

export interface CorePlace {
  lat: number;
  lng: number;
  slug: string;
  city: string;
  country: string;
  street: string;
  street_no: string;
  region: string;
  google_place_id: string;
  postal_code: string;
  formatted: string;
}

interface AddressSuggestion {
  title: string;
  subtitle: string;
  placeId: string;
}

// PUBLIC
export const getAddressSuggestionDetails = async (
  placeId: string
): Promise<Partial<CorePlace>> => {
  const queryObj = { placeId };
  const queryString = buildQueryString({ ...queryObj });

  return (
    await axios.get(
      `${apiEndpoint}/v1/guest/discovery/autocomplete/details${queryString}`
    )
  ).data;
};

// PUBLIC
export const getAddressSuggestions = async (
  query?: string
): Promise<AddressSuggestion[]> => {
  const queryObj = { query };
  const queryString = buildQueryString({ ...queryObj });

  return (
    await axios.get(
      `${apiEndpoint}/v1/guest/discovery/autocomplete${queryString}`
    )
  ).data;
};

export const useAddressAutocomplete = () => {
  const [data, setData] = useState<AddressSuggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const load = useCallback(
    debounce(async (query: string) => {
      setLoading(true);
      const results = await getAddressSuggestions(query);

      setData(results);

      setLoading(false);
    }, 1000),
    [setLoading, setData]
  );

  return { load, data, loading };
};

// PUBLIC
export const getDiscoverySearch = async (
  query?: string,
  filter?: {
    arrival?: string;
    departure?: string;
    tag?: string;
    guests?: number;
  }
): Promise<{
  listings: DiscoveryListing[];
  place?: Partial<CorePlace & Pick<CorePlace, "slug">>;
}> => {
  const queryObj: {
    arrival?: string;
    departure?: string;
    tag?: string;
    guests?: number;
    query?: string;
  } = { ...filter, query };
  const queryString = buildQueryString({ ...queryObj });

  return (
    await axios.get(`${apiEndpoint}/v1/guest/discovery/search${queryString}`)
  ).data;
};

// PUBLIC
export const getDestinations = async (): Promise<{
  destinations: ComboBoxOption[];
}> => (await axios.get(`${apiEndpoint}/v1/guest/discovery/destinations`)).data;

export const useDestinations = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ComboBoxOption[]>();
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(() => {
    setLoading(true);

    (async () => {
      try {
        setData((await getDestinations()).destinations);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, setData, setError]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch, data]);

  return { data, loading, error, refetch };
};
