import { Alert, Flex } from "@aws-amplify/ui-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReservationData } from "../../hooks/customer/useReservations";
import { CalendarContext } from "../context/CalendarContext";
import { getFormattedMonth } from "../utils/getFormattedMonthName";
import MonthSelector from "./MonthSelector";
import OwnerPropertyReservationPageTable from "./OwnerPropertyReservationPageTable";
import StatLabel from "./StatLabel";

interface OwnerPropertyReservationSingleMonthProps {
  items: ReservationData[];
  avgPayout: number;
  avgDailyRate: number;
  occupancyRate: number | undefined;
  onNext?: () => void;
  onBack?: () => void;
}

export default function OwnerPropertyReservationSingleMonth({
  items,
  avgPayout,
  avgDailyRate,
  occupancyRate,
  onNext,
  onBack,
}: OwnerPropertyReservationSingleMonthProps) {
  const { t } = useTranslation("translation");
  const { selectedMonth } = useContext(CalendarContext);

  return (
    <>
      <MonthSelector
        onBack={onBack}
        onNext={onNext}
        monthName={getFormattedMonth(selectedMonth)}
      ></MonthSelector>

      {!items.length && (
        <Alert
          marginBottom={20}
          marginTop={10}
          variation="info"
          textAlign={"center"}
          isDismissible={false}
          hasIcon={false}
          heading={t("Reservation.NoCheckoutsInThisMonth")}
        />
      )}

      {!!items.length && (
        <Flex marginTop={15} direction="column" marginBottom={30}>
          <Flex gap={{ base: 15, small: 25 }}>
            <StatLabel
              value={occupancyRate || 0}
              label={t("Reservation.OccupancyRate")}
              unit={"%"}
            />
            <StatLabel
              value={avgDailyRate}
              label={t("Reservation.AverageDailyPrice")}
              unit={"zł"}
            />
            <StatLabel
              value={avgPayout}
              label={t("Reservation.AverageDailyRate")}
              unit={"zł"}
            />
          </Flex>

          <Flex overflow="scroll">
            <OwnerPropertyReservationPageTable reservations={items} />
          </Flex>
        </Flex>
      )}
    </>
  );
};
