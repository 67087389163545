import { Flex, Heading } from "@aws-amplify/ui-react";
import { MdCheckCircleOutline } from "react-icons/md";
import { theme } from "../../theme";

export default function SuccessHeading({ label }: { label: string }) {
  return (
    <Flex direction={"row"} alignItems={"center"}>
      <Flex>
        <MdCheckCircleOutline
          size={50}
          color={theme.success}
        ></MdCheckCircleOutline>
      </Flex>
      <Flex>
        <Heading level={3}>{label}</Heading>
      </Flex>
    </Flex>
  );
};
