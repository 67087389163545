import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Headline2 from "../../components/Headline2";
import { borderBottomStyle } from "../commonStyles";
import DiscoveryMobileSearch, {
  FilterSearchObj,
} from "../components/DiscoveryMobileSearch";

export default function DatesBottomsheet({
  open,
  onSelected,
  onDismiss,
}: {
  open: boolean;
  onDismiss: () => void;
  onSelected: (filter: FilterSearchObj) => void;
}) {
  const { t } = useTranslation();

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <Flex direction={"column"} style={borderBottomStyle}>
        <Flex padding={16} style={{ ...borderBottomStyle }}>
          <Headline2>{t("Discovery.Dates.Header")}</Headline2>
        </Flex>

        <Flex justifyContent={"center"}>
          <DiscoveryMobileSearch
            onSubmit={(params) => {
              let newObj: Partial<FilterSearchObj> = {};

              if (params.arrival) {
                newObj = {
                  ...newObj,
                  arrival: params.arrival,
                };
              }

              if (params.departure) {
                newObj = {
                  ...newObj,
                  departure: params.departure,
                };
              }

              onSelected(newObj);
            }}
          ></DiscoveryMobileSearch>
        </Flex>
      </Flex>
    </BottomSheet>
  );
};
