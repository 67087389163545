import {
  Alert,
  Badge,
  Button,
  Flex,
  Heading,
  Placeholder,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  TextBold,
  TextMuted,
} from "../Discovery/components/DiscoveryTypography";
import Text from "../components/Text";
import { useAnalytics } from "../hooks/useAnalytics";
import { formatRangeNice } from "../utils/formatDate";
import AccessInstructionsBottomsheet from "./AccessInstructions/AccessInstructionsBottomsheet";
import SectionWithBoldText from "./AccessInstructions/SectionWithBoldText";
import CheckInButton from "./components/CheckInButton";
import FinalizePaymentButton from "./components/FinalizePaymentButton";
import SuccessIcon from "./components/SuccessIcon";
import { useReservationContext } from "./providers/ReservationProvider";

export const WrappedText = styled(Text)`
  text-wrap: pretty !important;
`;

export default function GuestPortalPage() {
  useAnalytics();

  const { t } = useTranslation();
  const params = useParams();
  const { reservation } = useReservationContext();
  const [accessOpen, setAccessOpen] = useState<boolean>(false);

  return (
    <>
      <Placeholder height={80} isLoaded={!!reservation} />

      {reservation && params.reservationId && accessOpen && (
        <AccessInstructionsBottomsheet
          open={true}
          onDismiss={() => setAccessOpen(false)}
          reservationId={params.reservationId}
        ></AccessInstructionsBottomsheet>
      )}

      {reservation && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            <Heading level={3}>
              {t("GuestReservation.Reservation")} {params.reservationId}
            </Heading>

            <Text>
              {formatRangeNice(
                reservation.checkinDate,
                reservation.checkoutDate
              )}
            </Text>

            {reservation.status === "cancelled" && (
              <Flex>
                <Badge>{t("Reservation.Cancelled")}</Badge>
              </Flex>
            )}

            {reservation.status === "ownerStay" && (
              <Flex>
                <Badge>{t("Reservation.OwnerStay")}</Badge>
              </Flex>
            )}
          </Flex>

          {reservation.status !== "cancelled" && (
            <Flex grow={1} direction="column">
              {reservation.totalPrice > reservation.totalPaid && (
                <Alert variation="warning">
                  {t("GuestReservation.OpenBalanceAlert")}
                </Alert>
              )}

              {reservation.totalPrice > reservation.totalPaid && (
                <FinalizePaymentButton
                  haReservationId={Number(params!.reservationId)}
                ></FinalizePaymentButton>
              )}

              {reservation.status !== "inquiry" && (
                <SectionWithBoldText header={t("GuestReservation.Access")}>
                  <Flex direction={"column"}>
                    {reservation.status !== "ownerStay" && (
                      <>
                        <Flex>
                          <TextMuted>
                            {t("GuestReservation.AccessSubtitle")}
                          </TextMuted>
                        </Flex>

                        {!reservation.hasCheckedIn && (
                          <CheckInButton
                            reservationId={params.reservationId!}
                          ></CheckInButton>
                        )}

                        {reservation.hasCheckedIn && (
                          <Flex justifyContent={"center"} alignItems={"center"}>
                            <SuccessIcon></SuccessIcon>
                            <TextBold style={{ fontSize: 20 }}>
                              {t("GuestReservation.CheckedIn")}
                            </TextBold>
                          </Flex>
                        )}
                      </>
                    )}

                    <Button
                      disabled={!reservation.canAccessCheckinInstructions}
                      isFullWidth={true}
                      variation="primary"
                      size="large"
                      onClick={() => setAccessOpen(true)}
                    >
                      {t("AccessInstructions.ButtonToOpen")}
                    </Button>
                  </Flex>
                </SectionWithBoldText>
              )}

              {reservation.status !== "ownerStay" && (
                <SectionWithBoldText
                  header={t("GuestReservation.Manage")}
                  paddingTop={12}
                >
                  <Flex direction={"column"}>
                    {moment(reservation.checkoutDate).isSameOrAfter(
                      moment()
                    ) && (
                        <Link to={`/gp/${params.reservationId!}/prolong`}>
                          <Button
                            disabled={!reservation.paid}
                            isFullWidth={true}
                            variation="primary"
                            size="large"
                            onClick={async () => { }}
                          >
                            {t("GuestReservation.Title")}
                          </Button>
                        </Link>
                      )}

                    {reservation.earlyCheckin !== "unavailable" && (
                      <Link to={`/gp/${params.reservationId}/early_checkin`}>
                        <Button
                          disabled={!reservation.paid}
                          isFullWidth={true}
                          variation="primary"
                          size="large"
                          onClick={async () => { }}
                        >
                          {t("EarlyCheckin.Submit")}
                        </Button>
                      </Link>
                    )}

                    {reservation.lateCheckout !== "unavailable" && (
                      <Link to={`/gp/${params.reservationId}/late_checkout`}>
                        <Button
                          isFullWidth={true}
                          variation="primary"
                          size="large"
                          onClick={async () => { }}
                        >
                          {t("LateCheckout.Submit")}
                        </Button>
                      </Link>
                    )}

                    {reservation.babyCot !== "unavailable" && (
                      <Link to={`/gp/${params.reservationId}/baby_cot`}>
                        <Button
                          isFullWidth={true}
                          variation="primary"
                          size="large"
                          onClick={async () => { }}
                        >
                          {t("BabyCot.Submit")}
                        </Button>
                      </Link>
                    )}
                  </Flex>
                </SectionWithBoldText>
              )}

              {reservation.status !== "ownerStay" && (
                <Link to={`/gp/${params.reservationId}/invoice`}>
                  <Button
                    disabled={!reservation.canRequestInvoice}
                    isFullWidth={true}
                    variation="primary"
                    size="large"
                    onClick={async () => { }}
                  >
                    {t("GuestReservation.RequestInvoice")}
                  </Button>
                </Link>
              )}

              {false && (
                <Link to={`/gp/${params.reservationId!}/cleaning`}>
                  <Button
                    isFullWidth={true}
                    variation="primary"
                    size="large"
                    onClick={async () => { }}
                  >
                    Order New Cleaning
                  </Button>
                </Link>
              )}
            </Flex>
          )}

          {false && (
            <Flex grow={1}>
              <Flex direction="column">
                <Heading level={5}>
                  or subscribe to RENTUJEMY and enjoy all the benefits
                </Heading>
                <Text>
                  Join RENTUJEMY GOLD for exclusive perks:
                  <ul>
                    <li>15% off direct bookings</li>
                    <li>order weekly cleaning without additional costs</li>
                    <li>Premium guest support/concierge.</li>
                    <li>
                      Free early check-in/late check-out (availability-based)
                    </li>
                    <li>Weekly cleaning without additional costs</li>
                    <li>
                      Premium guest support/concierge. Elevate your stay with
                      RENTUJEMY GOLD!
                    </li>
                  </ul>
                </Text>
              </Flex>
            </Flex>
          )}
          {false && (
            <Flex grow={1}>
              <Link to={`https://buy.stripe.com/7sI6shem88Reakg5lg`}>
                <Button
                  variation="primary"
                  isFullWidth={true}
                  size="large"
                  onClick={async () => { }}
                >
                  Subscribe to RENTUJEMY GOLD
                </Button>
              </Link>

              <Link
                to={`https://billing.stripe.com/p/login/dR68yf60K9cV33a144`}
              >
                <Button
                  isFullWidth={true}
                  size="large"
                  onClick={async () => { }}
                >
                  Manage Subscription
                </Button>
              </Link>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
