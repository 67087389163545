import { Button, Flex } from "@aws-amplify/ui-react";
import moment from "moment";
import { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { MdOutlineSearch } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import i18n from "../../i18n/i18n";
import "./react-datepicker.overrides.css";

export interface FilterSearchObj {
  city?: string;
  arrival?: string;
  departure?: string;
  guests?: string;
}

export default function DiscoveryMobileSearch({
  onSubmit,
}: {
  defaultValues?: { city?: string };
  onSubmit: (props: FilterSearchObj) => void;
}) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [startDate, setStartDate] = useState<Date | undefined>(
    searchParams.get("arrival")!
      ? new Date(searchParams.get("arrival")!)
      : new Date()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    searchParams.get("departure")!
      ? new Date(searchParams.get("departure")!)
      : undefined
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      arrival: moment(startDate).format("YYYY-MM-DD"),
      departure: moment(endDate).format("YYYY-MM-DD"),
    });
  }, [startDate, endDate, onSubmit]);

  return (
    <Flex direction={"column"} alignItems={"center"} padding="16px" grow={1}>
      <Flex direction={"column"}>
        <Flex direction={"column"}>
          <DatePicker
            dayClassName={() => "react-picker-day"}
            monthsShown={2}
            locale={i18n.language}
            placeholderText={t("Discovery.Search.AddDates")}
            selected={startDate}
            dateFormat={"d.MM.YYYY"}
            onChange={(date) => {
              setStartDate(date[0] || undefined);
              setEndDate(date[1] || undefined);
            }}
            startDate={startDate}
            minDate={new Date()}
            endDate={endDate}
            selectsRange
            inline
          />
        </Flex>
      </Flex>

      <Flex grow={1} width={"100%"}>
        <Button
          width={"100%"}
          variation="primary"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleSubmit}
        >
          <MdOutlineSearch size={40}></MdOutlineSearch>
        </Button>
      </Flex>
    </Flex>
  );
};
