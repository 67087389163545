import moment from "moment";
import { useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { PropertyDetails, useProperties } from "../hooks/useProperties";
import { logger } from "../logger";
import { PayoutStatus } from "../hooks/useCoreData";

const usePayoutStatus = () => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("section") as PayoutStatus;

  return [PayoutStatus.Paid, PayoutStatus.Planned].includes(tab)
    ? tab
    : undefined;
};

export default function usePayoutFilter(cognitoId?: string) {
  const [startMonth, setStartMonth] = useState(
    moment().add(1, "month").month()
  );
  const [startYear, setStartYear] = useState(
    moment().subtract(1, "year").year()
  );
  const [endMonth, setEndMonth] = useState(moment().month());
  const [endYear, setEndYear] = useState(moment().year());
  const [payoutStatus, setPayoutStatus] = useState<PayoutStatus | undefined>(
    usePayoutStatus()
  );
  const [investObjectId, setInvestObjectId] = useState("");
  const [investObjects, setInvestObjects] =
    useState<PropertyDetails<boolean>[]>();
  const { getProperties } = useProperties();

  // const handleContractsAutocompleteClick = useCallback(() => {
  //   const load = async () => {
  //     try {
  //       setContracts([]);
  //     } catch (error) {
  //       logger.error(error);
  //     }
  //   };

  //   load();
  // }, [cognitoId]);

  const handleInvestObjectsAutocompleteClick = useCallback(() => {
    const load = async () => {
      try {
        const items = await getProperties();

        setInvestObjects(items);
      } catch (error) {
        logger.error(error);
      }
    };

    load();
  }, [getProperties]);

  return {
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    payoutStatus,
    setPayoutStatus,
    investObjectId,
    setInvestObjectId,
    investObjects,
    handleInvestObjectsAutocompleteClick,
  };
};
