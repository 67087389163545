import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
`;

export default function PressableExternalLink({ children, url }: PropsWithChildren<{ url: string }>) {
  return (
    <StyledLink href={url} target="_blank">
      {children}
    </StyledLink>
  )
};
