import { isValidNIP } from "../isValidNIP";
import { InvoiceForm } from "../types/InvoiceForm";
import { checkVAT, countries } from "jsvat";
import postalCodes from "postal-codes-js";

export type OptionalErrors<T> = {
  [K in keyof T]?: string;
};

export const validateInvoiceForm = (
  formData: InvoiceForm
): { canSubmit: boolean; errors: OptionalErrors<InvoiceForm> } => {
  const errors: OptionalErrors<InvoiceForm> = {};
  let canSubmit = true;

  if (!formData.firstName) {
    canSubmit = false;
  }

  if (!formData.lastName) {
    canSubmit = false;
  }

  if (!formData.street) {
    canSubmit = false;
  }

  if (!formData.city) {
    canSubmit = false;
  }

  if (!formData.country) {
    canSubmit = false;
  }

  if (!formData.postalCode) {
    canSubmit = false;
  }

  if (!formData.email) {
    canSubmit = false;
  }

  // vat validation only for poland
  if (
    formData.country === "PL" &&
    formData.vatId &&
    !isValidNIP(formData.vatId)
  ) {
    canSubmit = false;
    errors.vatId = "NIP is not correct.";
  }

  // vat validation for other countries
  if (
    formData.country !== "PL" &&
    formData.vatId &&
    !checkVAT(formData.vatId, countries).isValid
  ) {
    canSubmit = false;
    errors.vatId = "NIP is not correct.";
  }

  if (
    formData.postalCode &&
    postalCodes.validate(formData.country, formData.postalCode) !== true
  ) {
    canSubmit = false;
    errors.postalCode = "Invoice.InvalidPostalCode";
  }

  return { canSubmit, errors };
};
