import { Image, Link } from "@aws-amplify/ui-react";
import React from "react";
import Box from "../components/Box";

export default function PublicHeader({ logoHref }: { logoHref?: string }) {
  return (
    <Box style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
      <Box style={{ margin: "0 auto", textAlign: "center" }} mb={35}>
        <Link isExternal={true} href={logoHref || "/"}>
          <Image
            alt="Rentujemy.pl Logo"
            src="/rentujemy-logo-subtitle.png"
            objectFit="initial"
            backgroundColor="initial"
            width="400px"
            opacity="100%"
          />
        </Link>
      </Box>
    </Box>
  );
};
