import { API } from "@aws-amplify/api";
import { prepareHerokuRequest } from "./common";

export interface PropertyDetails<IsInvestProperty extends boolean> {
  internal_id: number;
  airbnb_url: string;
  booking_url: string;
  google_url: string;
  vrbo_url: string;
  hostaway_id: number;
  name: string;
  is_mgmt_property: boolean;
  assigned_mgmt_contracts: number[];
  is_invest_property: boolean;
  invest_property_data: IsInvestProperty extends true
  ? {
    appartmentSize: number;
    totalFinancingAmount: number;
    expectedPricePerNight: number;
    expectedRoi: number;
    currency: string;
  }
  : null;
  assigned_invest_contracts: number[];
  disabled: boolean;
}

const getProperties = async (): Promise<PropertyDetails<boolean>[]> =>
  await API.get(
    "herokuapi",
    `/v1/admin/contracts/properties`,
    await prepareHerokuRequest()
  );

const getProperty = async (
  internalId: string
): Promise<PropertyDetails<boolean>> =>
  await API.get(
    "herokuapi",
    `/v1/admin/contracts/properties/${internalId}`,
    await prepareHerokuRequest()
  );

const getPropertyByHostawayId = async (
  hostawayId: number
): Promise<PropertyDetails<boolean>> =>
  await API.get(
    "herokuapi",
    `/v1/admin/contracts/properties/hostaway/${hostawayId}`,
    await prepareHerokuRequest()
  );

const updateProperty = async (
  internalId: number,
  body: PropertyDetails<boolean>
): Promise<PropertyDetails<boolean>> =>
  await API.put("herokuapi", `/v1/admin/contracts/properties/${internalId}`, {
    ...(await prepareHerokuRequest()),
    body,
  });

export const useProperties = () => {
  return {
    getPropertyByHostawayId,
    getProperty,
    getProperties,
    updateProperty,
  };
};
