import { Flex } from "@aws-amplify/ui-react";
import "react-spring-bottom-sheet/dist/style.css";
import { theme } from "../../theme";
import { IconBaseProps } from "react-icons/lib";

export default function MobileNavTab({
  isActive,
  onPress,
  label,
  icon: Icon,
}: {
  isActive: boolean;
  onPress: () => void;
  label: string;
  icon: (props: IconBaseProps) => React.ReactElement;
}) {
  return (
    <Flex direction={"column"} alignItems={"center"} gap={4} onClick={onPress}>
      <Flex>
        <Icon
          color={isActive ? theme.primaryColor : theme.muted}
          size={20}
        ></Icon>
      </Flex>
      <Flex
        fontWeight={isActive ? 600 : undefined}
        color={isActive ? theme.primaryColor : theme.muted}
      >
        {label}
      </Flex>
    </Flex>
  );
};
