import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DiscoveryListing } from "./DiscoveryListing";
import DiscoveryListingComponent from "./DiscoveryListingComponent";
import Headline from "../../components/Headline";
import { DISCOVERY_LINK } from "../links";

export default function DiscoveryResults({ listings }: { listings?: DiscoveryListing[] }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex direction="column">
      {listings?.length === 0 && (
        <Flex direction="column" padding={16}>
          <Flex direction="column">
            <Headline>{t("Discovery.Results.NoResultsHeader")}</Headline>
            <Text>{t("Discovery.Results.NoResultsSubtitle")}</Text>
          </Flex>
          <Flex marginTop={16}>
            <Button
              size="large"
              onClick={() => {
                navigate(DISCOVERY_LINK);
              }}
            >
              {t("Discovery.Results.NoResultsCTA")}
            </Button>
          </Flex>
        </Flex>
      )}

      <Flex direction="column">
        <Flex
          paddingLeft={8}
          paddingRight={8}
          direction="row"
          gap="20px"
          wrap="wrap"
          justifyContent="space-around"
          marginTop="20px"
        >
          {listings?.map((listing, index) => (
            <DiscoveryListingComponent
              key={index}
              listing={listing}
            ></DiscoveryListingComponent>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
