import { Sidetab } from "@typeform/embed-react";
import { theme } from "../theme";

export default function SupportForum() {
  return (
    <Sidetab
      buttonText="Mam pytanie!"
      buttonColor={theme.primaryColor}
      id="xZT8SNUW"
    />
  );
};
