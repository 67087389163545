import { Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import PressableExternalLink from "../Discovery/components/PressableExternalLink";

export default function ConfirmTermsComponent() {
  const { t } = useTranslation();

  return (
    <Text>
      {t("AcceptTerms.AcceptTerms")}{" "}
      <PressableExternalLink url={"https://www.rentujemy.pl/terms-of-service"}>
        {t("AcceptTerms.TermsHyperlink")}
      </PressableExternalLink>
      {t("AcceptTerms.AcceptTermsPart2")}{" "}
      <PressableExternalLink url={"https://www.rentujemy.pl/privacy-policy"}>
        {t("AcceptTerms.PrivacyHyperlink")}
      </PressableExternalLink>{" "}
      {t("AcceptTerms.AcceptTermsPart3")}
    </Text>
  );
};
