import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import prices from "../template/pricesKrakow";

export default function KrakowRegions() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4}>
      <Heading level={4} marginBottom={10}>
        {t("shortTermCalculator.chooseDistrict")}
      </Heading>
      {prices.map((item) => (
        <Button
          key={item.region}
          onClick={() => {
            navigate(item.slug);
          }}
        >
          {item.region}
        </Button>
      ))}
    </Flex>
  );
};
