import { Heading, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import Box from "../components/Box";

export default function OwnerIncomeHeading() {
  const { t } = useTranslation();

  return (
    <Box mt={24}>
      <Box mb={40}>
        <Heading level={3}>{t("IncomeCalculator.Title")}</Heading>
        <Text>{t("IncomeCalculator.OwnerSubtitle")}</Text>
      </Box>
    </Box>
  );
};
