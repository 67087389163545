import { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import Redirect from "../components/Redirect";
import BookingSuccessPage from "./BookingSuccessPage";
import EarlyCheckinPage from "./EarlyCheckin/EarlyCheckinPage";
import ErrorPage from "./ErrorPage";
import FinalizeBookingPage from "./FinalizeBooking/FinalizeBookingPage";
import GuestPortalPage from "./GuestPortalPage";
import InvoicePage from "./Invoice/InvoicePage";
import LateCheckoutPage from "./LateCheckout/LateCheckoutPage";
import LateCheckoutSuccessPage from "./LateCheckout/LateCheckoutSuccessPage";
import ProlongPage from "./ProlongPage";
import GuestAuthWrapper from "./components/AuthWrapper";
import GuestLayout from "./components/GuestLayout";
import { ReservationProvider } from "./providers/ReservationProvider";
import { SessionProvider } from "./providers/SessionProvider";
import EarlyCheckinSuccessPage from "./EarlyCheckin/EarlyCheckinSuccessPage";
import BabyCotPage from "./BabyCot/BabyCotPage";

const publicGuestRoutes: RouteObject[] = [
  {
    path: "/gp",
    element: (
      <SessionProvider>
        <GuestLayout>
          <GuestAuthWrapper isPublic={true}>
            <ReservationProvider>
              <Outlet />
            </ReservationProvider>
          </GuestAuthWrapper>
        </GuestLayout>
      </SessionProvider>
    ),
    errorElement: (
      <GuestLayout>
        <ErrorPage />
      </GuestLayout>
    ),
    children: [
      {
        path: ":reservationId",
        element: <GuestPortalPage />,
      },
      {
        path: ":reservationId/prolong",
        element: <ProlongPage />,
      },
      {
        path: ":reservationId/early_checkin",
        element: <EarlyCheckinPage />,
      },
      {
        path: ":reservationId/late_checkout",
        element: <LateCheckoutPage />,
      },
      {
        path: ":reservationId/baby_cot",
        element: <BabyCotPage />,
      },
      {
        path: ":reservationId/finalize",
        element: <FinalizeBookingPage />,
      },
      {
        path: ":reservationId/finalize/success",
        element: <BookingSuccessPage />,
      },
      {
        path: ":reservationId/late_checkout/success",
        element: <LateCheckoutSuccessPage />,
      },

      {
        path: ":reservationId/early_checkin/success",
        element: <EarlyCheckinSuccessPage />,
      },
    ],
  },
];

const authenticatedGuestRoutes: RouteObject[] = [
  {
    path: "/gp",
    element: (
      <SessionProvider>
        <GuestLayout>
          <GuestAuthWrapper isPublic={false}>
            <ReservationProvider>
              <Outlet />
            </ReservationProvider>
          </GuestAuthWrapper>
        </GuestLayout>
      </SessionProvider>
    ),
    errorElement: (
      <GuestLayout>
        <ErrorPage />
      </GuestLayout>
    ),
    children: [
      {
        path: ":reservationId/invoice",
        element: <InvoicePage />,
      },
    ],
  },
];

const compatGuestRoutes: RouteObject[] = [
  {
    path: "/invoice/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/invoice"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/prolong/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/prolong"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/early_checkin/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/early_checkin"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/late_checkout/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/late_checkout"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/finalize/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/finalize"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/finalize/:reservationId/success",
    element: (
      <Redirect
        to="/gp/:reservationId/finalize/success"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/late_checkout/:reservationId/success",
    element: (
      <Redirect
        to="/gp/:reservationId/late_checkout/success"
        paramNames={["reservationId"]}
      />
    ),
  },
];

const routes = [
  ...publicGuestRoutes,
  ...authenticatedGuestRoutes,
  ...compatGuestRoutes,
  { path: "*", element: <Redirect to="/" paramNames={[]} /> },
];

export default routes;
