import React from "react";

import { Link } from "@aws-amplify/ui-react";

import { theme } from "../theme";

export default function ExternalLink(props: { label: string; href: string; utmSource?: string }) {
  return (
    <Link
      color={theme.primaryColor}
      textDecoration="underline dotted"
      isExternal={true}
      href={
        props.utmSource
          ? `${props.href}?utm_source=${props.utmSource}`
          : props.href
      }
      target="_blank"
    >
      {props.label}
    </Link>
  )
};
