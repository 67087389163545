import {
  Button,
  Flex,
  Placeholder,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDiscoverySearch } from "../hooks/useGuestDiscoveryApi";
import { useSearchParamsUtils } from "../hooks/useSearchParamsUtils";
import { logger } from "../logger";
import { theme } from "../theme";
import { borderBottomStyle, borderTopStyle } from "./commonStyles";
import DiscoveryAbout from "./components/DiscoveryAbout";
import { useDiscoveryContext } from "./components/DiscoveryContext";
import DiscoveryFAQ from "./components/DiscoveryFAQ";
import DiscoveryFooter from "./components/DiscoveryFooter";
import DiscoveryHeader from "./components/DiscoveryHeader";
import { DiscoveryListing } from "./components/DiscoveryListing";
import DiscoveryMap from "./components/DiscoveryMap";
import DiscoveryResults from "./components/DiscoveryResults";
import DiscoverySearchBar from "./components/DiscoverySearchBar";
import { Heading2 } from "./components/DiscoveryTypography";
import DiscoveryUSPs from "./components/DiscoveryUSPs";

const SECTION_WIDTH = 1250;

export default function DiscoveryDesktopPage() {
  const { t } = useTranslation();
  const [listings, setListings] = useState<DiscoveryListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ searchString?: string }>();
  const [searchParams] = useSearchParams();
  const isMatchingDesktop = useBreakpointValue({ large: true, base: false });
  const { setPlace, place } = useDiscoveryContext();
  const navigate = useNavigate();
  const { setMultipleSearchParams, setSingleSearchParam } =
    useSearchParamsUtils();

  useEffect(() => {
    setLoading(true);
    (async () => {
      logger.info(
        `Searching ${params.searchString} ${typeof params.searchString}`
      );

      const result = await getDiscoverySearch(params.searchString, {
        arrival: searchParams.get("arrival")!,
        departure: searchParams.get("departure")!,
        tag: searchParams.get("tag")!,
        guests: Number(searchParams.get("guests")!) || undefined,
      });

      setPlace(result.place);
      setListings(result.listings);

      setLoading(false);
    })();
  }, [params, setListings, setLoading, searchParams]);

  const categories = ["Prestige", "Business", "Budget"];

  const showMap = useMemo(() => {
    // only the city specific pages
    // entry page should not have displayed map due to cost optimization and limited value
    if (!!params.searchString) {
      return true;
    }

    return false;
  }, [params]);

  return (
    <>
      <Flex direction="column" className="discovery">
        <DiscoveryHeader>
          <Button
            variation="link"
            onClick={() => {
              const section = document.querySelector("#about");

              section?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          >
            {t("Header.About")}
          </Button>
          <Button
            variation="link"
            onClick={() => {
              const section = document.querySelector("#faq");

              section?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          >
            {t("Header.FAQ")}
          </Button>
        </DiscoveryHeader>

        <Flex direction={"column"} style={borderBottomStyle}>
          <Flex justifyContent={"center"}>
            <DiscoverySearchBar
              placeLabel={place?.formatted || params.searchString}
              defaultValues={{
                arrival: searchParams.get("arrival")! || undefined,
                departure: searchParams.get("departure")! || undefined,
                guests: Number(searchParams.get("guests")!) || undefined,
              }}
              onSubmit={(values) => {
                const newSearchParams = setMultipleSearchParams(
                  {
                    ...values,
                  },
                  false
                );

                navigate(`?${newSearchParams.toString()}`);
              }}
            ></DiscoverySearchBar>
          </Flex>
        </Flex>

        {/* Categories Section */}
        <Flex
          className="discovery-page"
          direction="row"
          gap="20px"
          justifyContent="center"
        >
          {categories.map((category) => (
            <Button
              key={category}
              variation={
                searchParams.get("tag")! === category.toLowerCase()
                  ? "primary"
                  : "link"
              }
              onClick={() => {
                setSingleSearchParam(
                  "tag",
                  searchParams.get("tag")! === category.toLocaleLowerCase()
                    ? undefined
                    : category.toLocaleLowerCase()
                );
              }}
            >
              {category}
            </Button>
          ))}
        </Flex>

        <Flex
          style={{ ...borderTopStyle, ...borderBottomStyle }}
          justifyContent={"flex-start"}
        >
          <Flex
            direction={"column"}
            grow={1}
            paddingLeft={showMap ? 0 : 32}
            paddingRight={showMap ? 0 : 32}
          >
            <Flex gap={0}>
              <Flex
                height={"80vh"}
                overflow={"scroll"}
                grow={1}
                basis={params.searchString ? "60%" : undefined}
                direction={"column"}
              >
                <Flex direction={"column"}>
                  {loading && (
                    <Flex
                      direction={"column"}
                      paddingTop={36}
                      paddingLeft={36}
                      paddingRight={36}
                    >
                      <Flex>
                        <Placeholder height={300}></Placeholder>
                        <Placeholder height={300}></Placeholder>
                        <Placeholder height={300}></Placeholder>
                      </Flex>
                      <Flex>
                        <Placeholder height={300}></Placeholder>
                        <Placeholder height={300}></Placeholder>
                        <Placeholder height={300}></Placeholder>
                      </Flex>
                    </Flex>
                  )}
                  {listings && !loading && (
                    <DiscoveryResults listings={listings}></DiscoveryResults>
                  )}
                </Flex>
              </Flex>

              {showMap && (
                <Flex
                  height={"80vh"}
                  overflow={"hidden"}
                  basis={isMatchingDesktop ? "40%" : "100%"}
                  backgroundColor={theme.lines}
                  direction={"column"}
                  justifyContent={"center"}
                >
                  {loading && <Placeholder height={"80vh"}></Placeholder>}

                  {listings && !loading && (
                    <DiscoveryMap
                      listings={listings}
                      loading={loading}
                    ></DiscoveryMap>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        <DiscoveryUSPs></DiscoveryUSPs>
        <DiscoveryAbout></DiscoveryAbout>

        <Flex grow={1} justifyContent={"center"}>
          <Flex
            maxWidth={SECTION_WIDTH}
            width={"100%"}
            direction={"column"}
            id="faq"
          >
            <Heading2>FAQ</Heading2>

            <DiscoveryFAQ />
          </Flex>
        </Flex>

        <DiscoveryFooter></DiscoveryFooter>
      </Flex>
    </>
  );
};
