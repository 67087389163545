import { Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React from "react";

export default function Stats({
  stats,
}: {
  stats: { value: string | number; name: string }[];
}) {
  return (
    <Flex justifyContent={"flex-start"} paddingBottom={16} paddingTop={16}>
      {stats.map((stat, index) => (
        <Card key={`stat-${index}`} variation="elevated">
          <Flex justifyContent={"center"} direction={"column"}>
            <Heading level={5}>{stat.value}</Heading>
            <Text>{stat.name}</Text>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};
