import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Link,
  SliderField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from "@aws-amplify/ui-react";
import Box from "../components/Box";
import { useParams, useSearchParams } from "react-router-dom";
import LoaderBox from "../components/LoaderBox";
import { useTranslation } from "react-i18next";
import calculateExpectedIncome from "../utils/calculateExpectedIncome";
import Disclaimer from "../components/Disclaimer";
import { apartmentExamples, StandardType } from "./apartmentExamples";
import { makeAmountNice } from "../utils/makeAmountNice";
import ExternalLink from "../components/ExternalLink";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { theme } from "../theme";
import { isNumeric } from "../utils/isNumeric";

const UTM_SOURCE = "rentujemy_calculator";

export default function OwnerIncomeCalculator() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const routeParams = useParams<{
    template?: string;
    region?: string;
    city?: string;
    type?: string;
    standard?: string;
  }>();

  // apartment
  const [item, setObj] = useState<{
    zipCode: string | undefined;
    apartmentSize: number;
    rooms: number;
    standardType: StandardType;
  }>({
    zipCode: "00-000",
    standardType: StandardType.Business,
    apartmentSize: 45,
    rooms: 2,
  });

  // hidden variables
  const [variables, setVariables] = useState<{
    realEstateValue: number;
    pricePerNight?: number;
    occupationRate?: number;
    currency: "PLN";
    occupiedNightsPerMonth: number;
    reservationPortalFees: number;
    managementFeeRate: number;
    runningCosts: number;
  }>({
    realEstateValue: 1500000,
    currency: "PLN",
    occupiedNightsPerMonth: 25,
    reservationPortalFees: 0.18,
    managementFeeRate: 0.17,
    runningCosts: 1000,
  });

  const [calculatedValues, setCalculatedValues] = useState<
    | {
      pricePerNight?: number;
      currency?: "PLN";
      taxes?: number;
      revenuePerMonth?: number;
      reservationFees?: number;
      managementFees?: number;
      ownerPayout?: number;
    }
    | undefined
  >();

  useEffect(() => {
    const price = searchParams.get("pricePerNight");

    if (!price || !isNumeric(price)) {
      navigate("/short-term-rental-calculator");
    }
  }, [item, variables, navigate, searchParams]);

  useEffect(() => {
    const rooms = searchParams.get("rooms");
    const standardType = searchParams.get("standardType");
    const zipCode = searchParams.get("zipCode");

    const template = apartmentExamples.find(
      (item) =>
        item.slug === routeParams.template && item.city === routeParams.city
    );

    const pricePerNight =
      Number(searchParams.get("pricePerNight")) || template?.pricePerNight;
    const occupancy =
      Number(searchParams.get("occupancy")) || template?.occupancy;

    if (pricePerNight) {
      const occupationRate = occupancy || 0.8;

      setVariables({
        ...variables,
        pricePerNight: pricePerNight,
        occupationRate: occupationRate,
        occupiedNightsPerMonth: Math.floor(occupationRate * 30),
      });
    }

    setObj({
      ...item,
      rooms: Number(rooms || item.rooms),
      zipCode: zipCode || undefined,
      standardType:
        standardType === (StandardType.Business || StandardType.Prestige)
          ? StandardType.Business
          : StandardType.Comfort,
    });
  }, []);

  useEffect(() => {
    if (!item) {
      return undefined;
    }

    try {
      if (!variables.pricePerNight) {
        setCalculatedValues(undefined);
        return;
      }

      const payoutCalculation = calculateExpectedIncome({
        expectedPricePerNight: variables.pricePerNight as number,
        occupationRate: variables.occupationRate,
      });

      setCalculatedValues({
        ...calculatedValues,
        ...payoutCalculation,
        pricePerNight: variables.pricePerNight,
      });
    } catch (_err) {
      setCalculatedValues(undefined);
    }
  }, [item, variables, calculatedValues]);

  if (!item) {
    return <LoaderBox></LoaderBox>;
  }

  return (
    <>
      {calculatedValues && (
        <Box>
          <Box mt={10}>
            <Table highlightOnHover={false}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Heading level={3}>
                      {variables.pricePerNight} {variables.currency}
                    </Heading>

                    <SliderField
                      label={t("IncomeCalculator.PricePerNight")}
                      defaultValue={350}
                      value={variables.pricePerNight}
                      onChange={(value) => {
                        setVariables({
                          ...variables,
                          pricePerNight: Number(value),
                        });
                      }}
                      min={150}
                      step={10}
                      max={1500}
                      size="large"
                      trackSize="15"
                      filledTrackColor={theme.primaryColor}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ExternalLink
                      label={t("IncomeCalculator.PricePerNightDisclaimer")}
                      utmSource={UTM_SOURCE}
                      href={
                        "https://www.rentujemy.pl/optymalizacja-cen-wynajmu-krotkoterminowego?utm_source=rental-calculator"
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box mt={24}>
            <Table highlightOnHover={false}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Heading level={3}>
                      {`${makeAmountNice(
                        (100 * variables.occupiedNightsPerMonth) / 30
                      )}%`}
                    </Heading>

                    <SliderField
                      label={t("IncomeCalculator.IncomeSubtitle", {
                        nights: variables.occupiedNightsPerMonth,
                        price: calculatedValues.pricePerNight,
                      })}
                      defaultValue={28}
                      value={variables.occupiedNightsPerMonth}
                      onChange={(value) => {
                        setVariables({
                          ...variables,

                          occupiedNightsPerMonth: Number(value),
                          occupationRate: value / 30,
                        });
                      }}
                      min={1}
                      max={30}
                      size="large"
                      trackSize="15"
                      filledTrackColor={theme.primaryColor}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ExternalLink
                      label={t("IncomeCalculator.OccupancyDisclaimer")}
                      utmSource={UTM_SOURCE}
                      href={
                        "https://www.rentujemy.pl/optymalizacja-cen-wynajmu-krotkoterminowego?utm_source=rental-calculator"
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box mt={24}>
            <Table highlightOnHover={false}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex direction="column">
                        <Heading level={5}>
                          {t("IncomeCalculator.OwnerPayout")}
                        </Heading>
                        <Heading level={2}>
                          {calculatedValues?.ownerPayout}
                          {variables.currency}
                        </Heading>
                      </Flex>
                      <Link
                        href={`/kalkulator-zwrotu-z-inwestycji-w-nieruchomosc?rentalIncome=${calculatedValues.ownerPayout}`}
                      >
                        <Button
                          size={"large"}
                          variation="primary"
                          isFullWidth={true}
                        >
                          {t("ROICalculator.CalculateROI")}
                          <MdArrowForwardIos style={{ marginLeft: 10 }} />
                        </Button>
                      </Link>
                    </Flex>
                    <Disclaimer className="mb-2 text-muted">
                      {t("IncomeCalculator.PayoutDisclaimer")}
                    </Disclaimer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box mt={40} mb={80}>
            <Box mb={12}>
              <Heading level={5}>
                {t("IncomeCalculator.IncomeAnalysis")}
              </Heading>
              <p>
                Wykorzystując dostarczone przez Ciebie informacje, nasz
                kalkulator wypłat wyliczył szacunkową wartość Twojej wypłaty
                związanej z wynajmem krótkoterminowym. Uwzględniamy różne
                czynniki takie jak miesięczny przychód, podatki, prowizję naszej
                firmy oraz koszty portali rezerwacyjnych, które zostały podane
                przez Ciebie. Na podstawie tych danych, nasz kalkulator oblicza
                szacunkową wartość Twojej wypłaty. Należy jednak pamiętać, że ta
                wartość jest jedynie szacunkowa i może nie odzwierciedlać
                dokładnej kwoty Twojej wypłaty. Aby uzyskać bardziej precyzyjny
                szacunek, zalecamy skontaktowanie się z nami w celu uzyskania
                szczegółowej analizy Twojego przypadku.
              </p>
            </Box>
            {calculatedValues && (
              <Table caption="" highlightOnHover={false}>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">
                      {t("IncomeCalculator.Calculation")}
                    </TableCell>
                    <TableCell as="th">{t("IncomeCalculator.Value")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      + {t("IncomeCalculator.MonthlyIncome")}
                    </TableCell>
                    <TableCell>
                      {calculatedValues.revenuePerMonth}
                      {variables.currency}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>- {t("IncomeCalculator.Taxes")}</TableCell>
                    <TableCell>
                      {calculatedValues.taxes}
                      {variables.currency}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <ExternalLink
                        utmSource={UTM_SOURCE}
                        label={`- ${t("IncomeCalculator.ManagementFeesCosts")}`}
                        href={"https://www.rentujemy.pl/ceny"}
                      />
                    </TableCell>
                    <TableCell>
                      {calculatedValues.managementFees}
                      {variables.currency}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      - {t("IncomeCalculator.ReservationFeesCosts")}
                    </TableCell>
                    <TableCell>
                      {calculatedValues.reservationFees}
                      {variables.currency}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Text fontWeight={900}>
                        {t("IncomeCalculator.OwnerPayout")}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text fontWeight={900}>
                        {calculatedValues.ownerPayout}
                        {variables.currency}
                      </Text>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Disclaimer className="mb-2 text-muted">
                        {t("IncomeCalculator.AdditionalRentalCosts")}
                      </Disclaimer>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
