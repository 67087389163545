import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import "./App.scss";
import DiscoveryPage from "./Discovery/DiscoveryPage";
import DiscoverySearchPage from "./Discovery/DiscoverySearchPage";
import { DISCOVERY_LINK } from "./Discovery/links";
import GuestRoutes from "./Guest/routes";
import OwnerIncomeCalculatorRoutes from "./OwnerIncomeCalculatorPage/routes";
import { RealEstateROICalculatorPage } from "./RealEstateROICalculatorPage";
import RentalOnboardingPage from "./RentalOnboardingPage/RentalOnboardingPage";
import { GOOGLE_MAPS_API } from "./config";
import { logger } from "./logger";
import CustomerRoutes from "./routes";
import LoginComponent from "./Auth/LoginPage";

export default function App() {
  return (
    <AmplifyProvider>
      <Authenticator.Provider>
        <RouterProvider
          router={createBrowserRouter(
            [
              {
                path: DISCOVERY_LINK,
                element: (
                  <APIProvider
                    apiKey={GOOGLE_MAPS_API}
                    onLoad={() => logger.log("Maps API has loaded.")}
                  >
                    <DiscoveryPage />
                  </APIProvider>
                ),
              },
              {
                path: "/login",
                element: <LoginComponent />,
              },
              {
                path: "/s/:searchString",
                element: (
                  <APIProvider
                    apiKey={GOOGLE_MAPS_API}
                    onLoad={() => logger.log("Maps API has loaded.")}
                  >
                    <DiscoverySearchPage />
                  </APIProvider>
                ),
              },
              ...CustomerRoutes,
              ...GuestRoutes,
              ...OwnerIncomeCalculatorRoutes,
              {
                path: "/kalkulator-zwrotu-z-inwestycji-w-nieruchomosc",
                element: <RealEstateROICalculatorPage />,
              },
              {
                path: "/rental-onboarding",
                element: <RentalOnboardingPage />,
              },
            ],
            {}
          )}
        />
      </Authenticator.Provider>
    </AmplifyProvider>
  );
};
