import styled from "styled-components";
import { theme } from "../../../theme";

export default styled.div`
  width: 100%;

  .fc-event {
    padding: 5px 0 5px 3px;
  }

  .fc-event-start {
    left: 20px;
  }

  .fc-daygrid-block-event:not(.fc-event-start) {
    margin-right: -20px;
  }

  .fc-daygrid-day-number {
    text-decoration: none;
    color: #000000;
  }

  .fc-col-header-cell-cushion {
    text-decoration: none;
    color: #000000;
  }

  .fc-day-today {
    background: ${theme.secondaryColor30} !important;
    border: none !important;
  }

  .fc-toolbar-title {
    font-size: 25px;
  }
  .fc-button-primary {
    background-color: ${theme.primaryColor};
  }
`;
