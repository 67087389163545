import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDestinations } from "../hooks/useGuestDiscoveryApi";
import DiscoveryPage from "./DiscoveryPage";

export default function DiscoverySearchPage() {
  const { t } = useTranslation();
  const { data } = useDestinations();
  const params = useParams<{ searchString?: string }>();

  const cityName = useMemo(() => {
    return (data || []).find((item) => item.id === params.searchString)?.label;
  }, [data, params]);

  return (
    <DiscoveryPage
      title={cityName ? `${cityName} - ${t("SEO.Discovery.Title")}` : undefined}
    ></DiscoveryPage>
  );
};
