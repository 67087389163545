import { Text, Flex, Expander, ExpanderItem } from "@aws-amplify/ui-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
export default function ListingExpandableDetails({ description }: { description: string }) {
  const { t } = useTranslation();

  return (
    <Flex marginBottom={40}>
      <Expander type="single" isCollapsible={true}>
        <ExpanderItem title={t("OfferDescription")} value="expander">
          <Text>
            {description?.split("\n").map((item, key) => {
              return (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              );
            })}
          </Text>
        </ExpanderItem>
      </Expander>
    </Flex>
  );
};
