import { Flex, Placeholder } from "@aws-amplify/ui-react";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import styled from "styled-components";
import { getDiscoverySearch } from "../hooks/useGuestDiscoveryApi";
import { logger } from "../logger";
import { theme } from "../theme";
import DiscoveryMobileBottomSection from "./DiscoveryMobileBottomSection";
import DatesBottomsheet from "./bottomsheets/DatesBottomsheet";
import GuestsBottomsheet from "./bottomsheets/GuestsBottomsheet";
import { borderBottomStyle } from "./commonStyles";
import { useDiscoveryContext } from "./components/DiscoveryContext";
import DiscoveryHeader from "./components/DiscoveryHeader";
import { DiscoveryListing } from "./components/DiscoveryListing";
import DiscoveryMap from "./components/DiscoveryMap";
import DiscoveryMobileSearchBar from "./components/DiscoveryMobileSearchBar";
import DiscoveryResults from "./components/DiscoveryResults";
import { ParagraphHeading } from "./components/DiscoveryTypography";
import MobileNavTabs, { NAV_TABS_HEIGHT } from "./components/MobileNavTabs";

const MOBILE_HEADER_HEIGHT = 135;

logger.debug("Device detection", {
  isMobile,
  isSafari,
});

const StyledMapContainer = styled(Flex)`
  position: absolute;
  background-color: ${theme.lines};
  width: 100%;
  height: calc(
    100svh - ${NAV_TABS_HEIGHT}px -
      ${isMobile && isSafari ? "env(safe-area-inset-bottom) + 0px" : "0px"}
  );
  overflow: auto;
`;

const MAP_CONTROL_ZINDEX = 1;

export default function DiscoveryMobilePage() {
  useEffect(() => {
    import("./discovery-page.styles.mobile.css" as any).then(() => {
      logger.debug("./discovery-page.styles.mobile.css imported");
    });
  }, []);

  const { t } = useTranslation();
  const [listings, setListings] = useState<DiscoveryListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ searchString?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [searchBottomSheet, setSearchBottomSheet] = useState(false);
  const [guestBottomSheet, setGuestsBottomSheet] = useState(false);

  const { setLocationBottomSheetOpen, setPlace, place, setSelectedMapCenter } =
    useDiscoveryContext();

  useEffect(() => {
    setLoading(true);
    (async () => {
      logger.info(
        `Searching ${params.searchString} ${typeof params.searchString}`
      );

      const result = await getDiscoverySearch(params.searchString, {
        arrival: searchParams.get("arrival")!,
        departure: searchParams.get("departure")!,
        tag: searchParams.get("tag")!,
        guests: Number(searchParams.get("guests")!) || undefined,
      });

      setPlace(result.place);
      if (result.place && result.place.lat && result.place.lng) {
        setSelectedMapCenter({
          lat: Number(result.place.lat),
          lng: Number(result.place.lng),
        });
      }
      setListings(result.listings);
      setLoading(false);

      if (result.listings.length === 0) {
        setOpen(true);
      }
    })();
  }, [params, setListings, setLoading, searchParams]);

  const showMap = useMemo(() => {
    // only the city specific pages
    // entry page should not have displayed map due to cost optimization and limited value
    if (!!params.searchString) {
      return true;
    }

    return false;
  }, [params]);

  return (
    <>
      <Flex
        padding={0}
        margin={0}
        gap={0}
        direction="column"
        className="discovery"
        height={`calc(
        100svh -
          ${NAV_TABS_HEIGHT}px -
          ${isMobile && isSafari ? "env(safe-area-inset-bottom) + 0px" : "0px"}
      )`}
        overflow={"hidden"}
      >
        <GuestsBottomsheet
          onDismiss={() => setGuestsBottomSheet(false)}
          open={guestBottomSheet}
          onSelected={(props) => {
            setGuestsBottomSheet(false);

            if (props.guests) {
              searchParams.set("guests", props.guests);
            }

            setSearchParams(searchParams);
          }}
        ></GuestsBottomsheet>

        <DatesBottomsheet
          onDismiss={() => setSearchBottomSheet(false)}
          open={searchBottomSheet}
          onSelected={(params) => {
            setOpen(true);
            setSearchBottomSheet(false);

            if (params.arrival && params.departure) {
              searchParams.set("arrival", params.arrival);
              searchParams.set("departure", params.departure);
            }

            setSearchParams(searchParams);
          }}
        />

        <BottomSheet
          onDismiss={() => setOpen(false)}
          open={open}
          snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
        >
          {loading && (
            <Flex
              direction={"column"}
              paddingTop={36}
              paddingLeft={36}
              paddingRight={36}
            >
              <Placeholder height={300}></Placeholder>
              <Placeholder height={300}></Placeholder>
            </Flex>
          )}

          {!loading && (
            <Flex justifyContent={"center"} paddingTop={16}>
              <ParagraphHeading fontWeight={800}>
                {listings?.length} {t("Discovery.Results.Count")}
              </ParagraphHeading>
            </Flex>
          )}

          {!loading && (
            <DiscoveryResults listings={listings}></DiscoveryResults>
          )}
        </BottomSheet>

        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          gap={0}
          style={{ ...borderBottomStyle, zIndex: MAP_CONTROL_ZINDEX }}
          direction={"column"}
        >
          <Flex
            grow={1}
            backgroundColor={theme.background}
            style={{ ...borderBottomStyle }}
          >
            <DiscoveryHeader></DiscoveryHeader>
          </Flex>

          <DiscoveryMobileSearchBar
            selectedLocation={place?.formatted || params.searchString!}
            selectedDates={
              searchParams.get("arrival")! && searchParams.get("departure")!
                ? `${moment(searchParams.get("arrival")!).format(
                  "ll"
                )} - ${moment(searchParams.get("departure")!).format("ll")}`
                : undefined
            }
            selectedGuests={searchParams.get("guests")!}
            onLocation={() => setLocationBottomSheetOpen(true)}
            onDates={() => setSearchBottomSheet(true)}
            onGuests={() => setGuestsBottomSheet(true)}
          ></DiscoveryMobileSearchBar>
        </Flex>

        {showMap && (
          <DiscoveryMobileBottomSection
            onOpen={() => {
              setOpen(true);
            }}
          />
        )}

        {showMap && (
          <StyledMapContainer>
            <DiscoveryMap
              doNotShowSelected={true}
              listings={listings}
              loading={loading}
            ></DiscoveryMap>
          </StyledMapContainer>
        )}

        {!showMap && (
          <Flex
            className="main-results"
            width={"100%"}
            overflow={"scroll"}
            height={"100vhs"}
            position={"absolute"}
            top={MOBILE_HEADER_HEIGHT}
            bottom={NAV_TABS_HEIGHT}
          >
            {loading && (
              <Flex
                height={"100vhs"}
                width={"100%"}
                direction={"column"}
                paddingLeft={36}
                paddingTop={12}
                paddingBottom={12}
                paddingRight={36}
              >
                <Placeholder height={260} borderRadius={12}></Placeholder>
                <Placeholder height={260} borderRadius={12}></Placeholder>
              </Flex>
            )}
            {!loading && (
              <DiscoveryResults listings={listings}></DiscoveryResults>
            )}
          </Flex>
        )}
      </Flex>

      <MobileNavTabs></MobileNavTabs>
    </>
  );
};
