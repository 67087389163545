import { Modal as BsModal } from "react-bootstrap";
import React from "react";

interface AdminPayoutPlanningDetailsProps {
  children: React.ReactElement | React.ReactElement[];
  title: string;
  onClose: () => void;
}

export default function Modal({
  children,
  title,
  onClose,
}: AdminPayoutPlanningDetailsProps) {
  return (
    <BsModal
      show={true}
      onHide={onClose}
      onBackdropClick={onClose}
      onExit={onClose}
    >
      <BsModal.Header closeButton>
        <BsModal.Title>{title}</BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>{children}</BsModal.Body>
    </BsModal>
  );
};
