import PublicHeader from "../molecules/PublicHeader";
import { Helmet } from "react-helmet";
import OwnerIncomeCta from "./OwnerIncomeCta";
import OwnerIncomeDisclaimer from "./OwnerIncomeDisclaimer";
import OwnerIncomeHeading from "./OwnerIncomeHeading";
import Box from "../components/Box";
import { Params, useParams, useSearchParams } from "react-router-dom";
import WarsowRegions from "./CityFormPages/WarsawRegions";
import ApartmentType from "./CityFormPages/ApartmentType";
import Standard, { getRegionObject } from "./CityFormPages/Standard";
import { useTranslation } from "react-i18next";
import TricityRegions from "./CityFormPages/TricityRegions";
import LodzRegions from "./CityFormPages/LodzRegions";
import { Cities } from "./model/Cities";
import City from "./CityFormPages/City";
import OwnerIncomeCalculator from "./OwnerIncomeCalculator";
import { Heading } from "@aws-amplify/ui-react";
import { ApartmentTypes } from "./model/ApartmentTypes";
import { ApartmentStandards } from "./model/ApartmentStandard";
import KrakowRegions from "./CityFormPages/KrakowRegions";

const UTM_SOURCE = "rentujemy_calculator";

export default function OwnerIncomeCalculatorPage() {
  const { t } = useTranslation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const price = searchParams.get("pricePerNight");

  const getContent = (params: Readonly<Params<string>>) => {
    if (price) {
      return <OwnerIncomeCalculator />;
    }

    if (params.type) {
      return <Standard />;
    }

    if (params.region) {
      return <ApartmentType />;
    }

    if (params.city?.toLowerCase() === Cities.WARSAW) {
      return <WarsowRegions />;
    }

    if (params.city?.toLowerCase() === Cities.TRICITY) {
      return <TricityRegions />;
    }

    if (params.city?.toLowerCase() === Cities.LODZ) {
      return <LodzRegions />;
    }

    if (params.city?.toLowerCase() === Cities.CRAKOW) {
      return <KrakowRegions />;
    }

    return <City />;
  };

  const useHeader = (
    city: Cities,
    region: string,
    type: ApartmentTypes,
    standard: ApartmentStandards
  ) => {
    let cityName;

    if (city === Cities.WARSAW) {
      cityName = t("shortTermCalculator.Warsaw");
    }

    if (city === Cities.TRICITY) {
      cityName = t("shortTermCalculator.Tricity");
    }

    if (city === Cities.LODZ) {
      cityName = t("shortTermCalculator.Lodz");
    }

    if (city === Cities.CRAKOW) {
      cityName = "Kraków";
    }

    const regionObject = getRegionObject(city);
    const regionName = regionObject?.find(
      (item) => item.slug === region
    )?.region;

    let typeName;

    if (type === ApartmentTypes.THREE_ROOMS) {
      typeName = t("shortTermCalculator.threeRooms");
    }

    if (type === ApartmentTypes.TWO_ROOMS) {
      typeName = t("shortTermCalculator.twoRooms");
    }

    if (type === ApartmentTypes.STUDIO) {
      typeName = t("shortTermCalculator.studio");
    }

    let standardName;

    if (standard === ApartmentStandards.COMFORT) {
      standardName = t("shortTermCalculator.comfort");
    }

    if (standard === ApartmentStandards.PREMIUM) {
      standardName = t("shortTermCalculator.premium");
    }

    return `${cityName ? `${cityName} ` : ""}${regionName ? ` - ${regionName}` : ""
      }${typeName ? ` - ${typeName}` : ""}${standardName ? ` - ${standardName}` : ""
      }`;
  };

  return (
    <Box className="container" style={{ maxWidth: 800 }}>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("IncomeCalculator.Title")} | Rentujemy</title>
          <link
            rel="canonical"
            href={
              params.city
                ? `https://invest.rentujemy.pl/short-term-rental-calculator/${params.city}`
                : "https://invest.rentujemy.pl/short-term-rental-calculator"
            }
          />
          <meta
            name="description"
            content="Oblicz, ile możesz zarobić na krótkoterminowym wynajmie swojego mieszkania z Rentujemy. Skorzystaj z naszego kalkulatora przychodów i sprawdź, jakie dochody przyniesie Ci wynajem krótkoterminowy. Dzięki Rentujemy zyskujesz pewność, że Twoje mieszkanie będzie zarabiało dla Ciebie jak najwięcej."
          />
          <meta
            name="keywords"
            content="kalkulator, przychody, wynajem, krótkoterminowy, mieszkanie, Rentujemy"
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <PublicHeader
          logoHref={`https://www.rentujemy.pl?utm_source=${UTM_SOURCE}`}
        ></PublicHeader>
        <OwnerIncomeHeading />
        <Heading level={3} marginBottom={10}>
          {useHeader(
            params.city as Cities,
            params.region as string,
            params.type as ApartmentTypes,
            params.standard as ApartmentStandards
          )}
        </Heading>
        {getContent(params)}
        <OwnerIncomeDisclaimer />
        <OwnerIncomeCta UTM_SOURCE={UTM_SOURCE} />
      </>
    </Box>
  );
};
