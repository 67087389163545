import { useHerokuApi } from "../common";

interface HostawayListing {
  city: string;
  country: string;
  description: string;
  hostaway_id: number;
  images: string[];
  name: string;
  street: string;
  tags: string[];
  zip_code: string;
}

interface CommonContractData {
  airbnb_url: string;
  booking_url: string;
  company_name: string;
  contract_cancelled: boolean;
  contract_id: number;
  first_name: string;
  google_url: string;
  hostaway_id: number;
  internal_property_id: number;
  last_name: string;
  name: string;
  property_disabled: boolean;
  user_id: number;
  vrbo_url: string;
}

interface MgmtContractData extends CommonContractData {
  participation_rate: number;
}

interface InvestContractData extends CommonContractData {
  share_of_project_income: number;
}

interface InvestContract extends InvestContractData {
  contract_type: "invest";
}

interface FixedPayoutMgmtContract extends MgmtContractData {
  contract_type: "mgmt";
  payout_type: "fixedPayout";
  fixed_payout_amount: number;
  fixed_payout_currency: string;
}

interface VariablePayoutMgmtContract extends MgmtContractData {
  contract_type: "mgmt";
  payout_type: "variablePayout";
}

type MgmtContract = FixedPayoutMgmtContract | VariablePayoutMgmtContract;

interface NoListing {
  listing_provider: "none";
}

interface HostawayListingData {
  listing_provider: "hostaway";
  listing_data: HostawayListing;
}

type ListingData = NoListing | HostawayListingData;

export type CustomerContractDetails = (MgmtContract | InvestContract) &
  ListingData;

export const useContracts = (allContracts = false) =>
  useHerokuApi<CustomerContractDetails>(
    `v1/customer/contracts?allContracts=${allContracts}`,
    {},
    "contracts"
  );

export const useContract = (type: string, id: number) =>
  useHerokuApi<CustomerContractDetails>(
    `v1/customer/contracts/${type}/${id}`,
    {},
    `contract-${type}-${id}`
  );
