import { Flex, Placeholder, Text } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GuestPublicContext, useCustomerApi } from "../hooks/useCustomerApi";
import SuccessHeading from "./components/SuccessHeading";

export default function BookingSuccessPage() {
  const { t } = useTranslation();
  const params = useParams();
  const [guestReservation, setGuestReservation] =
    useState<GuestPublicContext>();
  const { getGuestReservation } = useCustomerApi();

  useEffect(() => {
    const interval = setInterval(async () => {
      (async () => {
        const res = await getGuestReservation(params.reservationId!);

        setGuestReservation(res);

        if (res.paid === true) {
          clearInterval(interval);
        }
      })();
    }, 5000);

    return () => clearInterval(interval);
  }, [getGuestReservation, setGuestReservation]);

  return (
    <>
      {(!guestReservation || !guestReservation.paid) && (
        <Placeholder height={80}></Placeholder>
      )}

      {guestReservation && guestReservation.paid && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            <SuccessHeading
              label={t("FinalizeBooking.SuccessTitle")}
            ></SuccessHeading>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {t("Reservation.Id")}: {params.reservationId}
              </Text>
            </Flex>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {" "}
                {guestReservation.listingName}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {" "}
                {guestReservation.checkinDate} - {guestReservation.checkoutDate}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
