import { useTranslation } from "react-i18next";
import { Upsells } from "../Upsells";
import { useReservationContext } from "../providers/ReservationProvider";
import UpsellContentPage from "./UpsellContentPage";

export default function BabyCotPage() {
  const { t } = useTranslation();
  const { reservation } = useReservationContext();

  return (
    <UpsellContentPage
      productCode={Upsells.BABY_COT}
      availability={reservation?.babyCot}
      labels={{
        Submit: t("BabyCot.Submit"),
        Title: t("BabyCot.Title"),
        Subtitle: t("BabyCot.Subtitle"),
        NotAvailable: t("BabyCot.NotAvailable"),
        Success: t("BabyCot.Success"),
      }}
    />
  );
};
