import SEO from "@americanexpress/react-seo";
import { useTranslation } from "react-i18next";
import SupportForm from "../Typeform/SupportForm";
import Box from "../components/Box";
import Headline from "../components/Headline";
import { useAnalytics } from "../hooks/useAnalytics";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useOwnerStatements } from "../hooks/useOwnerStatement";
import Footer from "../molecules/Footer";
import OwnerHeader from "./OwnerHeader";
import OwnerListings from "./components/OwnerListings";
import { Flex } from "@aws-amplify/ui-react";

export default function OwnerCockpitPage() {
  useAnalytics();

  const { t } = useTranslation();
  useOwnerStatements();
  const { isAdmin, isCoordinator } = useCurrentUser();

  return (
    <>
      <SEO
        title={t("SEO.OwnerPortal.Title")}
        description={t("SEO.OwnerPortal.Description")}
        keywords={[
          "rent",
          "apartments",
          "short-term rental",
          "poland",
          "owner portal",
        ]}
        siteUrl="https://rentujemy.com"
        image={{
          src: "about-hero-img.png",
        }}
      />
      <OwnerHeader></OwnerHeader>
      <Flex className="container" paddingTop={24}>
        <SupportForm></SupportForm>

        <Box>
          <Box mb={40}>
            <Headline>{t("OwnerCockpit")}</Headline>
          </Box>

          <Box mb={40}>
            <OwnerListings listAll={isAdmin || isCoordinator} />
          </Box>
        </Box>
        <Footer />
      </Flex>
    </>
  );
};
