import React from "react";
import Box from "../components/Box";
import Headline2 from "../components/Headline2";
import Loader from "../components/LoaderBox";
import PayoutItemComponent from "./PayoutItemComponent";
import { Alert } from "@aws-amplify/ui-react";
import { IPayoutEntry } from "../hooks/useCoreData";

export default function PayoutSection({
  items,
  titleLabel,
  noItemsLabel,
}: {
  noItemsLabel: string;
  titleLabel: string;
  items?: IPayoutEntry[];
}) {
  return (
    <Box>
      <Headline2>{titleLabel}</Headline2>

      {!items && <Loader />}

      {items && (
        <>
          {!items.length ? (
            <Alert variation="info">{noItemsLabel}</Alert>
          ) : (
            <></>
          )}

          {items.map((item) => (
            <PayoutItemComponent key={item.id} item={item} />
          ))}
        </>
      )}
    </Box>
  );
};
