import { Flex, Image } from "@aws-amplify/ui-react";
import Box from "../components/Box";
import { useTranslation } from "react-i18next";

export default function ErrorPage() {
  const { t } = useTranslation();
  return (
    <Flex direction={"column"} textAlign={"center"}>
      <Box style={{ margin: "0 auto", maxWidth: 600, textAlign: "center" }}>
        <Image
          src="/rentujemycom.png"
          objectFit="fit"
          marginTop={150}
          paddingLeft={40}
          paddingRight={40}
          width={{
            base: "100%",
            small: "80%",
            medium: "60%",
          }}
          alt={"Rentujemy - Zarządzanie Najmem Krótkoterminowym"}
        />
      </Box>

      <p>{t("ErrorPage.SomethingWentWrong")}</p>
      <p>{t("ErrorPage.TryAgainLater")}</p>
      <p>
        <a href="https://rentujemy.com">{t("ErrorPage.ReturnToHomepage")}</a>
      </p>
    </Flex>
  );
}
