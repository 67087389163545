export const prices = [
  {
    region: "Bemowo",
    slug: "bemowo",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 248.9 },
      { type: "studio", standard: "premium", avgPricePerNight: 298.7 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 357.7 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 429.2 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 496.3 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 595.6 },
    ],
  },
  {
    region: "Białołęka",
    slug: "bialoleka",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 201.2 },
      { type: "studio", standard: "premium", avgPricePerNight: 241.4 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 288.7 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 346.5 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 400.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 480.4 },
    ],
  },
  {
    region: "Bielany",
    slug: "bielany",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 220.1 },
      { type: "studio", standard: "premium", avgPricePerNight: 264.1 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 315.8 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 378.9 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 437.3 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 524.7 },
    ],
  },
  {
    region: "Mokotów",
    slug: "mokotow",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 355.8 },
      { type: "studio", standard: "premium", avgPricePerNight: 427 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 511.1 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 613.3 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 708.2 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 849.9 },
    ],
  },
  {
    region: "Ochota",
    slug: "ochota",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 293.9 },
      { type: "studio", standard: "premium", avgPricePerNight: 352.7 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 422.7 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 507.2 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 585.5 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 702.6 },
    ],
  },
  {
    region: "Praga Południe",
    slug: "praga-poludnie",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 241.9 },
      { type: "studio", standard: "premium", avgPricePerNight: 290.3 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 347.1 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 416.5 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 480.5 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 576.6 },
    ],
  },
  {
    region: "Praga Północ",
    slug: "praga-polnoc",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 246.2 },
      { type: "studio", standard: "premium", avgPricePerNight: 295.4 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 353.1 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 423.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 488.2 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 585.8 },
    ],
  },
  {
    region: "Rembertów",
    slug: "rembertow",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 180.6 },
      { type: "studio", standard: "premium", avgPricePerNight: 216.7 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 259.4 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 311.3 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 359.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 431.6 },
    ],
  },
  {
    region: "Śródmieście",
    slug: "srodmiescie",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 415.2 },
      { type: "studio", standard: "premium", avgPricePerNight: 498.2 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 597.6 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 717.9 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 989.2 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 1187 },
    ],
  },
  {
    region: "Targówek",
    slug: "targowek",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 201.2 },
      { type: "studio", standard: "premium", avgPricePerNight: 241.4 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 288.7 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 346.5 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 400.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 480.4 },
    ],
  },
  {
    region: "Ursus",
    slug: "ursus",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 211.2 },
      { type: "studio", standard: "premium", avgPricePerNight: 253.4 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 303.9 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 364.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 421.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 506.1 },
    ],
  },
  {
    region: "Ursynów",
    slug: "ursynow",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 255.7 },
      { type: "studio", standard: "premium", avgPricePerNight: 306.8 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 368.9 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 442.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 512.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 615.2 },
    ],
  },
  {
    region: "Wawer",
    slug: "wawer",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 194.4 },
      { type: "studio", standard: "premium", avgPricePerNight: 233.3 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 279.8 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 335.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 367.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 441.2 },
    ],
  },
  {
    region: "Wilanów",
    slug: "wilanow",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 311.5 },
      { type: "studio", standard: "premium", avgPricePerNight: 373.8 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 447.8 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 537.3 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 619.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 743.3 },
    ],
  },
  {
    region: "Włochy",
    slug: "wlochy",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 223.8 },
      { type: "studio", standard: "premium", avgPricePerNight: 268.5 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 322.3 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 386.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 446.3 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 535.5 },
    ],
  },
  {
    region: "Wola",
    slug: "wola",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 293.8 },
      { type: "studio", standard: "premium", avgPricePerNight: 352.6 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 422.3 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 506.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 585.1 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 702.1 },
    ],
  },
  {
    region: "Żoliborz",
    slug: "zoliborz",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 308.6 },
      { type: "studio", standard: "premium", avgPricePerNight: 370.3 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 444.6 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 533.5 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 614.3 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 737.2 },
    ],
  },
];
