import moment from "moment";

const MONTH_DISPLAY_FORMAT = "MMMM YYYY";

/**
 * getFormattedMonth
 * @param month in format YYYY-MM, `undefined` for current month
 */
export const getFormattedMonth = (month?: string) => {
  return moment(month ? `${month}-01` : undefined).format(MONTH_DISPLAY_FORMAT);
};

/**
 * getFormattedMonthBasedOnDate
 * @param date in format YYYY-MM-DD, `undefined` for current month
 */
export const getFormattedMonthBasedOnDate = (date?: Date | string | null) => {
  return moment(date).format(MONTH_DISPLAY_FORMAT);
};
