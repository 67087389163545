import moment from "moment";

export const formatDate = (date: string | Date) =>
  moment(date).format("DD.MM.YYYY");

// niedziela, 14 lip 24
export const formatDateNice = (date: string | Date) =>
  moment(date).format("dddd, DD MMM YY");

// czw, 11 lip 2024
export const formatDateShortNice = (date: string | Date) =>
  moment(date).format("dd, DD MMM YY");

// czw, 11 lip 2024 - czw, 11 lip 2024
export const formatRangeNice = (start: string | Date, end: string | Date) =>
  `${formatDateShortNice(start)} - ${formatDateShortNice(end)}`;
