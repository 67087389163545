export enum StandardType {
  Comfort = "comfort",
  Business = "business",
  Prestige = "prestige",
}

export const apartmentExamples = [
  {
    slug: "kawalerka",
    title: "Kawalerka (do 35m2)",
    imageSrc: "/comfort.png",
    standardType: StandardType.Comfort,
    rooms: 1,
    city: "warszawa",
    zipCode: "00",
    pricePerNight: 220,
    occupancy: 0.8,
  },
  {
    slug: "2-pokoje",
    title: "2-pokoje\n",
    imageSrc: "/comfort-2.png",
    standardType: StandardType.Comfort,
    rooms: 2,
    city: "warszawa",
    zipCode: "00",
    pricePerNight: 275,
    occupancy: 0.8,
  },
  {
    slug: "2-pokoje-wysoki-standard",
    title: "2-pokoje (wysoki standard)",
    rooms: 2,
    city: "warszawa",
    imageSrc: "/business.png",
    zipCode: "00",
    standardType: StandardType.Business,
    pricePerNight: 420,
    occupancy: 0.8,
  },
  {
    slug: "2-pokoje-luksusowy-standard",
    title: "3-pokoje (standard luksusowy)",
    rooms: 3,
    city: "warszawa",
    zipCode: "00",
    imageSrc: "/prestige.png",
    standardType: StandardType.Business,
    pricePerNight: 800,
    occupancy: 0.8,
  },
];
