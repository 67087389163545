import Box from "../components/Box";
import { useTranslation } from "react-i18next";
import moneyFormatter from "../utils/moneyFormatter";
import { Currency, IPayoutEntry } from "../hooks/useCoreData";
import { useMemo } from "react";
import Stats from "../components/Stats";

interface PayoutTotalAmountProps {
  payouts?: IPayoutEntry[];
}

const amountByCurrency = (payouts: IPayoutEntry[], currency: Currency) =>
  payouts.reduce(
    (acc, { amount, currency }) => {
      switch (currency) {
        case Currency.PLN:
          return { ...acc, [Currency.PLN]: acc[Currency.PLN] + Number(amount) };
        case Currency.EUR:
          return { ...acc, [Currency.EUR]: acc[Currency.EUR] + Number(amount) };
        default:
          throw new Error(`Unknown currency: ${currency}`);
      }
    },
    { [Currency.PLN]: 0, [Currency.EUR]: 0 }
  );

export default function PayoutTotalAmount({ payouts }: PayoutTotalAmountProps) {
  const { t } = useTranslation();

  const stats = useMemo(() => {
    if (!payouts) {
      return [];
    }

    // we are not using EUR for now
    const { PLN: totalPlnAmount } = amountByCurrency(payouts, Currency.PLN);

    return totalPlnAmount === 0
      ? []
      : [
        {
          value: `${moneyFormatter(totalPlnAmount)} PLN`,
          name: t("Payout.TotalAmount"),
        },
        {
          value: `${moneyFormatter(totalPlnAmount / payouts.length)} PLN`,
          name: t("Payout.AvgPayout"),
        },
      ];
  }, [payouts, t]);

  return (
    <Box mb={20} style={{ display: "flex", flexDirection: "row" }}>
      <Stats stats={stats}></Stats>
    </Box>
  );
};
