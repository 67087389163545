import SEO from "@americanexpress/react-seo";
import { Alert, Flex, Heading } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "../components/Box";
import LoaderBox from "../components/LoaderBox";
import { useContract } from "../hooks/customer/useContracts";
import Footer from "../molecules/Footer";
import ListingExpandableDetails from "./components/ListingExpandableDetails";
import OwnerPropertyDetailsCard from "./components/OwnerPropertyDetailsCard";
import ReservationsSection from "./components/ReservationsSection";
import OwnerHeader from "./OwnerHeader";

export default function OwnerPropertyReservationPage() {
  const { t } = useTranslation();
  const { contractType, contractId } = useParams();

  const { data, error, isLoading } = useContract(
    contractType!,
    Number(contractId)
  );

  const contract = data[0];

  return (
    <>
      <SEO
        title={t("SEO.OwnerListing.Title", { name: contract?.name })}
        description={t("SEO.OwnerListing.Description", {
          name: contract?.name,
        })}
        keywords={[]}
        siteUrl="https://rentujemy.com/owner-cockpit"
        image={{
          src: "about-hero-img.png",
        }}
      />

      <OwnerHeader></OwnerHeader>

      <Flex className="container" paddingTop={35}>
        {!!contract && (
          <Flex direction="column">
            <Heading
              level={4}
              marginBottom={20}
            >{`${contract.name} (${contract.hostaway_id})`}</Heading>
            {contract && (
              <OwnerPropertyDetailsCard
                contract={contract}
              ></OwnerPropertyDetailsCard>
            )}

            {!contract.property_disabled &&
              contract.listing_provider === "hostaway" && (
                <ListingExpandableDetails
                  description={contract.listing_data.description || ""}
                ></ListingExpandableDetails>
              )}
          </Flex>
        )}

        {error && (
          <Box>
            <Alert
              variation="error"
              isDismissible={true}
              hasIcon={true}
              heading={`${t("OwnerCockpitError.SomeErrorCode")} - ${error.message
                }`}
            />
          </Box>
        )}

        {isLoading && <LoaderBox label={t("CustomerContracts.Loading")} />}

        {contract &&
          (contract.contract_type === "invest" ||
            contract.payout_type === "variablePayout") &&
          contract && (
            <ReservationsSection propertyId={contract.internal_property_id} />
          )}

        <Footer />
      </Flex>
    </>
  );
};
