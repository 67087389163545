import { Image } from "@aws-amplify/ui-react";

export default function RentujemyLogo() {
  return (
    <Image
      src="/logo.svg"
      objectFit="fit"
      marginTop={150}
      paddingLeft={40}
      paddingRight={40}
      width={{
        base: "100%",
        small: "80%",
        medium: "60%",
      }}
      alt={"Rentujemy - Zarządzanie Najmem Krótkoterminowym"}
    />
  );
};
