import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";

interface OwnerPropertyReservationSingleMonthProps {
  monthName: string;
  onNext?: () => void;
  onBack?: () => void;
}

export default function MonthSelector({
  monthName,
  onNext,
  onBack,
}: OwnerPropertyReservationSingleMonthProps) {
  return (
    <Flex direction="row" gap="1rem" justifyContent={"space-between"}>
      <Flex>
        {onBack && (
          <Button size="large" onClick={onBack}>
            <RxCaretLeft />
          </Button>
        )}
      </Flex>
      <Flex>
        <Heading level={5}>{monthName}</Heading>
      </Flex>
      <Flex>
        {onNext && (
          <Button size="large" onClick={onNext}>
            <RxCaretRight />
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
