import { Flex, Heading, Image, Text } from "@aws-amplify/ui-react";
import { useMemo, useRef } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { theme } from "../../theme";
import { useDiscoveryContext } from "./DiscoveryContext";
import { DiscoveryListing } from "./DiscoveryListing";
import PressableExternalLink from "./PressableExternalLink";

export default function DiscoveryListingComponent({
  listing,
  standalone,
}: {
  standalone?: boolean;
  listing: DiscoveryListing;
}) {
  const [searchParams] = useSearchParams();
  const { setFocusedListing, setSelectedListing } = useDiscoveryContext();
  const { t } = useTranslation();

  const myRef = useRef(null);
  const bookingContextQuery = useMemo(() => {
    if (!searchParams.get("arrival")) {
      return "";
    }

    return `?start=${searchParams.get("arrival")}&end=${searchParams.get(
      "departure"
    )}&numberOfGuests=2`;
  }, [searchParams]);

  return (
    <Flex
      boxShadow={standalone ? "large" : undefined}
      borderRadius={standalone ? 20 : 0}
      backgroundColor={"white"}
      ref={myRef}
      width={standalone ? 260 : isMobile ? undefined : 243}
      onMouseEnter={() => {
        if (isDesktop) {
          setFocusedListing(listing);
        }
      }}
      paddingLeft={!standalone ? 16 : undefined}
      paddingRight={!standalone ? 16 : undefined}
      grow={standalone ? undefined : 1}
      maxWidth={isMobile ? 430 : 300}
      onMouseLeave={() => {
        if (isDesktop) {
          setFocusedListing(undefined);
        }
      }}
      minWidth={200}
      direction="column"
      gap="10px"
      position={"relative"}
      marginBottom="20px"
    >
      {standalone && (
        <Flex
          borderRadius="50%"
          width="45px"
          height="45px"
          alignItems="center"
          boxShadow={"medium"}
          justifyContent="center"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedListing(undefined);
          }}
          position={"absolute"}
          right={10}
          top={10}
          backgroundColor={theme.background}
        >
          <MdOutlineClose size={24} color={theme.primaryColor}></MdOutlineClose>
        </Flex>
      )}
      <PressableExternalLink url={`${listing.url}${bookingContextQuery}`}>
        <Image
          width={"100%"}
          style={{
            borderTopLeftRadius: standalone ? 20 : 0,
            borderTopRightRadius: standalone ? 20 : 0,
          }}
          overflow={"hidden"}
          borderRadius={standalone ? undefined : 20}
          src={listing.imageUrl}
          alt={listing.name}
          objectFit="cover"
          height={standalone ? 175 : 260}
        />
        <Flex
          padding={standalone ? 16 : undefined}
          direction={"column"}
          gap={0}
        >
          <Heading level={6} margin="0">
            {listing.name}
          </Heading>
          <Text color={theme.muted}>{listing.description}</Text>
          <Text fontWeight={600}>
            {t("Discovery.Listing.Price", {
              price: Number(listing.price) || "-",
            })}
          </Text>
        </Flex>
      </PressableExternalLink>
    </Flex>
  );
};
