import { Storage } from "aws-amplify";

export const downloadFile = async (name: string) => {
  let fileUrl;

  if (name.startsWith("http")) {
    fileUrl = name;
  } else {
    fileUrl = await Storage.get(name, {
      level: "public",
    });
  }

  if (!fileUrl) {
    throw new Error("[downloadFile] File does not exist");
  }

  const link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute("download", name);
  link.href = fileUrl;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
