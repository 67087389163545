import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import LoginPage from "./LoginPage";
import Loader from "../components/Loader";

export default function AuthWrapper({ children }: PropsWithChildren) {
  const { user, isPending } = useAuthenticator();

  if (isPending) {
    return <Loader></Loader>;
  }

  if (!user) {
    return <LoginPage />;
  }

  return <Authenticator>{children}</Authenticator>;
};
