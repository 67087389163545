import { useTranslation } from "react-i18next";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import NavButton from "../Discovery/components/NavButton";
import { useBreakpointValue } from "@aws-amplify/ui-react";

export default function OwnerHeader() {
  const { t } = useTranslation();
  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });

  return (
    <DiscoveryHeader>
      {!isMatchingMobile && (
        <>
          <NavButton
            pathname="/owner-cockpit"
            label={t("OwnerCockpit")}
          ></NavButton>
          <NavButton pathname="/payouts" label={t("Payouts")}></NavButton>
        </>
      )}
    </DiscoveryHeader>
  );
};
