import { useTranslation } from "react-i18next";
import Box from "../components/Box";
import Disclaimer from "../components/Disclaimer";

export default function OwnerIncomeDisclaimer() {
  const { t } = useTranslation();

  return (
    <Box mt={24}>
      <Disclaimer className="mb-2 text-muted">
        {t("IncomeCalculator.IncomeDisclaimer")}
      </Disclaimer>
    </Box>
  );
};
