export default function moneyFormatter(amount: number | string, currency?: string) {
  amount = typeof amount === "string" ? parseFloat(amount) : amount;

  const formatted = amount.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return currency ? `${formatted} ${currency}` : formatted;
};
