import { translations } from "@aws-amplify/ui-react";
import { I18n as AmplifyI18n } from "aws-amplify";
import { pl as dateFnPl } from "date-fns/locale/pl";
import i18next from "i18next";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { initReactI18next } from "react-i18next";
import { logger } from "../logger";
import { awsAuthPl, en, pl } from "./langs";

const DEFAULT_LANG = "pl";
const SUPPORTED_LANGS = ["en", "pl"];
const RESOURCES = { en, pl };
const CACHE_KEY = "language";

class I18n {
  constructor() {
    this.initAmplify();
    this.initDatePicker();
    this.initI18next();

    this.setLanguage(this.detectLanguage());
  }

  get language() {
    return i18next.language;
  }

  setLanguage(lang: string) {
    if (!SUPPORTED_LANGS.includes(lang)) {
      logger.debug(`Unsupported Language ${lang}`);
      return;
    }

    logger.info(`Setting language to ${lang}`);

    AmplifyI18n.setLanguage(lang);
    i18next.changeLanguage(lang);
    moment.locale(lang);

    document.documentElement.setAttribute("lang", lang);
    localStorage.setItem(CACHE_KEY, lang);
  }

  detectLanguage() {
    const cachedLang = localStorage.getItem(CACHE_KEY);

    if (cachedLang) {
      return cachedLang;
    }

    const langOptions = (navigator.languages ?? [navigator.language]).map(lang => lang.split("-")[0]);

    for (const lang of langOptions) {
      if (SUPPORTED_LANGS.includes(lang)) {
        return lang;
      }
    }

    return DEFAULT_LANG;
  }

  private initAmplify() {
    AmplifyI18n.putVocabularies(translations);

    AmplifyI18n.putVocabularies({
      pl: {
        ...awsAuthPl,
      },
    });

    // https://github.com/aws-amplify/amplify-ui/blob/de1c874f294a3b21cc9d7a97b310d2744d18b065/packages/ui/src/i18n/dictionaries/authenticator/de.ts
    // I18n.putVocabulariesForLanguage("pl");
  }

  private initDatePicker() {
    registerLocale("pl", dateFnPl);
  }

  private initI18next() {
    i18next
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: RESOURCES,
        supportedLngs: SUPPORTED_LANGS,
        fallbackLng: DEFAULT_LANG,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
  }
}

export default new I18n();
