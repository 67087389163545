import { Badge, Button, Flex } from "@aws-amplify/ui-react";
import moment from "moment";
import { MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  TextBold,
  TextMuted,
} from "../../Discovery/components/DiscoveryTypography";
import { GuestReservation } from "../../hooks/guest-reservation";
import { useTranslation } from "react-i18next";

export default function ReservationComponent({ reservation }: { reservation: GuestReservation }) {
  const { t } = useTranslation();

  return (
    <Flex direction={"column"} boxShadow={"medium"} gap={0}>
      <Link
        to={`/gp/${reservation.id}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Flex
          padding={16}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex direction={"column"} gap={4}>
            <Flex>
              <TextBold>{reservation.listingName}</TextBold>
            </Flex>
            <Flex>
              <TextMuted>
                {moment(reservation.arrivalDate).format("ll")} -{" "}
                {moment(reservation.departureDate).format("ll")}
                {" • "}
                {t("Reservation.Guests", {
                  numberOfGuests: reservation.numberOfGuests,
                })}
              </TextMuted>
            </Flex>
            <Flex>
              <TextMuted>ID: {reservation.id}</TextMuted>
            </Flex>

            <Flex>
              {reservation.status !== "ownerStay" && (
                <Badge>{reservation.channelName}</Badge>
              )}

              {reservation.status === "ownerStay" && (
                <Badge>{t("Reservation.OwnerStay")}</Badge>
              )}

              {reservation.status === "cancelled" && (
                <Badge>{t("Reservation.Cancelled")}</Badge>
              )}

              {reservation.status !== "cancelled" && (
                <Badge>
                  {moment(reservation.departureDate).isAfter(new Date())
                    ? t("GuestReservation.Active")
                    : t("GuestReservation.Past")}
                </Badge>
              )}
            </Flex>
          </Flex>
          <Flex>
            <MdChevronRight></MdChevronRight>
          </Flex>
        </Flex>
      </Link>

      <Flex>
        <Button
          onClick={() => {
            window.open(reservation.listingUrl, "_blank");
          }}
          justifyContent={"space-between"}
          grow={1}
          variation="link"
        >
          {t("Reservation.RebookListing")} <MdChevronRight></MdChevronRight>
        </Button>
      </Flex>
    </Flex>
  );
};
