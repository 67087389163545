export const prices = [
  {
    region: "Gdańsk",
    slug: "gdansk",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 267.3 },
      { type: "studio", standard: "premium", avgPricePerNight: 321.0 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 383.4 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 460.1 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 529.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 635.7 },
    ],
  },
  {
    region: "Sopot",
    slug: "sopot",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 311.8 },
      { type: "studio", standard: "premium", avgPricePerNight: 374.1 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 448.0 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 537.6 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 620.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 744.9 },
    ],
  },
  {
    region: "Gdynia",
    slug: "gdynia",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 247.5 },
      { type: "studio", standard: "premium", avgPricePerNight: 297.0 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 356.4 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 427.7 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 491.7 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 590.1 },
    ],
  },
];
