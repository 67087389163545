import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApartmentTypes } from "../model/ApartmentTypes";

export default function ApartmentType() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4}>
      <Heading level={4} marginBottom={10}>
        {t("shortTermCalculator.chooseType")}
      </Heading>
      <Button
        onClick={() => {
          navigate(ApartmentTypes.STUDIO);
        }}
      >
        {t("shortTermCalculator.studio")}
      </Button>
      <Button
        onClick={() => {
          navigate(ApartmentTypes.TWO_ROOMS);
        }}
      >
        {t("shortTermCalculator.twoRooms")}
      </Button>
      <Button
        onClick={() => {
          navigate(ApartmentTypes.THREE_ROOMS);
        }}
      >
        {t("shortTermCalculator.threeRooms")}
      </Button>
    </Flex>
  );
};
