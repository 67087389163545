import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../components/Box";
import Headline from "../components/Headline";
import { useUserProfile } from "../components/UserProfileProvider";
import Footer from "../molecules/Footer";
import PayoutSection from "../CockpitPage/PayoutSection";
import { logger } from "../logger";
import PayoutFilter from "./PayoutFilter";
import usePayoutFilter from "./usePayoutFilter";
import PayoutTotalAmount from "./PayoutTotalAmount";
import SupportForm from "../Typeform/SupportForm";
import { IPayoutEntry, useCoreData } from "../hooks/useCoreData";
import OwnerHeader from "../OwnerCockpitPage/OwnerHeader";

export default function PayoutsPage() {
  const [payouts, setPayouts] = useState<IPayoutEntry[]>();

  const { t } = useTranslation();
  const { listPayouts } = useCoreData();
  const { userProfile } = useUserProfile();

  const {
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    payoutStatus,
    setPayoutStatus,
  } = usePayoutFilter(userProfile?.cognito_id);

  useEffect(() => {
    const load = async () => {
      let data: IPayoutEntry[] = [];

      try {
        if (userProfile) {
          data = await listPayouts(
            payoutStatus,
            new Date(startYear, startMonth, 1),
            new Date(endYear, endMonth + 1, 0)
          );
        }
      } catch (error) {
        logger.error(error);
      } finally {
        setPayouts(data);
      }
    };

    load();
  }, [
    userProfile,
    listPayouts,
    setPayouts,
    payoutStatus,
    startMonth,
    startYear,
    endMonth,
    endYear,
  ]);

  return (
    <>
      <OwnerHeader></OwnerHeader>

      <Box className="container" pt={35}>
        <SupportForm></SupportForm>

        <Box>
          <Box mb={10}>
            <Headline>{t("Moje Wypłaty")}</Headline>
          </Box>
          <PayoutTotalAmount payouts={payouts} />
          <PayoutFilter
            hasInvestObjectFilter={false}
            startMonth={startMonth}
            setStartMonth={setStartMonth}
            startYear={startYear}
            setStartYear={setStartYear}
            endMonth={endMonth}
            setEndMonth={setEndMonth}
            endYear={endYear}
            setEndYear={setEndYear}
            setPayoutStatus={setPayoutStatus}
          />
          <Box mb={40}>
            <PayoutSection
              titleLabel={""}
              noItemsLabel={t("NoInvestPayouts")}
              items={payouts}
            />
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
