import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
import React from "react";
import { useTranslation } from "react-i18next";
import PressableExternalLink from "./PressableExternalLink";

import { Heading2, Paragraph } from "./DiscoveryTypography";

const SECTION_WIDTH = 1250;

export default function DiscoveryAbout() {
  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      padding="20px"
      borderRadius="10px"
      marginTop="40px"
      alignItems={"center"}
    >
      <Flex maxWidth={SECTION_WIDTH} width={"100%"} direction={"column"}>
        <Heading2>{t("Discovery.About.Header")}</Heading2>

        <Flex direction={isMatchingMobile ? "column-reverse" : "row"}>
          <Flex basis={"50%"}>
            <Paragraph>
              <p>{t("Discovery.About.Paragraph1")}</p>
              <p>{t("Discovery.About.Paragraph2")}</p>
              <p>{t("Discovery.About.Paragraph3")}</p>
            </Paragraph>
          </Flex>
          <Flex padding={32} alignItems={"baseline"}>
            <Image
              overflow={"hidden"}
              borderRadius={20}
              maxHeight={500}
              src={"/about-hero-img.png"}
              alt={"RENTUJEMY"}
              objectFit="fill"
            />
          </Flex>
        </Flex>
        <Flex direction="column" gap="20px">
          <Flex>
            {" "}
            <Image src={"/airbnb.svg"} alt="RENTUJEMY Airbnb"></Image>
            <PressableExternalLink
              url={"https://www.airbnb.com/users/show/486447775"}
            >
              <strong>{t("Discovery.Airbnb")}</strong>
            </PressableExternalLink>{" "}
          </Flex>
          <Flex>
            {" "}
            <Image src={"/google.svg"} alt="RENTUJEMY Google"></Image>
            <PressableExternalLink
              url={
                "https://www.google.com/maps/place/Rentujemy.pl/@52.1841676,21.0080958,17z/data=!3m1!4b1!4m6!3m5!1s0x471933fab8c1ab55:0x7b8bb52b8acf892b!8m2!3d52.1841676!4d21.0080958!16s%2Fg%2F11tmt8pgp2?entry=ttu"
              }
            >
              <strong>{t("Discovery.GoogleBusiness")}</strong>
            </PressableExternalLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
