import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import ConfirmTermsComponent from "./ConfirmTermsComponent";
import LoginContainer from "./LoginContainer";
import MobileNavTabs from "../Discovery/components/MobileNavTabs";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import useBreakpoints from "../hooks/useBreakpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCurrentUser } from "../hooks/useCurrentUser";

export default function LoginPage() {
  const { t } = useTranslation();
  const { isMatchingDesktop } = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  const authFormFields = {
    signIn: {
      username: {
        placeholder: t("Auth.EnterUsername"),
      },
      password: {
        placeholder: t("Auth.EnterPassword"),
      },
    },
    signUp: {
      email: {
        placeholder: t("Auth.EnterEmail"),
      },
      password: {
        placeholder: t("Auth.EnterPassword"),
      },
      confirm_password: {
        placeholder: t("Auth.EnterPassword"),
      },
    },
  };

  const authComponents = {
    SignUp: {
      Footer() {
        return (
          <Flex paddingLeft={32} paddingRight={32} paddingBottom={32}>
            <ConfirmTermsComponent />
          </Flex>
        );
      },
    },
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      if (user) {
        navigate("/trips");
      }
    }
  }, [location.pathname, user]);

  return (
    <>
      <DiscoveryHeader></DiscoveryHeader>

      <Flex
        height={`
        100svh
      `}
      >
        <LoginContainer>
          <Authenticator
            components={authComponents}
            formFields={authFormFields}
            variation="default"
          ></Authenticator>
        </LoginContainer>
      </Flex>
      {!isMatchingDesktop && <MobileNavTabs></MobileNavTabs>}
    </>
  );
};
