import { Alert, TextField } from "@aws-amplify/ui-react";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../../components/Box";
import LoaderBox from "../../components/LoaderBox";
import { logger } from "../../logger";
import OwnerPropertyCard from "./Card/OwnerPropertyCard";
import {
  useContracts,
  CustomerContractDetails,
} from "../../hooks/customer/useContracts";

interface OwnerListingsProps {
  listAll: boolean;
}

export default function OwnerListings({ listAll }: OwnerListingsProps) {
  const { data, error, isLoading } = useContracts(listAll);
  const [filteredContracts, setFilteredContracts] =
    useState<CustomerContractDetails[]>();
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredContracts(data);
  }, [data]);

  const handleSearchInput = useCallback(
    debounce((e) => {
      if (!data) {
        return;
      }

      const searchString = e.target.value;

      if (searchString.length > 2) {
        const searchResults = data.filter((contract) => {
          return `${contract.name} ${contract.hostaway_id}`
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });

        logger.debug(
          "Search results for searchString: " + searchResults.length
        );

        setFilteredContracts(searchResults);

        return;
      }

      setFilteredContracts(data);
    }, 1000),
    [data, setFilteredContracts]
  );

  return (
    <Box mt={20}>
      {!!data?.length && data?.length > 3 && (
        <Box mb={30}>
          <TextField
            autoComplete="off"
            label={""}
            onChange={handleSearchInput}
            placeholder={`${t("ChooseContract")} (${data.length})`}
          />
        </Box>
      )}

      {!isLoading && (data?.length === 0 || error) && (
        <Alert variation="info">
          {t("CustomerContracts.NoContractsAssigned")}
        </Alert>
      )}

      <Box mt={15}>
        {(filteredContracts || [])
          .filter((item) => item)
          .map((contract) => (
            <OwnerPropertyCard
              contract={contract}
              key={contract.contract_type + contract.contract_id}
            />
          ))}
      </Box>

      {isLoading && <LoaderBox />}
    </Box>
  );
};
