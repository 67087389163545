import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Cities } from "../model/Cities";

export default function City() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4}>
      <Heading level={4} marginBottom={10}>
        {t("shortTermCalculator.chooseCity")}
      </Heading>
      <Button
        onClick={() => {
          navigate(Cities.WARSAW);
        }}
      >
        {t("shortTermCalculator.Warsaw")}
      </Button>
      <Button
        onClick={() => {
          navigate(Cities.LODZ);
        }}
      >
        {t("shortTermCalculator.Lodz")}
      </Button>
      <Button
        onClick={() => {
          navigate(Cities.TRICITY);
        }}
      >
        {t("shortTermCalculator.Tricity")}
      </Button>
      <Button
        onClick={() => {
          navigate(Cities.CRAKOW);
        }}
      >
        {"Kraków"}
      </Button>
    </Flex>
  );
};
