import axios from "axios";
import { apiEndpoint } from "../config";
import { useCallback, useEffect, useState } from "react";
import { getAuthHeader } from "./common";
import { GuestReservation } from "./guest-reservation";

const getGuestReservations = async (): Promise<{
  reservations: GuestReservation[];
}> =>
  (
    await axios.get(
      `${apiEndpoint}/v1/guest/reservations/manager/reservations`,
      await getAuthHeader()
    )
  ).data;

export const useGuestReservations = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GuestReservation[]>();
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(() => {
    setLoading(true);

    (async () => {
      try {
        setData((await getGuestReservations()).reservations);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, setData, setError]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch]);

  return { data, loading, error, refetch };
};
