import { Badge, Flex, Table, TableCell, TableRow } from "@aws-amplify/ui-react";
import moment from "moment";
import { ChannelName, getChannel } from "../utils/channel";
import { MdAnalytics, MdInfoOutline } from "react-icons/md";
import { useMemo, useState } from "react";
import Modal from "../../components/Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moneyFormatter from "../../utils/moneyFormatter";
import { ReservationData } from "../../hooks/customer/useReservations";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import ExternalResourceLink from "../../components/ExternalResourceLink";

export default function ReservationRow({ reservation }: { reservation: ReservationData }) {
  const [details, setDetails] = useState<ReservationData | undefined>();
  const { t } = useTranslation();
  const { isCoordinator, isAdmin } = useCurrentUser();
  const navigate = useNavigate();

  const reservationTotalValue = useMemo(() => {
    let temp = Number(reservation.rental_income || 0);

    if (reservation.status !== "cancelled") {
      temp += Number(reservation.cleaning_fee || 0);
    }

    return temp;
  }, [reservation]);

  return (
    <>
      <TableRow key={reservation.hostaway_id}>
        <TableCell>
          {moment(reservation.arrival_date).format("DD.MM.YYYY")}
        </TableCell>
        <TableCell>
          {moment(reservation.departure_date).format("DD.MM.YYYY")}
        </TableCell>
        <TableCell>
          {reservation.status === "ownerStay"
            ? t("Reservation.OwnerStay")
            : `${getChannel(reservation.channel_name as ChannelName)}${reservation.status === "cancelled" ? " (odwołane)" : ""
            }`}
        </TableCell>
        <TableCell textAlign={"right"}>
          {Number(reservation.rental_income) || "-"}
        </TableCell>
        <TableCell textAlign={"right"}>
          {Number(reservation.total_tax) || "-"}
        </TableCell>
        <TableCell>
          <Flex
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-end"}
          >
            <Flex>{Number(reservation.pm_commission) || "-"}</Flex>
            {isAdmin && (
              <Flex>
                <Badge variation="info">
                  {(100 *
                    Math.round(
                      (100 * Number(reservation.pm_commission)) /
                      Number(reservation.rental_income)
                    )) /
                    100}
                  %
                </Badge>
              </Flex>
            )}
          </Flex>
        </TableCell>
        <TableCell textAlign={"right"}>
          {Number(reservation.reservation_portal_fee) || "-"}
        </TableCell>
        <TableCell textAlign={"right"}>
          {Number(reservation.owner_payout) || "-"}
        </TableCell>

        <TableCell fontWeight={800} textAlign={"right"}>
          <Flex direction={"row"} gap={4}>
            <Button
              variant="default"
              onClick={() => {
                setDetails(reservation);
              }}
            >
              <MdInfoOutline></MdInfoOutline>
            </Button>

            {(isCoordinator || isAdmin) && (
              <Button
                variant="default"
                onClick={() => {
                  navigate(
                    `https://admin.rentujemy.pl/?q=${reservation.hostaway_id}`
                  );
                }}
              >
                <MdAnalytics></MdAnalytics>
              </Button>
            )}
          </Flex>
        </TableCell>
      </TableRow>
      {details && (
        <Modal
          title="Details"
          onClose={() => {
            setDetails(undefined);
          }}
        >
          <>
            <Table>
              <TableRow>
                <TableCell>{t("Reservation.Id")}</TableCell>
                <TableCell>
                  {reservation.hostaway_id}
                  {(isCoordinator || isAdmin) && (
                    <ExternalResourceLink
                      href={`https://admin.rentujemy.pl?q=${reservation.hostaway_id}`}
                    ></ExternalResourceLink>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t("TotalPrice")}</TableCell>
                <TableCell>
                  {moneyFormatter(
                    Number(reservation.rental_income || 0),
                    "PLN"
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t("Reservation.CleaningFee")}</TableCell>
                <TableCell>
                  {reservation.status === "cancelled"
                    ? "-"
                    : moneyFormatter(reservation.cleaning_fee || 0, "PLN")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t("Reservation.ReservationTotal")}</TableCell>
                <TableCell>
                  {moneyFormatter(Number(reservationTotalValue || 0), "PLN")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t("Reservation.NumberOfGuests")}</TableCell>
                <TableCell>{reservation.no_of_guests || 1}</TableCell>
              </TableRow>
            </Table>
          </>
        </Modal>
      )}
    </>
  );
};
