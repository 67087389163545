import SEO from "@americanexpress/react-seo";
import { useBreakpointValue } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAnalytics } from "../hooks/useAnalytics";
import { CorePlace } from "../hooks/useGuestDiscoveryApi";
import DiscoveryDesktopPage from "./DiscoveryDesktopPage";
import DiscoveryMobilePage from "./DiscoveryMobilePage";
import { KEYWORDS } from "./KEYWORDS";
import LocationBottomsheet from "./bottomsheets/LocationBottomsheet";
import { DiscoveryContext } from "./components/DiscoveryContext";
import { DiscoveryListing } from "./components/DiscoveryListing";
import "./discovery-page.styles.css";
import isLatLngSearchString from "./isLatLngSearchString";

export default function DiscoveryPage({ title }: { title?: string }) {
  useAnalytics();

  const isMatchingDesktop = useBreakpointValue({ large: true, base: false });
  const { t } = useTranslation();
  const [focusedListing, setFocusedListing] = useState<DiscoveryListing>();
  const [selectedListing, setSelectedListing] = useState<DiscoveryListing>();
  const [selectedMapCenter, setSelectedMapCenter] = useState<
    { lat: number; lng: number } | undefined
  >();
  const [place, setPlace] = useState<Partial<CorePlace>>();
  const [locationBottomSheetOpen, setLocationBottomSheetOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    const latLng = isLatLngSearchString(params.searchString || "");

    if (latLng) {
      return setSelectedMapCenter(latLng);
    }
  }, [params]);

  return (
    <>
      <SEO
        title={
          place
            ? `RENTUJEMY | ${place?.formatted} - ${t("SEO.Discovery.Title")}`
            : title || t("SEO.Discovery.Title")
        }
        description={t("SEO.Discovery.Description")}
        keywords={KEYWORDS}
        siteUrl={
          place
            ? `https://rentujemy.com/s/${place.slug}`
            : "https://rentujemy.com"
        }
        image={{
          src: "about-hero-img.png",
        }}
      />

      <DiscoveryContext.Provider
        value={{
          place: place,
          setPlace: setPlace,
          locationBottomSheetOpen,
          setLocationBottomSheetOpen,
          selectedMapCenter,
          setSelectedMapCenter,
          focusedListing,
          setFocusedListing,
          selectedListing,
          setSelectedListing,
        }}
      >
        <LocationBottomsheet
          onDismiss={() => setLocationBottomSheetOpen(false)}
          open={locationBottomSheetOpen}
        ></LocationBottomsheet>

        {isMatchingDesktop ? <DiscoveryDesktopPage /> : <DiscoveryMobilePage />}
      </DiscoveryContext.Provider>
    </>
  );
};
