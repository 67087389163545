import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../components/Page";
import { TextField, Flex, Button, Alert, Heading } from "@aws-amplify/ui-react";
import moment from "moment";
import { useCustomerApi } from "../hooks/useCustomerApi";
import { isAxiosError } from "axios";

const dateToHostawayFormat = (value: string | Date) => {
  return moment(value).format("YYYY-MM-DD");
};

export default function OwnerStayPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { createOwnerReservation } = useCustomerApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [validationError, setValidationError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<{
    hostawayId: string;
    guestFirstName: string;
    guestLastName: string;
    checkinDate?: string;
    checkoutDate?: string;
  }>({
    hostawayId: params.id!,
    guestFirstName: "",
    guestLastName: "",
    checkinDate: undefined,
    checkoutDate: undefined,
  });

  return (
    <Page>
      {!success && (
        <Flex direction={"column"}>
          <Heading level={4} marginBottom={20}>
            {t("OwnerReservation.Title")}
          </Heading>

          <Alert isDismissible={false} hasIcon={true}>
            {t("OwnerReservation.Notes")}
          </Alert>

          <Flex direction={"column"}>
            <Flex direction="column">
              <TextField
                label={t("OwnerReservation.GuestFirstName")}
                id="firstName"
                type="text"
                required={true}
                onChange={(e) => {
                  let { value } = e.target;

                  setFormValues({
                    ...formValues,
                    guestFirstName: value,
                  });
                }}
              />
            </Flex>
            <Flex direction="column">
              <TextField
                label={t("OwnerReservation.GuestLastName")}
                id="lastName"
                type="text"
                required={true}
                onChange={(e) => {
                  let { value } = e.target;

                  setFormValues({
                    ...formValues,
                    guestLastName: value,
                  });
                }}
              />
            </Flex>
            <Flex direction="column">
              <TextField
                label={t("OwnerReservation.CheckinDate")}
                id="checkinDate"
                type="date"
                onChange={(e) => {
                  let { value } = e.target;

                  setFormValues({
                    ...formValues,
                    checkinDate: dateToHostawayFormat(value),
                  });
                }}
              />
            </Flex>
            <Flex direction="column">
              <TextField
                label={t("OwnerReservation.CheckoutDate")}
                id="checkoutDate"
                type="date"
                onChange={(e) => {
                  let { value } = e.target;

                  setFormValues({
                    ...formValues,
                    checkoutDate: dateToHostawayFormat(value),
                  });
                }}
              />
            </Flex>
          </Flex>
          <Flex direction={"column"}>
            <Button
              variation="primary"
              isLoading={isLoading}
              disabled={
                !formValues.guestFirstName ||
                !formValues.guestLastName ||
                !formValues.checkinDate ||
                !formValues.checkoutDate
              }
              onClick={async () => {
                setError(undefined);
                setValidationError(undefined);

                // frontend validation
                if (
                  !moment(formValues.checkinDate).isAfter(new Date(), "day") ||
                  !moment(formValues.checkoutDate).isAfter(new Date(), "day")
                ) {
                  return setValidationError(
                    t("OwnerReservation.PastDateError")
                  );
                }

                if (
                  moment(formValues.checkinDate).isAfter(
                    moment(formValues.checkoutDate),
                    "day"
                  )
                ) {
                  return setValidationError(
                    t("OwnerReservation.CheckoutBeforeCheckinError")
                  );
                }

                if (
                  moment(formValues.checkoutDate).diff(
                    moment(formValues.checkinDate),
                    "days"
                  ) > 14
                ) {
                  return setValidationError(
                    t("OwnerReservation.ReservationDurationError")
                  );
                }

                try {
                  setIsLoading(true);

                  await createOwnerReservation({
                    hostawayId: formValues.hostawayId,
                    guestFirstName: formValues.guestFirstName,
                    guestLastName: formValues.guestLastName,
                    checkinDate: formValues.checkinDate!,
                    checkoutDate: formValues.checkoutDate!,
                  });

                  setSuccess(true);
                } catch (error) {
                  setError(
                    isAxiosError(error)
                      ? error.response?.data.message
                      : "unknown error"
                  );
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              {t("OwnerReservation.SendRequest")}
            </Button>

            {validationError && (
              <Alert variation="error" isDismissible={false} hasIcon={true}>
                {validationError}
              </Alert>
            )}

            {error && (
              <Alert variation="error" isDismissible={false} hasIcon={true}>
                {t("OwnerReservation.FailedMessage")} - {error}
              </Alert>
            )}
          </Flex>
        </Flex>
      )}

      {success && (
        <Flex direction={"column"}>
          <Alert variation="success" isDismissible={false} hasIcon={true}>
            {t("OwnerReservation.SuccessMessage")}
          </Alert>

          <Button
            onClick={() => {
              navigate("/trips");
            }}
          >
            {t("Common.Continue")}
          </Button>
        </Flex>
      )}
    </Page>
  );
};
