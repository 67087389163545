import moment from "moment";
import { theme } from "../../../theme";
import { ChannelName, getChannel } from "../../utils/channel";
import { useTranslation } from "react-i18next";
import { ReservationData } from "../../../hooks/customer/useReservations";

export const useCalendarUtils = () => {
  const { t } = useTranslation();

  const mapReservationsToCalendarEvents = (
    reservationList: ReservationData[],
    isOnMobile: boolean
  ) => {
    return reservationList.map((item) => {
      let end = item.departure_date!;

      if (item.status === "cancelled") {
        end =
          item.cancellation_date &&
          moment(item.cancellation_date).isAfter(item.arrival_date)
            ? moment(item.cancellation_date).add(1, "day").format("YYYY-MM-DD")
            : item.departure_date!;
      }

      const color =
        item.status === "ownerStay"
          ? theme.secondaryColor
          : item.status === "cancelled"
          ? theme.secondaryColor
          : theme.primaryColor;

      return {
        title: isOnMobile
          ? ""
          : item.status === "ownerStay"
          ? `${t("Reservation.OwnerStay")}`
          : item.status === "cancelled"
          ? `${t("Reservation.Cancelled")}`
          : `${getChannel(item.channel_name as ChannelName)}`,
        start: item.arrival_date!,
        end,
        color,
        borderColor: color,
      };
    });
  };

  return { mapReservationsToCalendarEvents };
};
