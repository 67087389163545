import { TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";

export default function ReservationTableHead() {
  const { t } = useTranslation("translation");

  return (
    <TableHead>
      <TableRow>
        <TableCell as="th">{t("CheckIn")}</TableCell>
        <TableCell as="th">{t("CheckOut")}</TableCell>
        <TableCell maxWidth={100} as="th">
          {t("Channel")}
        </TableCell>
        <TableCell textAlign={"right"} as="th">
          {t("TotalPrice")}
        </TableCell>
        <TableCell textAlign={"right"} as="th">
          {t("TotalTax")}
        </TableCell>
        <TableCell maxWidth={150} textAlign={"right"} as="th">
          {t("ManagementCommission")}
        </TableCell>
        <TableCell maxWidth={150} textAlign={"right"} as="th">
          {t("PortalFees")}
        </TableCell>
        <TableCell textAlign={"right"} as="th">
          {t("OwnerPayout")}
        </TableCell>
        <TableCell as="th" />
      </TableRow>
    </TableHead>
  );
};
