import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { MdOutlineSearch } from "react-icons/md";
import { useDestinations } from "../../hooks/useGuestDiscoveryApi";
import { theme } from "../../theme";
import { borderRightStyle, inputStyles } from "../commonStyles";
import { useDiscoveryContext } from "./DiscoveryContext";
import InputDates from "./InputDates";
import "./react-datepicker.overrides.css";

interface SearchObj {
  arrival?: string;
  departure?: string;
  guests?: number;
}

export default function DiscoverySearchBar({
  defaultValues,
  onSubmit,
  placeLabel,
}: {
  placeLabel?: string;
  defaultValues: Partial<SearchObj>;
  onSubmit: (props: SearchObj) => void;
}) {
  const { t } = useTranslation();
  const { data } = useDestinations();
  const [searchObj, setSearchObj] = useState<Partial<SearchObj>>(defaultValues);
  const [canSubmit, setCanSubmit] = useState<boolean | null>(null);
  const { setLocationBottomSheetOpen } = useDiscoveryContext();

  const handleSubmit = useCallback(() => {
    if (searchObj.arrival) {
      onSubmit(searchObj);
      setCanSubmit(false);
    }
  }, [searchObj, onSubmit]);

  useEffect(() => {
    // first run
    if (canSubmit === null) {
      return setCanSubmit(false);
    }

    setCanSubmit(true);
  }, [searchObj, setCanSubmit]);

  return (
    <Flex
      className="r-search-bar"
      minWidth={900}
      boxShadow={"large"}
      direction={"row"}
      justifyContent="center"
      alignItems={"center"}
      padding="16px"
      paddingRight={50}
      paddingLeft={50}
      style={{ borderColor: theme.lines, borderStyle: "solid", borderWidth: 1 }}
      borderRadius="60px"
      marginBottom="40px"
    >
      <>
        <Flex
          style={borderRightStyle}
          grow={1}
          direction={"column"}
          width={"100%"}
          onClick={() => {
            setLocationBottomSheetOpen(true);
          }}
        >
          <TextField
            inputStyles={inputStyles}
            label={t("Discovery.Location.Header")}
            readOnly
            whiteSpace={"nowrap"}
            placeholder={
              data?.find((item) => item.id === placeLabel)?.label ||
              placeLabel ||
              t("Discovery.Search.LocationPlaceholder")
            }
            grow={1}
            autoComplete={"off"}
            autoCorrect={"off"}
            borderRadius="20px"
          />
        </Flex>

        <InputDates
          onChange={(prop) => {
            let newObj = { ...searchObj };
            if (prop.arrival) {
              newObj.arrival = moment(prop.arrival).format("YYYY-MM-DD");
            } else {
              delete newObj.arrival;
            }

            if (prop.departure) {
              newObj.departure = moment(prop.departure).format("YYYY-MM-DD");
            } else {
              delete newObj.departure;
            }

            setSearchObj(newObj);
          }}
        ></InputDates>

        <TextField
          inputStyles={inputStyles}
          label={t("Discovery.Search.Guests")}
          placeholder="2"
          type="number"
          min="1"
          max="10"
          minWidth={100}
          value={searchObj.guests}
          autoComplete={"off"}
          autoCorrect={"off"}
          borderRadius="20px"
          onChange={(event) => {
            setSearchObj({
              ...searchObj,
              guests: Number(event.target.value) || 2,
            });
          }}
        />
        <Flex justifyContent={"flex-end"} grow={1}>
          <Button
            disabled={!canSubmit}
            variation="primary"
            borderRadius="50%"
            width="60px"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={handleSubmit}
          >
            <MdOutlineSearch size={40}></MdOutlineSearch>
          </Button>
        </Flex>
      </>
    </Flex>
  );
};
