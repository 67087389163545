import { useBreakpointValue } from "@aws-amplify/ui-react";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { theme } from "../../../theme";
import { CalendarContext } from "../../context/CalendarContext";
import FullCalendarWrapper from "./FullCalendarWrapper";
import { useCalendarUtils } from "./useCalendarUtils";
import { ReservationData } from "../../../hooks/customer/useReservations";

interface OwnerCockpitReservationPageCalendarProps {
  reservationList: ReservationData[];
  onMonthChange: (year: string, month: string) => void;
}

export default function OwnerCockpitReservationPageCalendar({
  reservationList,
  onMonthChange,
}: OwnerCockpitReservationPageCalendarProps) {
  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });
  const calendarRef = useRef(null);
  const { t } = useTranslation();
  const { selectedMonth } = useContext(CalendarContext);
  const { mapReservationsToCalendarEvents } = useCalendarUtils();

  const events = useMemo(() => {
    return mapReservationsToCalendarEvents(reservationList, !!isMatchingMobile);
  }, [reservationList, isMatchingMobile]);

  useEffect(() => {
    if (selectedMonth) {
      const calendarApi = (calendarRef?.current as any).getApi();
      calendarApi.gotoDate(`${selectedMonth}-01`); // 'newDate' should be a string in 'YYYY-MM-DD' format
    }
  }, [selectedMonth]);

  return (
    <FullCalendarWrapper>
      <FullCalendar
        ref={calendarRef}
        datesSet={(arg) => {
          const midDate = new Date(
            (arg.start.getTime() + arg.end.getTime()) / 2
          );

          onMonthChange(
            moment(midDate).format("YYYY"),
            moment(midDate).format("MM")
          );
        }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale={i18n.language}
        firstDay={1}
        events={events}
        height={"80vh"}
        eventColor={theme.primaryColor}
        buttonText={{
          today: t("Calendar.Today"),
        }}
      />
    </FullCalendarWrapper>
  );
};
