import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { borderRightStyle } from "../commonStyles";
import moment from "moment";

export default function InputDates({
  onChange,
}: {
  onChange: (props: { arrival: Date; departure?: Date }) => void;
}) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date | undefined>(
    searchParams.get("arrival")!
      ? new Date(searchParams.get("arrival")!)
      : new Date()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    searchParams.get("departure")!
      ? new Date(searchParams.get("departure")!)
      : undefined
  );
  const isMatchingDesktop = useBreakpointValue({ base: false, large: true });

  useEffect(() => {
    if (
      startDate &&
      (searchParams.get("arrival")! !==
        moment(startDate).format("YYYY-MM-DD") ||
        searchParams.get("departure")! !== moment(endDate).format("YYYY-MM-DD"))
    ) {
      onChange({ arrival: startDate, departure: endDate });
    }
  }, [startDate, endDate]);

  return (
    <Flex direction={isMatchingDesktop ? "row" : "column"}>
      <Flex
        gap={10}
        direction={"column"}
        style={isMatchingDesktop ? borderRightStyle : undefined}
      >
        <Text color={"var(--amplify-components-field-label-color)"}>
          {t("Discovery.Search.Arrival")}
        </Text>
        <DatePicker
          includeDateIntervals={[
            {
              start: moment().toDate(),
              end: moment().add(12, "months").toDate(),
            },
          ]}
          placeholderText={t("Discovery.Search.AddDates")}
          selected={startDate}
          dateFormat={"d.MM.YYYY"}
          onChange={(date) => {
            if (endDate && moment(date).isSameOrAfter(endDate)) {
              setEndDate(undefined);
            }

            setStartDate(date || undefined);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsStart
        />
      </Flex>

      <Flex
        gap={10}
        direction={"column"}
        style={isMatchingDesktop ? borderRightStyle : undefined}
      >
        <Text color={"var(--amplify-components-field-label-color)"}>
          {t("Discovery.Search.Departure")}
        </Text>
        <DatePicker
          includeDateIntervals={[
            {
              start: moment(startDate).toDate(),
              end: moment().add(12, "months").toDate(),
            },
          ]}
          placeholderText={t("Discovery.Search.AddDates")}
          selected={endDate}
          dateFormat={"d.MM.YYYY"}
          onChange={(date) => {
            if (startDate && moment(date).isSameOrBefore(startDate)) {
              setStartDate(undefined);
            }

            setEndDate(date || undefined);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsEnd
        />
      </Flex>
    </Flex>
  );
};
