import { Flex } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdPerson, MdSearch } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { theme } from "../../theme";
import { borderTopStyle } from "../commonStyles";
import MobileNavTab from "./MobileNavTab";

const MAP_CONTROL_ZINDEX = 1;

const NAV_TABS_ZINDEX = MAP_CONTROL_ZINDEX + 1;

export const NAV_TABS_HEIGHT = 65;

export default function MobileNavTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return (
    <Flex
      style={{ ...borderTopStyle, zIndex: NAV_TABS_ZINDEX }}
      height={65}
      overflow={"auto"}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={theme.background}
      position={"fixed"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
    >
      <MobileNavTab
        isActive={currentLocation === "/" || currentLocation.startsWith("/s/")}
        label={t("Tabs.Explore")}
        onPress={() => navigate("/")}
        icon={MdSearch}
      />

      <MobileNavTab
        isActive={currentLocation.startsWith("/trips")}
        label={t("Tabs.Trips")}
        onPress={() => navigate("/trips")}
        icon={MdPerson}
      />
    </Flex>
  );
};
