import { Flex, Text } from "@aws-amplify/ui-react";
import moneyFormatter from "../../utils/moneyFormatter";
import { theme } from "../../theme";

interface StatLabelProps {
  label: string;
  value: number;
  unit: "zł" | "%";
}

export default function StatLabel({ label, value, unit }: StatLabelProps) {
  return (
    <Flex
      direction="column"
      gap={5}
      justifyContent={"space-between"}
      maxWidth={150}
    >
      <Text fontWeight={700}>{label}</Text>
      <Text fontSize={24} fontWeight={700} color={theme.secondaryColor}>
        {value
          ? `${unit === "zł" ? moneyFormatter(value) : value}${unit}`
          : "-"}
      </Text>
    </Flex>
  );
};
