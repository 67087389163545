import { Table, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { ReservationData } from "../../hooks/customer/useReservations";
import ReservationRow from "./ReservationRow";
import ReservationTableHead from "./ReservationTableHead";

interface OwnerPropertyReservationPageTableProps {
  reservations: ReservationData[];
}

export const totalAmount = (
  reservation: ReservationData[],
  propertyName:
    | "rental_income"
    | "pm_commission"
    | "reservation_portal_fee"
    | "total_tax"
    | "owner_payout"
) => {
  const sum = reservation.reduce(function (acc, obj) {
    return acc + Number(obj[propertyName]);
  }, 0);

  return `${sum.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}zł`;
};

export default function OwnerPropertyReservationPageTable({ reservations }: OwnerPropertyReservationPageTableProps) {
  const totals = useMemo(() => {
    return {
      rentalIncome: totalAmount(reservations, "rental_income"),
      totalTax: totalAmount(reservations, "total_tax"),
      pmCommission: totalAmount(reservations, "pm_commission"),
      reservationPortalFee: totalAmount(reservations, "reservation_portal_fee"),
      ownerPayout: totalAmount(reservations, "owner_payout"),
    };
  }, [reservations]);

  return (
    <Table size="small" variation="striped" highlightOnHover={false}>
      <ReservationTableHead></ReservationTableHead>
      <TableBody>
        {reservations.map((reservation, index) => (
          <ReservationRow key={index} reservation={reservation} />
        ))}
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell />

          <TableCell textAlign={"right"} fontWeight={700}>
            {totals.rentalIncome}
          </TableCell>
          <TableCell textAlign={"right"} fontWeight={700}>
            {totals.totalTax}
          </TableCell>
          <TableCell textAlign={"right"} fontWeight={700}>
            {totals.pmCommission}
          </TableCell>
          <TableCell textAlign={"right"} fontWeight={700}>
            {totals.reservationPortalFee}
          </TableCell>
          <TableCell fontWeight={800} textAlign={"right"}>
            {totals.ownerPayout}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableBody>
    </Table>
  );
};
