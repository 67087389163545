import { Button } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function FinalizePaymentButton({ haReservationId }: { haReservationId: number }) {
  const { t } = useTranslation();

  return (
    <Link to={`/gp/${haReservationId}/finalize`}>
      <Button
        isFullWidth={true}
        variation="primary"
        size="large"
        onClick={async () => { }}
      >
        {t("GuestReservation.FinalizePayment")}
      </Button>
    </Link>
  );
};
