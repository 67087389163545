import {
  Alert,
  Button,
  Flex,
  Placeholder,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCustomerApi } from "../../hooks/useCustomerApi";
import { getNames, registerLocale } from "i18n-iso-countries";
import HeadingSection from "../components/HeadingSection";
import i18n from "../../i18n/i18n";
import { InvoiceForm } from "../types/InvoiceForm";
import { useReservationContext } from "../providers/ReservationProvider";
import { OptionalErrors, validateInvoiceForm } from "./validateInvoice";
import { Logger } from "aws-amplify";
import FinalizePaymentButton from "../components/FinalizePaymentButton";
import { makeAmountNice } from "../../utils/makeAmountNice";

// Support french & english languages.
registerLocale(require("i18n-iso-countries/langs/en.json"));
registerLocale(require("i18n-iso-countries/langs/pl.json"));

const logger = new Logger("InvoicePage");

export default function InvoicePage() {
  const { t } = useTranslation();
  const params = useParams();
  const { reservation } = useReservationContext();
  const {
    downloadReservationInvoice,
    createReservationInvoice,
    getReservationInvoice,
  } = useCustomerApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<boolean>();

  const canRequestInvoice = useMemo(() => {
    return reservation?.canRequestInvoice;
  }, [reservation]);

  useEffect(() => {
    (async () => {
      const res = await getReservationInvoice(params.reservationId!);

      logger.debug(res);

      setInvoice(!!res);
    })();
  }, [getReservationInvoice, setInvoice, params]);

  const [formData, setFormData] = useState<InvoiceForm>({
    vatId: "",
    companyName: "",
    firstName: "",
    lastName: "",
    street: "",
    city: "",
    postalCode: "",
    country: "PL",
    email: "",
  });

  const [formErrors, setFormErrors] = useState<OptionalErrors<InvoiceForm>>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const invoice = await createReservationInvoice(
        params.reservationId!,
        formData
      );

      setInvoice(!!invoice);
    } catch (err) {
      logger.error(err);

      setError("Could not generate invoice.");
    } finally {
      setLoading(false);
    }
  };

  const countries = useMemo(() => {
    return getNames(i18n.language);
  }, []);

  const canSubmit = useMemo(() => {
    logger.debug("Validation", formData);

    const validationResult = validateInvoiceForm(formData);

    setFormErrors(validationResult.errors);

    return validationResult.canSubmit;
  }, [formData]);

  return (
    <Flex gap={16} direction={"column"}>
      <HeadingSection
        title={t("Invoice.Title")}
        subtitle={t("Invoice.Subtitle")}
      ></HeadingSection>

      {!reservation && <Placeholder height={80}></Placeholder>}

      {reservation && invoice === true && (
        <Flex>
          <Button
            onClick={() => {
              (async () => {
                const data = await downloadReservationInvoice(
                  params.reservationId!
                );

                // create file link in browser's memory
                const href = URL.createObjectURL(data);

                // create "a" HTML element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute(
                  "download",
                  `RENTUJEMY_INV-${params.reservationId!}.pdf`
                ); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })();
            }}
          >
            Pobierz
          </Button>
        </Flex>
      )}

      {reservation && invoice === false && (
        <Flex direction={"column"} gap={40}>
          {!reservation.paid && (
            <Flex direction={"column"}>
              <Alert variation="warning">
                <Flex>{t("Invoice.NoPaymentAlert")}</Flex>
                <Flex>
                  <Flex>
                    {t("Invoice.BalanceDue")}:{" "}
                    {makeAmountNice(
                      reservation.totalPrice - reservation.totalPaid
                    ).toFixed(2)}{" "}
                    PLN
                  </Flex>
                </Flex>
              </Alert>

              <FinalizePaymentButton
                haReservationId={Number(params!.reservationId)}
              ></FinalizePaymentButton>
            </Flex>
          )}

          {reservation.paid && (
            <>
              {!canRequestInvoice && (
                <Flex>
                  <Alert>{t("Invoice.BeforeCheckoutAlert")}</Alert>
                </Flex>
              )}

              {canRequestInvoice && (
                <form onSubmit={handleSubmit}>
                  <Flex gap={16} direction={"column"}>
                    <TextField
                      label={t("Account.FirstName")}
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder={t("Account.FirstName")}
                      required
                    />

                    <TextField
                      label={t("Account.LastName")}
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder={t("Account.LastName")}
                      required
                    />

                    <TextField
                      label={t("Invoice.InputCompanyName")}
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder={t("Invoice.InputCompanyName")}
                    />

                    <SelectField
                      label={t("Account.Country")}
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      placeholder={t("Account.Country")}
                      required
                    >
                      {Object.keys(countries).map((countryKey) => (
                        <option value={countryKey} key={countryKey}>
                          {countries[countryKey]}
                        </option>
                      ))}
                    </SelectField>

                    <TextField
                      label={t("Account.Street")}
                      name="street"
                      value={formData.street}
                      onChange={handleChange}
                      placeholder={t("Account.Street")}
                      required
                    />

                    <Flex direction={"row"}>
                      <TextField
                        label={t("Account.ZipCode")}
                        hasError={!!formErrors.postalCode}
                        errorMessage={
                          formErrors.postalCode
                            ? t(formErrors.postalCode as any)
                            : undefined
                        }
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        placeholder={t("Account.ZipCode")}
                        required
                      />

                      <TextField
                        grow={1}
                        label={t("Account.City")}
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder={t("Account.City")}
                        required
                      />
                    </Flex>

                    <TextField
                      label={t("Account.TaxId")}
                      descriptiveText={t("Invoice.InputVatLabel")}
                      name="vatId"
                      hasError={!!formErrors.vatId}
                      errorMessage={t("Invoice.InvalidInput")}
                      value={formData.vatId}
                      onChange={handleChange}
                      placeholder={t("Account.TaxId")}
                    />

                    <TextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                    />

                    <Flex paddingBottom={100}>
                      <Button
                        type="submit"
                        isLoading={loading}
                        disabled={!canSubmit}
                        variation="primary"
                        isFullWidth={true}
                        size="large"
                      >
                        {t("Invoice.Submit")}
                      </Button>
                    </Flex>
                    {error && (
                      <Alert
                        variation="error"
                        isDismissible={true}
                        onDismiss={() => {
                          setError(null);
                        }}
                      >
                        Wystąpił błąd podczas generowania faktury VAT. Popraw
                        dane i spróbuj ponownie.
                      </Alert>
                    )}
                  </Flex>
                </form>
              )}
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};
