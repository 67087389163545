import { Autocomplete, Flex, SelectField, Text } from "@aws-amplify/ui-react";
import moment from "moment";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Box from "../components/Box";
import { useTranslation } from "react-i18next";
import "moment/locale/pl";
import { PropertyDetails } from "../hooks/useProperties";

interface PayoutFilterTabProps {
  hasInvestObjectFilter: boolean;
  startMonth: number;
  setStartMonth: Dispatch<SetStateAction<number>>;
  startYear: number;
  setStartYear: Dispatch<SetStateAction<number>>;
  endMonth: number;
  setEndMonth: Dispatch<SetStateAction<number>>;
  endYear: number;
  setEndYear: Dispatch<SetStateAction<number>>;
  activeYears: number[];
  investObjectId?: number;
  setInvestObjectId?: Dispatch<SetStateAction<number | undefined>>;
  investObjects?: PropertyDetails<boolean>[];
  handleInvestObjectsAutocompleteClick?: () => void;
}

export default function PayoutFilterTab({
  hasInvestObjectFilter,
  startMonth,
  setStartMonth,
  endMonth,
  setEndMonth,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  activeYears,
  setInvestObjectId,
  investObjects,
  handleInvestObjectsAutocompleteClick,
}: PayoutFilterTabProps) {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          {hasInvestObjectFilter && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                gap: 5,
              }}
            >
              <Text>{t("InvestObject")}</Text>
              <Autocomplete
                label={""}
                onClick={handleInvestObjectsAutocompleteClick}
                isLoading={!investObjects}
                options={(investObjects || []).map((invest) => ({
                  id: String(invest.internal_id),
                  label: invest.name,
                }))}
                onSelect={(option) => {
                  const invest = (investObjects || []).find(
                    (item) => item.internal_id === Number(option.id)
                  );
                  setInvestObjectId!(invest!.internal_id);
                }}
                onClear={() => {
                  setInvestObjectId!(undefined);
                }}
              />
            </Box>
          )}
        </Box>
        <Flex direction={{ base: "column", medium: "row" }} gap={10}>
          <Flex
            direction="column"
            width={{ base: "100%", medium: "50%" }}
            gap={5}
          >
            <Flex alignItems="center" direction="row" gap={5}>
              <Text>{t("Od")}</Text>
              <Box style={{ flexGrow: 1, minWidth: 120 }}>
                <SelectField
                  label={""}
                  labelHidden
                  size="small"
                  defaultValue={String(startMonth)}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setStartMonth(parseInt(e.target.value));
                  }}
                >
                  {moment.months().map((month, index) => (
                    <option key={month} value={index}>
                      {month}
                    </option>
                  ))}
                </SelectField>
              </Box>

              <SelectField
                label=""
                labelHidden
                size="small"
                defaultValue={String(startYear)}
                onChange={(e) => {
                  setStartYear(parseInt(e.target.value));
                }}
              >
                {activeYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectField>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            width={{ base: "100%", medium: "50%" }}
            gap={5}
          >
            <Flex alignItems="center" direction="row" gap={5}>
              <Text>{t("Do")}</Text>
              <Box style={{ flexGrow: 1, minWidth: 120 }}>
                <SelectField
                  label=""
                  labelHidden
                  size="small"
                  defaultValue={String(endMonth)}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setEndMonth(parseInt(e.target.value));
                  }}
                >
                  {moment.months().map((month, index) => (
                    <option key={month} value={index}>
                      {month}
                    </option>
                  ))}
                </SelectField>
              </Box>
              <SelectField
                label=""
                labelHidden
                size="small"
                defaultValue={String(endYear)}
                onChange={(e) => {
                  setEndYear(parseInt(e.target.value));
                }}
              >
                {activeYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectField>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
