import { Text, Card, Flex, Image, Button } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";

import OwnerCockpitListingLinks from "./OwnerCockpitListingLinks";
import OwnerCockpitTags from "./OwnerCockpitTags";
import { useNavigate } from "react-router-dom";
import { MdCameraAlt, MdDomainDisabled } from "react-icons/md";
import { CustomerContractDetails } from "../../hooks/customer/useContracts";

const STAY_RENTUJEMY_LINK_PREFIX = "https://stay.rentujemy.pl/listings/";

export default function OwnerPropertyDetailsCard({ contract }: { contract: CustomerContractDetails }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listing =
    contract.listing_provider === "hostaway" ? contract.listing_data : null;

  const isFixedPayout =
    contract.contract_type === "mgmt" && contract.payout_type === "fixedPayout";

  return (
    <Card variation="elevated" marginBottom={20} padding={0}>
      <Flex
        direction={{ base: "column", medium: "row" }}
        gap={0}
        maxHeight={{ base: 400, medium: 360 }}
        overflow="hidden"
      >
        <Flex width="100%" justifyContent="center" overflow="hidden">
          {contract.property_disabled ? (
            <MdDomainDisabled style={{ width: "100%", height: "auto" }} />
          ) : !listing?.images[0] ? (
            <MdCameraAlt style={{ width: "100%", height: "auto" }} />
          ) : (
            <Image
              alt={t("PropertyImage")}
              src={listing.images[0]}
              objectFit="cover"
              objectPosition="50% 50%"
              width={"100%"}
            />
          )}
        </Flex>
        <Flex
          direction="column"
          alignItems="flex-start"
          gap={10}
          padding={20}
          width="100%"
          height="100%"
        >
          {listing && (
            <Flex alignItems="center">
              <Text>
                {`${listing.street}, ${listing.zip_code} ${listing.city}, ${listing.country}`}
              </Text>
            </Flex>
          )}

          {listing && <OwnerCockpitTags tags={listing.tags} />}
          {contract && (
            <OwnerCockpitListingLinks
              links={[
                { name: "airbnb", url: contract.airbnb_url },
                { name: "booking.com", url: contract.booking_url },
                { name: "google travel", url: contract.google_url },
                {
                  name: "stay.rentujemy.pl",
                  url: `${STAY_RENTUJEMY_LINK_PREFIX}${contract.hostaway_id}`,
                },
                { name: "vrbo", url: contract.vrbo_url },
              ]}
            />
          )}

          {contract.property_disabled && (
            <Flex>
              <Text>This listing has been disabled</Text>
            </Flex>
          )}

          {isFixedPayout && (
            <Flex>
              <Text>Fixed Payout Contract</Text>
            </Flex>
          )}

          <Button
            disabled={contract.property_disabled || isFixedPayout}
            onClick={() => {
              navigate(`/owner-cockpit/${contract.hostaway_id}/owner-stay`);
            }}
          >
            {t("Reservation.OwnerReservation")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
