const pricesKrakow = [
  {
    region: "Stare Miasto",
    slug: "stare-miasto",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 340 },
      { type: "studio", standard: "premium", avgPricePerNight: 430 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 520 },
      {
        type: "twoRooms",
        standard: "premium",
        avgPricePerNight: 555,
      },
      {
        type: "threeRooms",
        standard: "comfort",
        avgPricePerNight: 645,
      },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 780.15 },
    ],
  },
  {
    region: "Kazimierz",
    slug: "kazimierz",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 300,
      },
      { type: "studio", standard: "premium", avgPricePerNight: 360 },
      {
        type: "twoRooms",
        standard: "comfort",
        avgPricePerNight: 421,
      },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 495.75 },
      {
        type: "threeRooms",
        standard: "comfort",
        avgPricePerNight: 585,
      },
      {
        type: "threeRooms",
        standard: "premium",
        avgPricePerNight: 705,
      },
    ],
  },
  {
    region: "Podgórze",
    slug: "podgorze",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 270,
      },
      { type: "studio", standard: "premium", avgPricePerNight: 331.2 },
      {
        type: "twoRooms",
        standard: "comfort",
        avgPricePerNight: 390,
      },
      {
        type: "twoRooms",
        standard: "premium",
        avgPricePerNight: 465,
      },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 540.75 },
      {
        type: "threeRooms",
        standard: "premium",
        avgPricePerNight: 660,
      },
    ],
  },
  {
    region: "Grzegórzki",
    slug: "grzegorzki",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 293,
      },
      {
        type: "studio",
        standard: "premium",
        avgPricePerNight: 353,
      },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 420.0 },
      {
        type: "twoRooms",
        standard: "premium",
        avgPricePerNight: 503,
      },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 578.25 },
      {
        type: "threeRooms",
        standard: "premium",
        avgPricePerNight: 698,
      },
    ],
  },
  {
    region: "Nowa Huta",
    slug: "nowa-huta",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 245,
      },
      {
        type: "studio",
        standard: "premium",
        avgPricePerNight: 295,
      },
      {
        type: "twoRooms",
        standard: "comfort",
        avgPricePerNight: 346,
      },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 420 },
      {
        type: "threeRooms",
        standard: "comfort",
        avgPricePerNight: 495,
      },
      {
        type: "threeRooms",
        standard: "premium",
        avgPricePerNight: 615,
      },
    ],
  },
  {
    region: "Bieżanów-Prokocim",
    slug: "biezanow-prokocim",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 263,
      },
      { type: "studio", standard: "premium", avgPricePerNight: 322.65 },
      {
        type: "twoRooms",
        standard: "comfort",
        avgPricePerNight: 383,
      },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 458.25 },
      {
        type: "threeRooms",
        standard: "comfort",
        avgPricePerNight: 533,
      },
      {
        type: "threeRooms",
        standard: "premium",
        avgPricePerNight: 653,
      },
    ],
  },
  {
    region: "Krowodrza",
    slug: "krowodrza",
    apartments: [
      {
        type: "studio",
        standard: "comfort",
        avgPricePerNight: 330,
      },
      { type: "studio", standard: "premium", avgPricePerNight: 391.35 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 450.75 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 540.15 },
      {
        type: "threeRooms",
        standard: "comfort",
        avgPricePerNight: 616,
      },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 735.3 },
    ],
  },
];

export default pricesKrakow;
