import { Flex, Heading } from "@aws-amplify/ui-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline } from "react-icons/md";
import { theme } from "../../theme";
import { Paragraph } from "./DiscoveryTypography";

const SECTION_WIDTH = 1250;

export default function DiscoveryUSPs() {
  const { t } = useTranslation();

  return (
    <Flex
      id="about"
      direction="column"
      padding="20px"
      borderRadius="10px"
      marginTop="40px"
      alignItems={"center"}
    >
      <Flex maxWidth={SECTION_WIDTH} width={"100%"} direction={"column"}>
        <Heading level={2} marginBottom="20px" fontWeight={600}>
          {t("Discovery.USP.Header")}
        </Heading>
        <Flex direction="column" gap="10px" fontSize={20}>
          <Paragraph>
            <MdCheckCircleOutline
              size={20}
              color={theme.success}
            ></MdCheckCircleOutline>{" "}
            {t("Discovery.USP.USP1")}
          </Paragraph>
          <Paragraph>
            <MdCheckCircleOutline
              size={20}
              color={theme.success}
            ></MdCheckCircleOutline>{" "}
            {t("Discovery.USP.USP2")}
          </Paragraph>
          <Paragraph>
            <MdCheckCircleOutline
              size={20}
              color={theme.success}
            ></MdCheckCircleOutline>{" "}
            {t("Discovery.USP.USP3")}
          </Paragraph>
        </Flex>
      </Flex>
    </Flex>
  );
};
