import { Flex, Text } from "@aws-amplify/ui-react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useMemo, useState } from "react";
import { theme } from "../../theme";
import { useDiscoveryContext } from "./DiscoveryContext";
import DiscoveryListingComponent from "./DiscoveryListingComponent";

export default function CustomAdvancedMarker({
  listing,
  handleMarkerClick,
  doNotShowSelected,
}: {
  doNotShowSelected?: boolean;
  listing: any;
  handleMarkerClick?: (lat: number, lng: number) => void;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { selectedListing, setSelectedListing, focusedListing } =
    useDiscoveryContext();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isHighlighted = useMemo(() => {
    return (
      isHovered ||
      focusedListing?.url === listing.url ||
      selectedListing?.url === listing.url
    );
  }, [listing, focusedListing, selectedListing, isHovered]);

  return (
    <AdvancedMarker
      key={listing.url}
      zIndex={isHighlighted ? 99999 : undefined}
      position={{ lat: listing.lat, lng: listing.lng }}
      onClick={() => {
        setSelectedListing(listing);

        if (handleMarkerClick) {
          handleMarkerClick(listing.lat, listing.lng);
        }
      }}
      title={"AdvancedMarker with custom html content."}
    >
      <Flex
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        fontWeight={600}
        justifyContent="center"
        alignItems={"center"}
        boxShadow={"large"}
        minWidth={70}
        backgroundColor={isHighlighted ? theme.primaryColor : "white"}
        paddingRight={12}
        paddingLeft={12}
        padding={"16"}
        position="absolute"
        height={30}
        borderRadius={16}
      >
        <Text
          color={isHighlighted ? "white" : undefined}
          fontSize={16}
          fontWeight={600}
        >
          {`${Number(listing.price) || "-"}zł`}
        </Text>
      </Flex>

      {!doNotShowSelected && selectedListing?.url === listing.url && (
        <Flex position="absolute" top={30} left={-90}>
          <DiscoveryListingComponent
            standalone={true}
            listing={listing}
          ></DiscoveryListingComponent>
        </Flex>
      )}
    </AdvancedMarker>
  );
};
