import moment, { Moment } from "moment";
import { useState } from "react";

const getAccountActiveYears = (creationDate: Moment): Array<number> => {
  const firstActiveYear = creationDate.year();
  const currentYear = moment().year();
  const activeYears: Array<number> = [];

  for (let i = currentYear; i >= firstActiveYear - 1; i--) {
    activeYears.push(i);
  }

  return activeYears;
};

export default function useAccountActiveYears() {
  const [creationDate] = useState(moment());

  const activeYears = getAccountActiveYears(creationDate);

  // useEffect(() => {
  //   getUserData(user.userId).then((res) => setCreationDate(moment(res!.createdAt)));
  // }, [user]);

  return { activeYears };
};
