import {
  Alert,
  Button,
  Flex,
  Heading,
  Placeholder,
  TextField,
} from "@aws-amplify/ui-react";
import { isAxiosError } from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Text from "../components/Text";
import { useCustomerApi } from "../hooks/useCustomerApi";
import { useReservationContext } from "./providers/ReservationProvider";

const dateToHostawayFormat = (value: string | Date) => {
  return moment(value).format("YYYY-MM-DD");
};

enum ProlongErrors {
  NotAvailable = "NOT_AVAILABLE",
  Unknown = "UNKNOWN",
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function ProlongPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const { createProlongation } = useCustomerApi();

  useEffect(() => {
    (async () => {
      const dayAfterCheckout = moment(reservation?.checkoutDate).add(1, "days");

      setFormValues({
        ...formValues,
        checkoutDate: dateToHostawayFormat(dayAfterCheckout.toDate()),
      });
    })();
  }, [reservation]);

  const [error, setError] = useState<ProlongErrors | undefined>();
  const [validationError, setValidationError] = useState<string | undefined>();
  const [success, setSuccess] = useState<
    { guestPortalUrl: string; totalPrice: number; id: string } | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<{
    reservationId: string;
    guestEmail?: string;
    guestFirstName: string;
    guestLastName: string;
    checkinDate?: string;
    checkoutDate?: string;
  }>({
    reservationId: params.reservationId!,
    guestFirstName: "",
    guestLastName: "",
    checkinDate: "",
    checkoutDate: "",
  });

  const canSubmit = useMemo(() => {
    if (!reservation) {
      return false;
    }

    if (!formValues.checkoutDate) {
      return false;
    }

    if (reservation?.obfuscatedEmail) {
      return true;
    }

    if (!formValues.guestEmail) {
      return false;
    }

    return true;
  }, [formValues, reservation]);

  return (
    <>
      {!success && (
        <Flex direction={"column"}>
          <Flex justifyContent={"flex-start"} direction="column">
            <Heading level={3}>{t("GuestReservation.Title")}</Heading>
          </Flex>
          <Flex direction={"column"}>
            {!reservation && (
              <Flex direction={"column"} gap={45}>
                <Placeholder height={50}></Placeholder>
                <Placeholder height={50}></Placeholder>
                <Placeholder height={50}></Placeholder>
                <Placeholder height={50}></Placeholder>
              </Flex>
            )}

            {!success && reservation && (
              <Flex direction={"column"}>
                <Flex direction="column">
                  <TextField
                    disabled
                    defaultValue={formValues.reservationId}
                    label={t("ReservationId")}
                    id="reservationId"
                    type="text"
                    required={true}
                    onChange={(e) => {
                      let { value } = e.target;

                      setFormValues({
                        ...formValues,
                        guestFirstName: value,
                      });
                    }}
                  />
                </Flex>

                <Flex direction="column">
                  <TextField
                    disabled={!!reservation?.obfuscatedEmail}
                    defaultValue={reservation?.obfuscatedEmail || ""}
                    label={"Email*"}
                    id="email"
                    type="email"
                    required={true}
                    onChange={(e) => {
                      let { value } = e.target;

                      if (validateEmail(value)) {
                        setFormValues({
                          ...formValues,
                          guestEmail: value,
                        });
                      }
                    }}
                  />
                </Flex>

                <Flex direction="column" gap={0}>
                  <Text>{t("GuestReservation.NewCheckoutDate")}</Text>
                  <DatePicker
                    inline
                    excludeDateIntervals={[
                      {
                        start: moment(reservation.checkoutDate)
                          .subtract(99, "year")
                          .toDate(),
                        end: moment(reservation.checkoutDate).toDate(),
                      },
                    ]}
                    id="prolongation-checkout"
                    placeholderText={t("GuestReservation.NewCheckoutDate")}
                    selected={moment(
                      formValues.checkoutDate || reservation.checkoutDate
                    ).toDate()}
                    dateFormat={"d.MM.YYYY"}
                    onChange={(value) => {
                      if (value) {
                        setFormValues({
                          ...formValues,
                          checkoutDate: dateToHostawayFormat(value),
                        });
                      }
                    }}
                  />
                </Flex>
                <Flex direction={"column"} marginTop={16}>
                  <Button
                    isLoading={loading}
                    variation="primary"
                    size="large"
                    disabled={!canSubmit}
                    onClick={async () => {
                      setError(undefined);
                      setValidationError(undefined);
                      setLoading(true);

                      try {
                        const success = await createProlongation({
                          guestEmail: formValues.guestEmail,
                          reservationId: formValues.reservationId,
                          checkoutDate: formValues.checkoutDate!,
                        });

                        setSuccess(success);

                        navigate(`/finalize/${success.id}`);
                      } catch (error) {
                        setLoading(false);

                        if (isAxiosError(error)) {
                          if (
                            error.response?.data.message ===
                            ProlongErrors.NotAvailable
                          ) {
                            return setError(ProlongErrors.NotAvailable);
                          }
                        }

                        return setError(ProlongErrors.Unknown);
                      }
                    }}
                  >
                    {loading && <Loader></Loader>}
                    {!loading && t("GuestReservation.SendRequest")}
                  </Button>

                  {validationError && (
                    <Alert
                      variation="error"
                      isDismissible={false}
                      hasIcon={true}
                    >
                      {validationError}
                    </Alert>
                  )}

                  {error === ProlongErrors.NotAvailable && (
                    <Alert
                      variation="error"
                      isDismissible={false}
                      hasIcon={true}
                    >
                      {t("GuestReservation.NotAvailable")}
                    </Alert>
                  )}

                  {error === ProlongErrors.Unknown && (
                    <Alert
                      variation="error"
                      isDismissible={false}
                      hasIcon={true}
                    >
                      {t("GuestReservation.FailedMessage")}
                    </Alert>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}

      {success && (
        <Flex direction={"column"} gap={42}>
          <Heading level={2}>{t("GuestReservation.Title")}</Heading>

          <Alert variation="info" isDismissible={false} hasIcon={true}>
            {t("GuestReservation.SuccessMessage")}
          </Alert>

          <Button
            size={"large"}
            variation="primary"
            onClick={async () => {
              navigate(`/finalize/${success.id}`);
            }}
          >
            {t("GuestReservation.FinalizeBooking")}
          </Button>
        </Flex>
      )}
    </>
  );
};
