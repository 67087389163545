import React from "react";
import { Loader as AmplifyLoader } from "@aws-amplify/ui-react";
import { theme } from "../theme";

export default function Loader() {
  return (
    <AmplifyLoader
      style={{ marginRight: 5, marginLeft: 5, top: 10 }}
      size="large"
      emptyColor="#DDDDDD"
      filledColor={theme.primaryColor}
    />
  )
};
