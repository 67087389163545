import Box from "../components/Box";
import Footer from "../molecules/Footer";
import RentalLeadCaptureForm from "../Typeform/RentalLeadCaptureForm";

export default function ReantalOnboardingPage() {
  return (
    <Box className="container" style={{ maxWidth: 615 }}>
      <Box pl={15} pr={15}>
        <RentalLeadCaptureForm></RentalLeadCaptureForm>
      </Box>

      <Footer />
    </Box>
  );
};
