/**
 * High ROI Test:
 * http://localhost:3000/real-estate-roi-calculator?rentalIncome=8500&purchasePrice=535000&costs=710
 *
 * Medium ROI Test:
 * http://localhost:3000/real-estate-roi-calculator?rentalIncome=5000&purchasePrice=535000&costs=710
 *
 * Low ROI Test:
 * http://localhost:3000/real-estate-roi-calculator?rentalIncome=3500&purchasePrice=535000&costs=710
 */
import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Heading,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import Box from "../components/Box";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../molecules/Footer";
import PublicHeader from "../molecules/PublicHeader";
import { Helmet } from "react-helmet";
import ExternalLink from "../components/ExternalLink";
import { makeAmountNice } from "../utils/makeAmountNice";
import { theme } from "../theme";

const CtaComponent = () => {
  const { t } = useTranslation();

  return (
    <Box mt={30} mb={30}>
      <Link
        href={`https://www.rentujemy.pl/dodaj-apartament-kolejne-kroki?utm_source=${UTM_SOURCE}`}
      >
        <Button size={"large"} variation="primary" isFullWidth={true}>
          {t("ROICalculator.CTA")}
        </Button>
      </Link>
    </Box>
  );
};

const UTM_SOURCE = "rentujemy_calculator";

const highlighedInputStyles = {
  backgroundColor: "white",
  border: `3px solid ${theme.primaryColor}`,
};

export default function RealEstateROICalculatorPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const routeParams = useParams<{
    template?: string;
    city?: string;
  }>();

  // hidden variables
  const [variables, setVariables] = useState<{
    purchasePrice?: number;
    rentalIncome?: number;
    utilityCosts?: number;
    taxRate: number;
    currency: "PLN";
  }>({
    taxRate: 8,
    utilityCosts: 0,
    currency: "PLN",
  });

  const [calculatedValues, setCalculatedValues] = useState<
    | {
      roi: number;
    }
    | undefined
  >();

  useEffect(() => {
    const rentalIncome = Number(searchParams.get("rentalIncome"));

    setVariables({
      ...variables,
      rentalIncome,
    });
  }, [searchParams, routeParams]);

  const calculateROI = () => {
    let roi: number | undefined;

    if (
      !variables?.rentalIncome ||
      !variables?.purchasePrice ||
      !variables?.utilityCosts
    ) {
      roi = undefined;
    } else {
      const totalCosts = variables?.utilityCosts || 0;
      const taxes = (variables?.taxRate / 100) * variables?.rentalIncome;

      roi = makeAmountNice(
        (100 * (variables?.rentalIncome - totalCosts - taxes) * 12) /
        variables?.purchasePrice
      );
    }

    setCalculatedValues(
      roi
        ? {
          ...calculatedValues,
          roi,
        }
        : undefined
    );
  };

  return (
    <Box className="container" style={{ maxWidth: 800 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ROICalculator.Title")} | Rentujemy</title>
        <link
          rel="canonical"
          href={
            routeParams.city && routeParams.template
              ? `https://invest.rentujemy.pl/short-term-rental-calculator/${routeParams.city}/${routeParams.template}`
              : "https://invest.rentujemy.pl/short-term-rental-calculator"
          }
        />

        <meta
          name="description"
          content="Oblicz, ile możesz zarobić na krótkoterminowym wynajmie swojego mieszkania z Rentujemy. Skorzystaj z naszego kalkulatora przychodów i sprawdź, jakie dochody przyniesie Ci wynajem krótkoterminowy. Dzięki Rentujemy zyskujesz pewność, że Twoje mieszkanie będzie zarabiało dla Ciebie jak najwięcej."
        />
        <meta
          name="keywords"
          content="kalkulator, roi, przychody, wynajem, krótkoterminowy, mieszkanie, Rentujemy"
        />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <PublicHeader
        logoHref={`https://www.rentujemy.pl?utm_source=${UTM_SOURCE}`}
      ></PublicHeader>

      <Box mt={24}>
        <Box mb={40}>
          <Heading level={3}>{t("ROICalculator.Title")}</Heading>
          <Text>{t("ROICalculator.OwnerSubtitle")}</Text>
        </Box>
      </Box>

      <Box>
        <Box mt={24}>
          <Table highlightOnHover={false}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    label={t("ROICalculator.RentalIncome")}
                    type="number"
                    min={0}
                    placeholder={t("ROICalculator.Amount")}
                    inputStyles={
                      !variables.rentalIncome
                        ? highlighedInputStyles
                        : undefined
                    }
                    value={
                      !!variables.rentalIncome
                        ? variables.rentalIncome
                        : undefined
                    }
                    onChange={(value) =>
                      setVariables({
                        ...variables,
                        rentalIncome: Number(value.target.value),
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <TextField
                    label={t("ROICalculator.UtilityCosts")}
                    type="number"
                    autoFocus
                    inputStyles={
                      !variables.utilityCosts
                        ? highlighedInputStyles
                        : undefined
                    }
                    descriptiveText={
                      "Obejmują one m.in. koszty eksploatacji, napraw, konserwacji, opłat za media. Włączenie tych kosztów w obliczenia pozwala na dokładniejsze oszacowanie zwrotu z inwestycji (ROI) oraz lepsze zrozumienie, ile rzeczywiście zarabia się na wynajmie."
                    }
                    placeholder={t("ROICalculator.Amount")}
                    value={
                      !!variables.utilityCosts
                        ? variables.utilityCosts
                        : undefined
                    }
                    min={0}
                    onChange={(value) =>
                      setVariables({
                        ...variables,
                        utilityCosts: Number(value.target.value),
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <TextField
                    label={t("ROICalculator.TaxRate")}
                    type="number"
                    inputStyles={
                      !variables.taxRate ? highlighedInputStyles : undefined
                    }
                    descriptiveText={
                      "Stawka podatkowa dla dochodów z wynajmu - wprowadź wartość zgodną z Twoją sytuacją podatkową, np. 8% podatek ryczałtowy stosowany w przypadku PIT-28."
                    }
                    placeholder={t("ROICalculator.TaxAmount")}
                    min={0}
                    max={100}
                    step={1}
                    value={!!variables.taxRate ? variables.taxRate : undefined}
                    onChange={(value) => {
                      if (Number(value.target.value) <= 100) {
                        setVariables({
                          ...variables,
                          taxRate: Number(value.target.value),
                        });
                      }
                    }}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    label={t("ROICalculator.PropertyPurchasePrice")}
                    type="number"
                    descriptiveText={
                      "Podaj wartość zakupu swojej nieruchomości, uwzględniając wszelkie koszty związane z zakupem, takie jak opłaty notarialne, podatek od czynności cywilnoprawnych czy prowizje pośrednika. Alternatywnie, podaj bieżącą wycenę nieruchomości. Używanie bieżącej wyceny nieruchomości pozwala na dokładniejsze obliczenie zwrotu z inwestycji (ROI) w oparciu o aktualną wartość kapitału zainwestowanego, co pozwala lepiej ocenić efektywność inwestycji."
                    }
                    placeholder={t("ROICalculator.Amount")}
                    min={0}
                    inputStyles={
                      !variables.purchasePrice
                        ? highlighedInputStyles
                        : undefined
                    }
                    value={
                      !!variables.purchasePrice
                        ? variables.purchasePrice
                        : undefined
                    }
                    onChange={(value) =>
                      setVariables({
                        ...variables,
                        purchasePrice: Number(value.target.value),
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button
                    variation="primary"
                    isFullWidth
                    disabled={
                      !variables.currency ||
                      !variables.purchasePrice ||
                      !variables.utilityCosts
                    }
                    onClick={() => {
                      calculateROI();
                    }}
                  >
                    {t("ROICalculator.CalculateROI")}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ExternalLink
                    label={t("ROICalculator.OccupancyDisclaimer")}
                    utmSource={UTM_SOURCE}
                    href={
                      "https://www.rentujemy.pl/optymalizacja-cen-wynajmu-krotkoterminowego?utm_source=rental-calculator"
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box mt={24}>
          <Table highlightOnHover={false}>
            <TableBody>
              {calculatedValues ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Heading level={5}>
                        {t("ROICalculator.OwnerPayout")}
                      </Heading>
                      <Heading level={2}>{calculatedValues?.roi}%</Heading>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {calculatedValues?.roi && calculatedValues.roi < 8 && (
                        <Box>
                          <Alert variation="error">
                            Wykryliśmy, że Twój zwrot z inwestycji (ROI) jest
                            zbyt niski. Rekomendujemy umówienie się z nami na
                            bezpłatną konsultację, podczas której omówimy, jak
                            możemy zwiększyć Twój ROI z wynajmem
                            krótkoterminowym.
                          </Alert>

                          <CtaComponent />
                        </Box>
                      )}
                      {calculatedValues?.roi &&
                        calculatedValues.roi >= 8 &&
                        calculatedValues.roi < 11 && (
                          <Box>
                            <Alert variation="info">
                              Twój zwrot z inwestycji (ROI) z wynajmu jest
                              optymalny. Jeśli chcesz dowiedzieć się więcej jak
                              możemy pomóc Ci jeszcze bardziej zoptymalizować
                              wynajem, zapraszamy do zapoznania się z{" "}
                              <ExternalLink
                                label="naszą ofertą cenową oraz usługami dodatkowymi"
                                utmSource={UTM_SOURCE}
                                href="https://www.rentujemy.pl/ceny"
                              ></ExternalLink>
                              .
                            </Alert>
                          </Box>
                        )}

                      {calculatedValues?.roi && calculatedValues.roi >= 11 && (
                        <Box>
                          <Alert variation="success">
                            Wykryliśmy, że Twój zwrot z inwestycji (ROI) w
                            wynajmie krótkoterminowym jest wyjątkowo wysoki.
                            Gratulacje! Być może chciałbyś się podzielić swoimi
                            doświadczeniami oraz strategiami, które przyczyniły
                            się do osiągnięcia takiego sukcesu?
                          </Alert>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ) : null}
              <TableRow>
                <TableCell>
                  ROI (ang. return on investment, zwrot z inwestycji) to
                  wskaźnik służący do oceny efektywności inwestycji. ROI stara
                  się bezpośrednio zmierzyć kwotę zwrotu z określonej inwestycji
                  w stosunku do jej kosztów. Aby obliczyć ROI, zysk (lub zwrot)
                  z kampanii jest dzielony przez jej koszt. Wynik jest wyrażony
                  w procentach.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      {false && <Footer />}
    </Box>
  );
};
