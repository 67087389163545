import { Flex } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import useBreakpoints from "../hooks/useBreakpoints";
import USPCompactSection from "../Guest/USPCompactSection";
import RentujemyComLogo from "../Guest/components/RentujemyComLogo";

export default function LoginContainer({ children }: PropsWithChildren) {
  const { isMatchingDesktop } = useBreakpoints();

  return (
    <Flex
      height={"100%"}
      grow={1}
      paddingBottom={150}
      overflow={"scroll"}
      direction={!isMatchingDesktop ? "column-reverse" : "row"}
    >
      <Flex
        basis={"50%"}
        justifyContent="center"
        direction={"column"}
        alignItems={"center"}
        gap={32}
      >
        <RentujemyComLogo></RentujemyComLogo>

        <Flex maxWidth={500} padding={12}>
          <USPCompactSection></USPCompactSection>
        </Flex>
      </Flex>
      <Flex
        justifyContent="center"
        padding={10}
        grow={1}
        direction={"column"}
        alignItems={"center"}
      >
        {children}
      </Flex>
    </Flex>
  );
};
