import {
  Button,
  Flex,
  Heading,
  Link,
  Placeholder,
  Text,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerApi } from "../../hooks/useCustomerApi";
import SuccessHeading from "../components/SuccessHeading";
import UnavaiableHeading from "../components/UnavaiableHeading";
import { useReservationContext } from "../providers/ReservationProvider";
import { useSessionContext } from "../providers/SessionProvider";
import { Upsells } from "../Upsells";

export default function LateCheckoutPage() {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { reservation } = useReservationContext();

  const { getProductCheckoutUrl } = useCustomerApi();

  const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!session) return;

      const res = await getProductCheckoutUrl(
        session.reservationId,
        Upsells.LATE_CHECKOUT
      );

      setCheckoutUrl(res.url);
    })();
  }, [getProductCheckoutUrl, setCheckoutUrl, session]);

  return (
    <>
      {!reservation && <Placeholder height={80}></Placeholder>}

      {session && reservation && reservation.lateCheckout === "unavailable" && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            <UnavaiableHeading
              label={t("LateCheckout.NotAvailable")}
            ></UnavaiableHeading>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {t("Reservation.Id")}: {session.reservationId}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {session && reservation && reservation.lateCheckout === "booked" && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            <SuccessHeading label={t("LateCheckout.Success")}></SuccessHeading>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {t("Reservation.Id")}: {session.reservationId}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {session && reservation && reservation.lateCheckout === "available" && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            <Flex direction={"row"} alignItems={"center"}>
              <Flex>
                <Heading level={3}>{t("LateCheckout.Title")}</Heading>
              </Flex>
            </Flex>
            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {t("Reservation.Id")}: {session.reservationId}
              </Text>
            </Flex>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {" "}
                {t("LateCheckout.Subtitle")}
              </Text>
            </Flex>
          </Flex>

          <Flex grow={1}>
            <Link isExternal={true} href={checkoutUrl || ""}>
              <Button
                isLoading={!checkoutUrl}
                variation="primary"
                isFullWidth={true}
                size="large"
                onClick={async () => { }}
              >
                {t("LateCheckout.Submit")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      )}
    </>
  );
};
