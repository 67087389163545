export const prices = [
  {
    region: "Bałuty",
    slug: "baluty",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 180.5 },
      { type: "studio", standard: "premium", avgPricePerNight: 215.6 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 258.8 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 310.6 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 358.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 430.1 },
    ],
  },
  {
    region: "Polesie",
    slug: "polesie",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 191.3 },
      { type: "studio", standard: "premium", avgPricePerNight: 229.6 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 274.9 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 329.8 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 381.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 457.7 },
    ],
  },
  {
    region: "Górna",
    slug: "gorna",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 211.1 },
      { type: "studio", standard: "premium", avgPricePerNight: 253.3 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 320.1 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 384.1 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 443.4 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 532.1 },
    ],
  },
  {
    region: "Śródmieście",
    slug: "srodmiescie",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 245.0 },
      { type: "studio", standard: "premium", avgPricePerNight: 294.0 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 350.0 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 420.0 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 490.0 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 588.0 },
    ],
  },
  // {
  //   region: "Łódź Górna",
  //   slug: "lodz-gorna",
  //   apartments: [
  //     { type: "studio", standard: "comfort", avgPricePerNight: 220.0 },
  //     { type: "studio", standard: "premium", avgPricePerNight: 264.0 },
  //     { type: "twoRooms", standard: "comfort", avgPricePerNight: 316.0 },
  //     { type: "twoRooms", standard: "premium", avgPricePerNight: 379.0 },
  //     { type: "threeRooms", standard: "comfort", avgPricePerNight: 440.0 },
  //     { type: "threeRooms", standard: "premium", avgPricePerNight: 528.0 },
  //   ],
  // },
  {
    region: "Widzew",
    slug: "widzew",
    apartments: [
      { type: "studio", standard: "comfort", avgPricePerNight: 200.0 },
      { type: "studio", standard: "premium", avgPricePerNight: 240.0 },
      { type: "twoRooms", standard: "comfort", avgPricePerNight: 288.0 },
      { type: "twoRooms", standard: "premium", avgPricePerNight: 345.6 },
      { type: "threeRooms", standard: "comfort", avgPricePerNight: 400.0 },
      { type: "threeRooms", standard: "premium", avgPricePerNight: 480.0 },
    ],
  },
];
