import { Flex } from "@aws-amplify/ui-react";
import {
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
} from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { MdCircle } from "react-icons/md";
import { logger } from "../../logger";
import { theme } from "../../theme";
import CustomAdvancedMarker from "./CustomAdvancedMarker";
import { useDiscoveryContext } from "./DiscoveryContext";
import { DiscoveryListing } from "./DiscoveryListing";

// Function to calculate the zoom level based on bounds and map dimensions
const getBoundsZoomLevel = (
  bounds: { lat: [number, number]; lng: [number, number] },
  mapDim: { height: number; width: number },
  worldDim = { height: 256, width: 256 }
): number => {
  const ZOOM_MAX = 21;

  const latFraction =
    (Math.sin((bounds.lat[1] * Math.PI) / 180) -
      Math.sin((bounds.lat[0] * Math.PI) / 180)) /
    Math.PI;
  const lngFraction = (bounds.lng[1] - bounds.lng[0]) / 360;

  const latZoom =
    Math.log(mapDim.height / worldDim.height / latFraction) / Math.LN2;
  const lngZoom =
    Math.log(mapDim.width / worldDim.width / lngFraction) / Math.LN2;

  return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
};

// Function to calculate the center and zoom level for the map based on listings
const getMapCenterAndZoom = (
  listings: DiscoveryListing[]
): { center: { lat: number; lng: number }; zoom: number } => {
  if (listings.length === 0) {
    // poland
    return { center: { lat: 52.0, lng: 19.0 }, zoom: 7 };
  }

  const lats = listings.map((listing) => listing.lat);
  const lngs = listings.map((listing) => listing.lng);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  const center = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2,
  };

  const bounds = {
    lat: [minLat, maxLat] as [number, number],
    lng: [minLng, maxLng] as [number, number],
  };

  const zoom = getBoundsZoomLevel(bounds, { height: 800, width: 800 }); // Assuming a map size of 800x800 pixels

  logger.log(center, zoom);

  return { center, zoom };
};

const DiscoveryMap = ({
  doNotShowSelected,
  listings,
  loading,
}: {
  doNotShowSelected?: boolean;
  listings: DiscoveryListing[];
  loading: boolean;
}) => {
  const { selectedMapCenter } = useDiscoveryContext();
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>();
  const [mapZoom, setMapZoom] = useState<number>();
  const { focusedListing, setSelectedListing } = useDiscoveryContext();

  useEffect(() => {
    if (focusedListing) {
      setMapCenter({
        lat: focusedListing.lat - 0.015,
        lng: focusedListing.lng,
      });
      setMapZoom(13); // Adjust the zoom level as needed
    }
  }, [focusedListing]);

  useEffect(() => {
    const { center, zoom } = getMapCenterAndZoom(listings);

    setMapCenter(selectedMapCenter || center);
    setMapZoom(selectedMapCenter ? 15 : zoom);
  }, [listings, selectedMapCenter]);

  const handleCameraChange = (ev: MapCameraChangedEvent) => {
    setMapCenter(ev.detail.center);
    setMapZoom(ev.detail.zoom);
  };

  return (
    <Map
      onClick={() => {
        setSelectedListing(undefined);
      }}
      controlSize={50}
      zoomControl={true}
      minZoom={6}
      center={mapCenter}
      zoom={mapZoom}
      mapId={"bf51a910020fa25a"}
      disableDefaultUI
      onCameraChanged={handleCameraChange}
      gestureHandling={"greedy"}
    >
      {listings.map((listing) => (
        <CustomAdvancedMarker
          doNotShowSelected={doNotShowSelected}
          key={listing.url}
          listing={listing}
        ></CustomAdvancedMarker>
      ))}

      {selectedMapCenter && (
        <AdvancedMarker
          zIndex={99998}
          position={selectedMapCenter}
          title={"Selected Location"}
        >
          <Flex
            fontWeight={600}
            justifyContent="center"
            alignItems={"center"}
            boxShadow={"large"}
            backgroundColor={theme.primaryColor}
            paddingRight={12}
            paddingLeft={12}
            padding={"16"}
            position="absolute"
            height={30}
            borderRadius={30}
          >
            <MdCircle color={theme.background}></MdCircle>
          </Flex>
        </AdvancedMarker>
      )}
    </Map>
  );
};

export default DiscoveryMap;
