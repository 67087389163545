import { Flex, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "react-spring-bottom-sheet/dist/style.css";
import { borderRightStyle } from "../commonStyles";
import { MdSearch } from "react-icons/md";
import { theme } from "../../theme";

export default function DiscoveryMobileSearchBar(props: {
  selectedLocation?: string;
  selectedDates?: string;
  selectedGuests?: string;
  onLocation: () => void;
  onDates: () => void;
  onGuests: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Flex
      gap={0}
      padding={16}
      paddingTop={8}
      paddingBottom={8}
      grow={1}
      backgroundColor={theme.background}
      justify-content={"space-around"}
      alignItems={"center"}
    >
      <Flex
        onClick={props.onLocation}
        gap={8}
        grow={1}
        basis={"40%"}
        overflow={"hidden"}
        padding={8}
        paddingLeft={16}
        style={{
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          ...borderRightStyle,
        }}
        alignItems={"center"}
        backgroundColor={theme.background1}
      >
        <Flex>
          <MdSearch></MdSearch>
        </Flex>
        <Flex>
          <Text
            color={theme.muted}
            whiteSpace={"nowrap"}
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {props.selectedLocation || t("Discovery.Search.LocationLabel")}
          </Text>
        </Flex>
      </Flex>
      <Flex
        onClick={props.onDates}
        style={{ ...borderRightStyle }}
        gap={16}
        grow={1}
        padding={8}
        alignItems={"center"}
        backgroundColor={theme.background1}
      >
        <Flex>
          <Text color={theme.muted}>
            {props.selectedDates || t("Discovery.Search.DatesLabel")}
          </Text>
        </Flex>
      </Flex>
      <Flex
        onClick={props.onGuests}
        gap={16}
        grow={1}
        padding={8}
        paddingLeft={16}
        style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}
        alignItems={"center"}
        backgroundColor={theme.background1}
      >
        <Flex>
          <Text color={theme.muted}>
            {props.selectedGuests || t("Discovery.Search.GuestsLabel")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
