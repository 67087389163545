import { Flex } from "@aws-amplify/ui-react";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import useBreakpoints from "../hooks/useBreakpoints";
import MobileNavTabs from "../Discovery/components/MobileNavTabs";

export default function Page({ children }: React.PropsWithChildren) {
  const { isMatchingDesktop } = useBreakpoints();
  return (
    <>
      <DiscoveryHeader></DiscoveryHeader>
      <Flex className="container" paddingTop={35}>
        {children}
      </Flex>

      {!isMatchingDesktop && <MobileNavTabs></MobileNavTabs>}
    </>
  );
};
