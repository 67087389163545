import { Button, Flex, Link, Placeholder, Text } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCustomerApi } from "../../hooks/useCustomerApi";
import HeadingSection from "../components/HeadingSection";
import SuccessHeading from "../components/SuccessHeading";
import { useReservationContext } from "../providers/ReservationProvider";
import { useSessionContext } from "../providers/SessionProvider";

export default function FinalizeBookingPage() {
  const { t } = useTranslation();
  const params = useParams();

  const { getReservationPaymentUrl } = useCustomerApi();
  const { reservation } = useReservationContext();
  const { session } = useSessionContext();
  const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null);

  useEffect(() => {
    if (reservation?.paid === false) {
      (async () => {
        const res = await getReservationPaymentUrl(
          String(session?.reservationId)
        );

        setCheckoutUrl(res.url);
      })();
    }
  }, [reservation, setCheckoutUrl]);

  return (
    <>
      {!reservation && <Placeholder height={80}></Placeholder>}

      {reservation && (
        <Flex direction={"column"} gap={40}>
          <Flex direction={"column"}>
            {!reservation.paid && (
              <HeadingSection
                title={t("FinalizeBooking.Title")}
                subtitle={t("FinalizeBooking.Subtitle")}
              ></HeadingSection>
            )}
            {reservation.paid && (
              <SuccessHeading
                label={t("FinalizeBooking.SuccessTitle")}
              ></SuccessHeading>
            )}

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {t("Reservation.Id")}: {params.reservationId}
              </Text>
            </Flex>

            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {" "}
                {reservation.listingName}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize={16} color={"rgba(33, 52, 88, 0.7)"}>
                {" "}
                {reservation.checkinDate} - {reservation.checkoutDate}
              </Text>
            </Flex>
          </Flex>

          {!reservation.paid && (
            <Flex grow={1}>
              <Link isExternal={true} href={checkoutUrl || ""}>
                <Button
                  isLoading={!checkoutUrl}
                  variation="primary"
                  isFullWidth={true}
                  size="large"
                  onClick={async () => { }}
                >
                  {t("FinalizeBooking.Submit")}
                </Button>
              </Link>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
