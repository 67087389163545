import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSessionContext } from "../Guest/providers/SessionProvider";
import { apiEndpoint } from "../config";
import { getAuthHeader } from "./common";
import { logger } from "../logger";

interface MultiLangAccessInfoFieldValue {
  en: string;
  pl: string;
}

export interface AccessInfo {
  wifiUsername: string;
  wifiPassword: string;
  doorSecurityCode: string;
  address: string;
  parking: MultiLangAccessInfoFieldValue;
  accessInstructions: MultiLangAccessInfoFieldValue;
  pastCheckinInstructions: MultiLangAccessInfoFieldValue;
}

const load = async (
  reservationId: string,
  publicLogin?: { reservationId: number; guestLastName: string }
): Promise<{ listing: AccessInfo }> => {
  if (publicLogin) {
    return (
      await axios.post(
        `${apiEndpoint}/v1/guest/reservations/manager/reservation/${reservationId}/access/public`,
        { publicAuth: publicLogin }
      )
    ).data;
  }

  return (
    await axios.post(
      `${apiEndpoint}/v1/guest/reservations/manager/reservation/${reservationId}/access`,
      undefined,
      await getAuthHeader()
    )
  ).data;
};

export const useAccessInstructions = (reservationId: string) => {
  const { session } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AccessInfo>();
  const [error, setError] = useState<AxiosError>();

  const refetch = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    logger.debug("useAccessInstructions#refetch");
    try {
      const body = session?.type === "unregistered" ? session : undefined;

      const result = (await load(reservationId, body)).listing;

      logger.debug(result);
      setData(result);
    } catch (err) {
      logger.debug(err);

      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData, setError, session]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch]);

  return { data, loading, error, refetch };
};
