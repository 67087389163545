import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { prices as pricesWarsaw } from "../template/prices";
import { prices as pricesLodz } from "../template/pricesLodz";
import { prices as pricesTricity } from "../template/pricesGdansk";
import { Cities } from "../model/Cities";
import { ApartmentStandards } from "../model/ApartmentStandard";
import { ApartmentTypes } from "../model/ApartmentTypes";
import pricesKrakow from "../template/pricesKrakow";

export const getRegionObject = (city: Cities) => {
  switch (city) {
    case Cities.LODZ:
      return pricesLodz;
    case Cities.TRICITY:
      return pricesTricity;
    case Cities.CRAKOW:
      return pricesKrakow;
    case Cities.WARSAW:
    default:
      return pricesWarsaw;
  }
};

const usePrice = (city: Cities, region: string, type: ApartmentTypes) => {
  const priceMatrix = getRegionObject(city).find(
    (item) => item.slug === region
  );
  const comfortPrice = priceMatrix?.apartments.find(
    (item) => item.type === type && item.standard === ApartmentStandards.COMFORT
  )?.avgPricePerNight;
  const premiumPrice = priceMatrix?.apartments.find(
    (item) => item.type === type && item.standard === ApartmentStandards.PREMIUM
  )?.avgPricePerNight;

  return { comfortPrice, premiumPrice };
};

export default function Standard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const { comfortPrice, premiumPrice } = usePrice(
    params.city as Cities,
    params.region as string,
    params.type as ApartmentTypes
  );

  return (
    <Flex direction="column" gap={4}>
      <Heading level={4} marginBottom={10}>
        {t("shortTermCalculator.chooseStandard")}
      </Heading>

      <Button
        onClick={() => {
          navigate(`comfort?pricePerNight=${comfortPrice}`);
        }}
      >
        {t("shortTermCalculator.comfort")}
      </Button>
      <Button
        onClick={() => {
          navigate(`premium?pricePerNight=${premiumPrice}`);
        }}
      >
        {t("shortTermCalculator.premium")}
      </Button>
    </Flex>
  );
};
