import {
  Flex,
  FlexProps,
  Image,
  ImageProps,
  Text,
} from "@aws-amplify/ui-react";
import { borderBottomStyle } from "../../Discovery/commonStyles";
import { TextBold } from "../../Discovery/components/DiscoveryTypography";
import styled from "styled-components";
import { PropsWithChildren } from "react";
import Markdown from "react-markdown";

export const StyledText = styled(Text)`
  text-wrap: pretty;
`;

export default function SectionWithBoldText({
  text,
  children,
  header,
  ...flexProps
}: PropsWithChildren<
  { text?: string; header: string } & Partial<FlexProps>
>) {
  return (
    <Flex style={{ ...borderBottomStyle }} direction={"column"} {...flexProps}>
      <TextBold>{header}</TextBold>

      {text && (
        <Markdown
          components={{
            img: (props) => {
              const { node, ...rest } = props;

              let imgProps = rest as ImageProps;

              return (
                <Image style={{ objectFit: "contain" }} {...imgProps} />
              ) as any;
            },
          }}
        >
          {text}
        </Markdown>
      )}

      {children && <StyledText>{children}</StyledText>}
    </Flex>
  );
};
