import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "react-spring-bottom-sheet/dist/style.css";
import { theme } from "../theme";
import { useDiscoveryContext } from "./components/DiscoveryContext";
import DiscoveryListingComponent from "./components/DiscoveryListingComponent";
import { MdListAlt } from "react-icons/md";
import { NAV_TABS_HEIGHT } from "./components/MobileNavTabs";

const MAP_CONTROL_ZINDEX = 1;

export default function DiscoveryMobileBottomSection({ onOpen }: { onOpen: () => void }) {
  const { t } = useTranslation();
  const { selectedListing } = useDiscoveryContext();

  return (
    <Flex
      position="absolute"
      bottom={NAV_TABS_HEIGHT + 16}
      left={0}
      right={0}
      style={{ zIndex: MAP_CONTROL_ZINDEX }}
      direction={"column"}
    >
      {selectedListing && (
        <Flex justifyContent={"center"} alignItems={"center"}>
          <DiscoveryListingComponent
            standalone={true}
            listing={selectedListing}
          ></DiscoveryListingComponent>
        </Flex>
      )}

      {!selectedListing && (
        <Flex
          padding={16}
          grow={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            gap={8}
            borderRadius={30}
            variation="primary"
            onClick={() => {
              onOpen();
            }}
          >
            <Text color={theme.background}>{t("Discovery.ShowList")}</Text>

            <MdListAlt color={theme.background} size={22}></MdListAlt>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
