import { Button, Link } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import Box from "../components/Box";

interface CtaComponentProps {
  UTM_SOURCE: string;
}

export default function OwnerIncomeCta({ UTM_SOURCE }: CtaComponentProps) {
  const { t } = useTranslation();

  return (
    <Box mt={30} mb={30}>
      <Link
        href={`https://www.rentujemy.pl/dodaj-apartament-kolejne-kroki?utm_source=${UTM_SOURCE}`}
      >
        <Button size={"large"} isFullWidth={true}>
          {t("IncomeCalculator.CTA")}
        </Button>
      </Link>
    </Box>
  );
};
